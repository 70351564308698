import { Component, ElementRef, Input, OnInit, ViewChild } from "@angular/core";
import { Event } from "../../shared/models/event";
import { EventService } from "../../shared/services/event.service";
import { PlayerService } from "../../shared/services/player.service";
import { PlayerEventInfoService } from "../../shared/services/playereventinfo.service";
import { Observable } from "rxjs";
import { PlayerEventInfo, PlayerEventInfoJersey, PlayerEventInfoStatus, TeamMemberType } from "../../shared/models/playerEventInfo";
import { Player } from "../../shared/models/player";
import { ActivatedRoute, Router } from "@angular/router";
import { Location } from "@angular/common";
import "rxjs/add/observable/of";
import "rxjs/Rx";
import { Chat, ChatEntityType } from "../../shared/models/chat";
import { ChatService } from "../../shared/services/chat.service";
import * as moment from "moment";
import { ParamMap } from "@angular/router";
import { NgxSpinnerService } from "ngx-spinner";
import { BsModalService, BsModalRef } from "ngx-bootstrap/modal";
import { ModalContentComponent } from "../../shared/modal/modal-content.component";
import { debounceTime, mergeMap } from "rxjs/operators";

@Component({
  selector: "event-players",
  templateUrl: "./event-players.component.html",
  styleUrls: ["../event.component.css"],
})
export class EventPlayersComponent implements OnInit {
  @Input() public sendFinalEmailHref: string = "";
  @Input() public editMode: boolean = false;
  public event: Event;

  public autocompleteSelectedPlayer: Player;
  public selectedPlayerHolder: Player;
  public playerNameSelected: boolean;
  public typeaheadPlayerLoading: boolean;
  public typeaheadPlayerNoResults: boolean;
  public playerDataSource: Observable<any>;
  private chatEntityType = new ChatEntityType();

  public fetchPlayerEventInfosInProgress: boolean;
  public newCandidatesFound: boolean;
  public newCandidatesInfoList: PlayerEventInfo[] = new Array<PlayerEventInfo>();
  public showHelp: boolean;

  public currentPlayer: Player;

  public loading = false;
  public loadingPlayersList = false;
  public countOfConfirmed: number = 0;
  public countOfNotAnswered: number = 0;
  public countOfDeclined: number = 0;
  public countOfNotNotified: number = 0;

  public playerEventInfoStatus: PlayerEventInfoStatus = new PlayerEventInfoStatus();
  public playerEventInfoJersey: PlayerEventInfoJersey = new PlayerEventInfoJersey();
  public teamMemberType: TeamMemberType = new TeamMemberType();
  public sendInviteEmailEvent: Event;

  public showNotifyDialog: boolean = false;
  public showNotificationsSent: boolean = false;

  public showNotifyAllOpenProfiles: boolean = false;
  public showNotifyTeammateOpenProfiles: boolean = false;
  public addNewPeopleToNogosh: boolean = false;
  public showAddPlayersDialog: boolean = false;
  public notifyAllOpenProfilesEvent: Event;
  public notifyTeammateOpenProfilesEvent: Event;
  public showNewNogoshUserDialog: boolean = false;
  public showInvitePlayersToMatchDialog: boolean = false;
  public selectedPlayers: Player[] = new Array<Player>();
  public eventId: string = null;
  bsModalRef: BsModalRef;

  @ViewChild("newPlayer")
  playerAutocomplete: ElementRef;

  public navigateHref: string = "";

  constructor(
    public playerService: PlayerService,
    private chatService: ChatService,
    public eventService: EventService,
    private playerEventInfoService: PlayerEventInfoService,
    public route: ActivatedRoute,
    public router: Router,
    public location: Location,
    private _location: Location,
    private spinner: NgxSpinnerService,
    private modalService: BsModalService
  ) {
    this.loading = true;
    this.spinner.show();
    this.newCandidatesFound = false;

    this.playerDataSource = new Observable((observer: any) => {
      observer.next(this.autocompleteSelectedPlayer);
      this.spinner.hide();
      this.loading = false;
    })
    .pipe(
      debounceTime(500),
      mergeMap((token: string) => this.getPlayersAsObservable(token))
    );
  }

  ngOnInit() {

    this.loading = true;
    this.spinner.show();
    this.newCandidatesFound = false;

    this.event = JSON.parse(localStorage.getItem("event"));
    this.editMode = JSON.parse(this.route.snapshot.params["editSelectedEvent"]);

    this.route.paramMap.subscribe((params: ParamMap) => {
      this.eventId = params.get("eventId");
    });

    if (!localStorage.getItem("event") && this.eventId === null) {
      this.router.navigate(["/event-list"]);
      return;
    }

    if (this.eventId !== null) {
      this.eventService.getEvent(this.eventId).subscribe((event) => {
        this.event = event;
        this.fetchPlayerEventInfos(this.event);
      });
    } else {
      this.fetchPlayerEventInfos(this.event);
    }

    this.currentPlayer = this.playerService.getCurrentPlayer();
    if (this.currentPlayer.id === this.event.manager.id) {
      this.event.viewedByManager = true;
    }
  }

  public openMngPlayerDetail(playerEventInfo: PlayerEventInfo, event: Event) {
    localStorage.setItem("peiForPlayerDetail", JSON.stringify(playerEventInfo));
    localStorage.setItem("eventForPlayerDetail", JSON.stringify(event));

    this.router.navigate(["/player-detail", true]);
  }

  public openAddPlayersDialog() {
    this.showAddPlayersDialog = true;
  }

  public notifyPlayers(event: Event, notifyMemberType: string): void {
    let me = this;

    event.sendingNotificationsInProgress = true;
    event.locationId = event.playSpace.id;
    event.playSpace.location = null;

    me.eventService.notifyPlayers(event, notifyMemberType).subscribe(
      (res) => {
        setTimeout(() => {
          event.sendingNotificationsInProgress = false;

          me.playerEventInfoService.fetchForEvent(event.id).subscribe(
            (playerEventInfoList) => {
              event.playerEventInfos = Array<PlayerEventInfo>();

              for (let playerEventInfo of playerEventInfoList) {
                event.playerEventInfos.push(playerEventInfo);
              }
            },
            (res) => {
              console.log(res.error);
            }
          );
        }, 2700);
        this.showNotificationsSent = true;
      },
      (error) => {
        console.log("notifications error: " + error);
        event.sendingNotificationsInProgress = false;
        me.spinner.hide();
        me.loading = false;
      }
    );
  }

  public notifyPlayersWithNoResponse(event: Event): void {
    this.eventService.notifyNotAnsweredEventPlayers(event.id).subscribe(
      (updatedPc) => {
        this.loading = false;
        this.spinner.hide();
        this.showNotificationsSent = true;
      },
      (error) => {
        console.log(error);
        this.spinner.hide();
        this.loading = false;
      }
    );
  }

  public dialogNotifyAllOpenProfiles(event: Event): void {
    this.showNotifyAllOpenProfiles = true;
    this.notifyAllOpenProfilesEvent = event;
  }

  public dialogNotifyTeammateOpenProfiles(event: Event): void {
    this.showNotifyTeammateOpenProfiles = true;
    this.notifyTeammateOpenProfilesEvent = event;
  }

  public notifyAllOpenProfiles(): void {
    let event: Event = this.notifyAllOpenProfilesEvent;

    if (event.sosUsed) {
      const initialState = {
        title: "Alert",
        text: "You have already used up your one SOS call for this event!",
      };
      this.bsModalRef = this.modalService.show(ModalContentComponent, { initialState });
      this.bsModalRef.content.closeBtnName = "Close";
    } else {
      this.spinner.show();
      this.loading = true;
      this.eventService.notifyAllOpenProfiles(event.id).subscribe(
        (updatedPc) => {
          this.loading = false;
          this.spinner.hide();
        },
        (res) => {
          console.log(res.error);
          this.loading = false;
          this.spinner.hide();
        }
      );
    }
  }

  public notifyTeammateOpenProfiles(): void {
    let me = this;
    me.loading = true;
    me.spinner.show();

    let event: Event = me.notifyTeammateOpenProfilesEvent;

    me.eventService.notifyTeammateOpenProfiles(event.id).subscribe(
      (updatedPc) => {
        me.loading = false;
        me.spinner.hide();
      },
      (res) => {
        console.log(res.error);
        me.loading = false;
        me.spinner.hide();
      }
    );
  }

  public sendFinalEmail(event: Event): void {
    let me = this;

    let recepient = "";

    let options = {
      bcc: function (playerEventInfoStatus: PlayerEventInfoStatus, event: Event) {
        let bcc = "";
        for (let pei of event.playerEventInfos) {
          if (pei.status === playerEventInfoStatus.CONFIRMED) {
            bcc += pei.player.email + ";";
          }
        }

        return bcc;
      },

      subject: event.name + " at " + moment(event.nextOccurrenceTimestamp).format("dddd, MMMM Do YYYY, h:mm:ss a") + ". Teams are: ",
      body: "Hi,\nThis is an email pre-populated from angular-mailto.",
    };

    me.sendFinalEmailHref = "mailto:" + recepient + "?" + "bcc=" + options.bcc(me.playerEventInfoStatus, event) + "&subject=" + options.subject + "&body=" + options.body;
  }

  public openMap(): void {
    if (navigator.platform.indexOf("iPhone") != -1 || navigator.platform.indexOf("iPod") != -1 || navigator.platform.indexOf("iPad") != -1) {
      this.navigateHref = "http://maps.apple.com/?q=" + this.event.playSpace.latitude + "," + this.event.playSpace.longitude;
    } else {
      this.navigateHref = "https://maps.google.com/maps?daddr=" + this.event.playSpace.latitude + "," + this.event.playSpace.longitude + "&amp;ll=";
    }
  }

  public viewEvent(event: Event): void {
    localStorage.setItem("event", JSON.stringify(event));
    this.router.navigate(["/event-detail", false]);
  }

  public openStats(event): void {
    localStorage.setItem("event", JSON.stringify(event));
    this.router.navigate(["/stats-detail/" + event.originalEventId]);
  }

  public openHistory(event: Event): void {
    localStorage.setItem("historyEvent", JSON.stringify(event));
    this.router.navigate(["/event-history"], { state: { data: { event } } });
  }

  public leaveEvent(event: Event): void {
    if (!event.playerEventInfos.some((playerEventInfo) => playerEventInfo.player.id === this.currentPlayer.id)) {
      return;
    }

    this.loading = true;
    this.spinner.show();
    this.eventService.requestToLeaveEvent(event, this.currentPlayer).subscribe(
      () => {
        this.router.navigate(["/event-list"]);
      },
      (res) => {
        console.log(res.error);
        this.spinner.hide();
        this.loading = false;
      }
    );
  }

  public respondToInvite(event: Event, status: string): void {
    let me = this;

    let playerEventInfoForUpdate: PlayerEventInfo;
    for (let playerEventInfo of event.playerEventInfos) {
      if (me.currentPlayer.id === playerEventInfo.player.id) {
        playerEventInfoForUpdate = playerEventInfo;
        playerEventInfoForUpdate.status = status;
        break;
      }
    }

    if (!playerEventInfoForUpdate) {
      for (let playerEventInfo of event.playerEventInfos) {
        if (me.currentPlayer.email === playerEventInfo.player.email) {
          playerEventInfoForUpdate = playerEventInfo;
          playerEventInfoForUpdate.status = status;
          break;
        }
      }
    }

    if (!playerEventInfoForUpdate) {
      console.log("Something is wrong. Current player is not part of this match and cannot accepts invites");
      return;
    }

    me.playerEventInfoService.update(playerEventInfoForUpdate, me.currentPlayer).subscribe(
      (playerEventInfo) => {
        event.currentPlayerInviteStatus = playerEventInfo.status;
        this.fetchPlayerEventInfos(event);
      },
      (error) => {
        console.log(error);
        if (error.status == 406) {
          const initialState = {
            title: "Alert",
            text: "Max number of players already signed up!!! Please refresh the page to see the latest status of the match.",
          };
          this.bsModalRef = this.modalService.show(ModalContentComponent, { initialState });
          this.bsModalRef.content.closeBtnName = "Close";
        }
      }
    );
  }

  public fetchPlayerEventInfos(event: Event): void {
    this.fetchPlayerEventInfosInProgress = true;
    this.newCandidatesFound = false;

    this.event.playerEventInfos = [];
    this.countOfConfirmed = 0;
    this.countOfNotAnswered = 0;
    this.countOfDeclined = 0;
    this.countOfNotNotified = 0;

    this.playerEventInfoService.fetchForEvent(event.id).subscribe(
      (playerEventInfoList) => {
        this.event.playerEventInfos = Array<PlayerEventInfo>();

        for (let playerEventInfo of playerEventInfoList) {
          if (playerEventInfo.teamMemberType === this.teamMemberType.CANDIDATE) {
            this.newCandidatesFound = true;
            this.newCandidatesInfoList.push(playerEventInfo);
          }

          if (playerEventInfo.player.id === this.currentPlayer.id) {
            this.event.currentPlayerInviteStatus = playerEventInfo.status;
            if (playerEventInfo.teamMemberType !== this.teamMemberType.CANDIDATE) {
              this.event.viewedByPlayer = true;
            }
            if (playerEventInfo.teamMemberType !== this.teamMemberType.CANDIDATE) {
              this.event.viewedByPlayer = true;
            }
            if (this.event.manager.id === this.currentPlayer.id) {
              this.event.viewedByManager = true;
            }
          }
          this.event.playerEventInfos.push(playerEventInfo);
        }

        this.event.playerEventInfos.forEach((player) => {
          if (player.status === "CONFIRMED") {
            this.countOfConfirmed++;
          } else if (player.status === "NOT_ANSWERED") {
            this.countOfNotAnswered++;
          } else if (player.status === "DECLINED") {
            this.countOfDeclined++;
          } else {
            this.countOfNotNotified++;
          }
        });

        this.sortPlayersByTeamMemberTypeAndNickname();

        // if (me.event.playerEventInfos.length === 0) {
        //   me.editMode = true;
        // }

        this.fetchPlayerEventInfosInProgress = false;
        this.spinner.hide();
        this.loading = false;
      },
      (error) => {
        console.log(error);

        this.fetchPlayerEventInfosInProgress = false;
        this.spinner.hide();
        this.loading = false;
      }
    );
  }

  public switchToTeamMode(): void {
    this.editMode = true;

    this.sortPlayersByTeamMemberType();
    this.router.navigate(["/event-players", false]);
  }

  public switchToMatchMode(): void {
    this.editMode = false;

    this.sortPlayerByStatusAndJerseyColor();
    this.router.navigate(["/event-players", false]);
  }

  public sortPlayersByTeamMemberType(): void {
    if (!this.event.playerEventInfos) {
      return;
    }

    this.event.playerEventInfos.sort((a, b) => a.teamMemberType.localeCompare(b.teamMemberType));
  }

  public sortPlayersByTeamMemberTypeAndNickname(): void {
    if (!this.event.playerEventInfos) {
      return;
    }

    this.event.playerEventInfos.sort((a, b) => {
      if (a.teamMemberType === b.teamMemberType) {
        return a.player.nickName.localeCompare(b.player.nickName);
      } else {
        return a.teamMemberType.localeCompare(b.teamMemberType);
      }
    });
  }

  public sortPlayerByStatusAndJerseyColor(): void {
    if (!this.event.playerEventInfos) {
      return;
    }

    this.event.playerEventInfos.sort((a, b) => {
      if (a.status === b.status) {
        return a.jersey.localeCompare(b.jersey);
      } else {
        return a.status.localeCompare(b.status);
      }
    });
  }

  public animateNotificationInProgress(originalPc: PlayerEventInfo, updatedPc: PlayerEventInfo) {
    let me = this;
    originalPc.notificationInProgress = true;

    setTimeout(() => {
      updatedPc.notificationInProgress = false;
      me.event.playerEventInfos[me.event.playerEventInfos.indexOf(originalPc, 0)] = updatedPc;
    }, 2700);
  }

  public sendIndividualMatchInvitation(playerEventInfo: PlayerEventInfo): void {
    this.eventService.invitePlayer(playerEventInfo).subscribe(
      (updatedPc) => {
        this.animateNotificationInProgress(playerEventInfo, updatedPc);
      },
      (error) => {
        console.log(error);
        this.animateNotificationInProgress(playerEventInfo, playerEventInfo);
      }
    );
  }

  public changeTypeaheadLoading(e: boolean): void {
    this.playerNameSelected = false;
    this.typeaheadPlayerLoading = e;
  }

  public changeTypeaheadNoResults(e: boolean): void {
    this.playerNameSelected = false;
    this.typeaheadPlayerNoResults = e;
  }

  public typeaheadPlayerNameSelected(selectedObject: any): void {
    this.playerNameSelected = true;
    this.selectedPlayerHolder = selectedObject.item;
    this.selectedPlayers.push(selectedObject.item);
  }

  public approveJoinRequest(playerEventInfo: PlayerEventInfo): void {
    playerEventInfo.teamMemberType = this.teamMemberType.RESERVE;

    this.checkIfCandidatesExist();
    this.sortPlayers();

    this.playerEventInfoService.update(playerEventInfo, this.currentPlayer).subscribe(
      (updatedPlayerEventInfo) => {
        this.updateEventPlayerEventInfo(updatedPlayerEventInfo);
        this.newCandidatesInfoList = this.newCandidatesInfoList.filter((item) => item.id !== playerEventInfo.id);
      },
      (error) => {
        console.log(error);
      }
    );
  }

  private calculateDirectChatEntityId(currentPlayer: Player, otherPlayer: Player): string {
    let ids: string[] = Array();
    ids.push(currentPlayer.id);
    ids.push(otherPlayer.id);
    ids.sort();

    return ids[0] + "-" + ids[1];
  }

  public enterOrCreateNewChat(player: Player): void {
    let directChatEntityId = this.calculateDirectChatEntityId(this.currentPlayer, player);

    this.chatService.getChatForEntityId(directChatEntityId).subscribe(
      (chat) => {
        if (chat) {
          this.router.navigate(["/messages", { chatId: chat.id }]);
        } else {
          let chat = new Chat();
          chat.name = this.currentPlayer.nickName + ", " + player.nickName;
          chat.entityType = this.chatEntityType.PLAYER;

          chat.contact = this.currentPlayer;

          chat.players = Array<Player>();
          chat.players.push(this.currentPlayer);
          chat.players.push(player);

          chat.entityId = directChatEntityId;

          this.chatService.createChat(chat).subscribe(
            (chat) => {
              this.router.navigate(["/messages", { chatId: chat.id }]);
            },
            (response) => {
              console.log(response.error);
              this.spinner.hide();
              this.loading = false;
            }
          );
        }
      },
      (response) => {
        console.log(response.error);
        this.spinner.hide();
        this.loading = false;
      }
    );
  }

  public addPlayerToTheEvent(email: string): void {
    if (this.selectedPlayerHolder != null && this.event.playerEventInfos.findIndex((x) => x.player.id === this.selectedPlayerHolder.id) > -1) {
      this.playerNameSelected = false;
      this.autocompleteSelectedPlayer = null;

      return;
    }

    this.addPlayerToPlayerEventInfosList(this.selectedPlayerHolder);

    //this.editMode = true;

    this.autocompleteSelectedPlayer = null;
    this.typeaheadPlayerNoResults = false;
    this.selectedPlayerHolder = null;
  }

  public removePlayerFromTheEvent(playerEventInfo: PlayerEventInfo): void {
    if (playerEventInfo.id) {
      this.playerEventInfoService.delete(playerEventInfo.id).subscribe(
        () => {
          console.log("nbkjbjkbkjb");
          this.removePlayerEventInfoFromList(playerEventInfo);
          this.checkIfCandidatesExist();
          this.event.playerEventInfos = this.event.playerEventInfos.filter((item) => item.id !== playerEventInfo.id);
          this.newCandidatesInfoList = this.newCandidatesInfoList.filter((item) => item.id !== playerEventInfo.id);
        },
        (res) => {
          console.log(res.error);
        }
      );
    } else {
      this.removePlayerEventInfoFromList(playerEventInfo);
      this.checkIfCandidatesExist();
    }
  }

  public async toggleSubteamJersey(playerEventInfo: PlayerEventInfo): Promise<void> {
    if (playerEventInfo.jersey == null || playerEventInfo.jersey === this.playerEventInfoJersey.WHITE) {
      playerEventInfo.jersey = this.playerEventInfoJersey.BLACK;
    } else {
      playerEventInfo.jersey = this.playerEventInfoJersey.WHITE;
    }

    // this.sortPlayers();

    try {
      const updatedPlayerEventInfo = await this.playerEventInfoService
        .update(playerEventInfo, this.currentPlayer)
        .toPromise();
      this.updateEventPlayerEventInfo(updatedPlayerEventInfo);
      // console.log("updated:" + updatedPlayerEventInfo.player.name + " " + updatedPlayerEventInfo.jersey);
    } catch (error) {
      console.log(error);
    }
  }

  public toggleStatus(playerEventInfo: PlayerEventInfo): void {
    let me = this;

    let peiOriginalStatus = playerEventInfo.status;

    if (playerEventInfo.status === me.playerEventInfoStatus.NOT_ANSWERED) {
      me.countOfNotAnswered--;
      me.countOfConfirmed++;
      playerEventInfo.status = me.playerEventInfoStatus.CONFIRMED;
    } else if (playerEventInfo.status === me.playerEventInfoStatus.CONFIRMED) {
      me.countOfConfirmed--;
      me.countOfDeclined++;
      playerEventInfo.status = me.playerEventInfoStatus.DECLINED;
    } else if (playerEventInfo.status === me.playerEventInfoStatus.DECLINED) {
      me.countOfDeclined--;
      me.countOfNotNotified++;
      playerEventInfo.status = me.playerEventInfoStatus.NOT_NOTIFIED;
    } else {
      me.countOfNotNotified--;
      me.countOfNotAnswered++;
      playerEventInfo.status = me.playerEventInfoStatus.NOT_ANSWERED;
    }

    // this.sortPlayers();

    me.playerEventInfoService.update(playerEventInfo, this.currentPlayer).subscribe(
      (updatedPlayerEventInfo) => {
        this.updateEventPlayerEventInfo(updatedPlayerEventInfo);
        me.spinner.hide();
        me.loading = false;
      },
      (error) => {
        this.fetchPlayerEventInfos(this.event);
        const initialState = {
          title: "Alert",
          text: "Max players for match reached. Please review refreshed list of players!",
        };
        this.bsModalRef = this.modalService.show(ModalContentComponent, { initialState });
        this.bsModalRef.content.closeBtnName = "Close";

        me.loading = false;
        me.spinner.hide();
      }
    );
  }

  // public sendInviteEmail(inviteToNogoshEmailList: string): void {
  //   this.loading = true;
  //   this.eventService.invitePlayersToJoinNogosh(this.sendInviteEmailEvent, inviteToNogoshEmailList).subscribe(
  //     () => {
  //       this.addNewPeopleToNogosh = true;
  //       this.loading = false;
  //     },
  //     (res) => {
  //       console.log(res.error);
  //       this.loading = false;
  //     }
  //   );
  // }

  // public dialogSendInviteEmail(event: Event): void {
  //   this.sendInviteEmailEvent = event;
  // }

  public toogleTeamMemberType(playerEventInfo: PlayerEventInfo): void {
    if (playerEventInfo.teamMemberType === this.teamMemberType.FIRST_TEAM) {
      playerEventInfo.teamMemberType = this.teamMemberType.RESERVE;
    } else {
      playerEventInfo.teamMemberType = this.teamMemberType.FIRST_TEAM;
    }

    // this.sortPlayers();

    this.playerEventInfoService.update(playerEventInfo, this.currentPlayer).subscribe(
      (updatedPlayerEventInfo) => {
        this.updateEventPlayerEventInfo(updatedPlayerEventInfo);
      },
      (error) => {
        console.log(error);
      }
    );
  }

  private checkIfCandidatesExist(): void {
    this.newCandidatesFound = false;
    for (let pei of this.event.playerEventInfos) {
      if (pei.teamMemberType === this.teamMemberType.CANDIDATE) {
        this.newCandidatesFound = true;
        break;
      }
    }
  }

  private removePlayerEventInfoFromList(playerEventInfo: PlayerEventInfo) {
    let index = 0;
    for (let pei of this.event.playerEventInfos) {
      if (playerEventInfo.id === pei.id) {
        this.event.playerEventInfos.splice(index, 1);
        break;
      }

      index++;
    }
  }

  private updateEventPlayerEventInfo(playerEventInfo: PlayerEventInfo): void {
    let newPlayerEventInfos = Array<PlayerEventInfo>();

    for (let pei of this.event.playerEventInfos) {
      if (playerEventInfo.id === pei.id) {
        newPlayerEventInfos.push(playerEventInfo);
      } else {
        newPlayerEventInfos.push(pei);
      }
    }
    this.event.playerEventInfos = newPlayerEventInfos;
  }

  private sortPlayers(): void {
    if (this.editMode) {
      this.sortPlayersByTeamMemberType();
    } else {
      this.sortPlayerByStatusAndJerseyColor();
    }
  }

  private getPlayersAsObservable(partialPlayerNickName: string): Observable<any> {
    return this.playerService.getPlayersOpenProfile(partialPlayerNickName);
  }

  public removePlayerFromInviteList(player: Player) {
    let index = 0;

    for (let splayer of this.selectedPlayers) {
      if (player.id === splayer.id) {
        this.selectedPlayers.splice(index, 1);
        break;
      }
      index++;
    }
  }

  public callPlayersFromInviteList(): void {
    for (let player of this.selectedPlayers) {
      this.addPlayerToPlayerEventInfosList(player);
    }

    this.editMode = false;

    this.autocompleteSelectedPlayer = null;
    this.typeaheadPlayerNoResults = false;
    this.selectedPlayerHolder = null;
  }

  private addPlayerToPlayerEventInfosList(player: Player): void {
    let currentPlayerEventInfosList = this.event.playerEventInfos;
    let playerEventInfoFound: boolean = false;

    for (let playerEventInfo of currentPlayerEventInfosList) {
      if (player.id === playerEventInfo.player.id) {
        playerEventInfoFound = true;
        break;
      }
    }

    if (!playerEventInfoFound) {
      let newPlayerEventInfo: PlayerEventInfo = new PlayerEventInfo();
      newPlayerEventInfo.eventId = this.event.id;
      newPlayerEventInfo.player = player;
      newPlayerEventInfo.jersey = this.playerEventInfoJersey.WHITE;
      newPlayerEventInfo.status = this.playerEventInfoStatus.NOT_NOTIFIED;
      newPlayerEventInfo.teamMemberType = this.teamMemberType.RESERVE;

      if (newPlayerEventInfo.eventId) {
        this.loadingPlayersList = true;
        this.playerEventInfoService.create(newPlayerEventInfo, this.currentPlayer).subscribe(
          (playerEventInfo) => {
            this.event.playerEventInfos.push(playerEventInfo);
            this.loadingPlayersList = false;
          },
          (error) => {
            console.log(error);
          }
        );
      } else {
        this.event.playerEventInfos.push(newPlayerEventInfo);
      }
    }
  }

  public copyNogoshMessage(inputElement) {
    let val = inputElement.innerText;
    let selBox = document.createElement("textarea");
    selBox.style.position = "fixed";
    selBox.style.left = "0";
    selBox.style.top = "0";
    selBox.style.opacity = "0";
    selBox.value = val;
    document.body.appendChild(selBox);
    selBox.focus();
    selBox.select();
    document.execCommand("copy");
    document.body.removeChild(selBox);
  }

  public openShareMenu(){
    const shareData = {
      title: 'Come and play football!',
      text: 'Yo! I am inviting you to Footballer Zone so we can play some football together. Log in and join the match ' + this.event.name + '! Long live football!',
      url: 'https://app.footballer.zone',
    }

    try {
      navigator.share(shareData)
    } catch(err) {
      console.log('Error: ' + err);
      this.showNewNogoshUserDialog = true;
    }
  }

  public goBack(): void {
    //console.log('uh');
    this._location.back();
  }

  public generateCombinations(players: Player[], teamSize: number): Player[][] {
    const result: Player[][] = [];
  
    function helper(start: number, team: Player[]): void {
      if (team.length === teamSize) {
        result.push([...team]);
        return;
      }
  
      for (let i = start; i < players.length; i++) {
        team.push(players[i]);
        helper(i + 1, team);
        team.pop();
      }
    }
  
    helper(0, []);
    return result;
  }
  
  public balanceTeams(players: Player[]): { teamA: Player[]; teamB: Player[] } {
    const teamSize = Math.floor(players.length / 2);
    const combinations = this.generateCombinations(players, teamSize);
  
    let minTotalDifference = Infinity;
    let minOffenseDifference = Infinity;
    let minDefenseDifference = Infinity;
    let minComboName = '';
    let bestTeams: { teamA: any[]; teamB: any[] } | null = null;
  
    for (const [index, teamA] of combinations.entries()) {
      console.log(`------------------- BEGIN ${index + 1} -----------------------------`);
      const teamB = players.filter((player) => !teamA.includes(player));
  
      const totalAOffense = teamA.reduce((sum, player) => sum + (player.ratingOffence.length > 0 ? player.ratingOffence.reduce((acc, r) => acc + r.rating, 0) / player.ratingOffence.length : 5), 0);
      const totalADefense = teamA.reduce((sum, player) => sum + (player.ratingDefence.length > 0 ? player.ratingDefence.reduce((acc, r) => acc + r.rating, 0) / player.ratingDefence.length : 5), 0);
      const totalBOffense = teamB.reduce((sum, player) => sum + (player.ratingOffence.length > 0 ? player.ratingOffence.reduce((acc, r) => acc + r.rating, 0) / player.ratingOffence.length : 5), 0);
      const totalBDefense = teamB.reduce((sum, player) => sum + (player.ratingDefence.length > 0 ? player.ratingDefence.reduce((acc, r) => acc + r.rating, 0) / player.ratingDefence.length : 5), 0);
  
      const offenseDifference = Math.abs(totalAOffense - totalBOffense);
      const defenseDifference = Math.abs(totalADefense - totalBDefense);
      const totalDifference = offenseDifference + defenseDifference;
  
      console.log('Team A:', teamA.map(player => player.name));
      console.log('Team B:', teamB.map(player => player.name));
      console.log('Total A Offense:', totalAOffense);
      console.log('Total A Defense:', totalADefense);
      console.log('Total B Offense:', totalBOffense);
      console.log('Total B Defense:', totalBDefense);
      console.log('Offense Difference:', offenseDifference);
      console.log('Defense Difference:', defenseDifference);
      console.log('Total Difference:', totalDifference);
      console.log('minComboName:', minComboName);

      if (totalDifference < minTotalDifference) {
        console.log('Found a more balanced matchup above!!!!!!!!!');
        minComboName = `Combo ${index + 1}`;
        minTotalDifference = totalDifference;
        minDefenseDifference = defenseDifference;
        minOffenseDifference = offenseDifference;
        console.log('new totalDifference:', minTotalDifference);
        console.log('new minOffenseDifference:', minOffenseDifference);
        console.log('new minDefenseDifference:', minDefenseDifference);
  
        bestTeams = { teamA, teamB };
      }

      console.log(`------------------- END ${index + 1} -----------------------------`);
    }
  
    console.log('Best Team A:', bestTeams!.teamA.map(player => player.name));
    console.log('Best Team B:', bestTeams!.teamB.map(player => player.name));
    console.log('Offense Difference:', minOffenseDifference);
    console.log('Defense Difference:', minDefenseDifference);
    console.log('Total Difference:', minTotalDifference);
  
    return bestTeams!;
  }
  
  

  async assignTeams(): Promise<void> { 
    let playersComingToEvent = this.event.playerEventInfos.filter(pei => pei.status === this.playerEventInfoStatus.CONFIRMED);

    let bestTeams = this.balanceTeams(playersComingToEvent.map(pei => pei.player));
    // console.log(bestTeams);

    // reset all to white jersey
    console.log("resetting all to white jersey");
    for (let pei of this.event.playerEventInfos) {
      if (pei.status === this.playerEventInfoStatus.CONFIRMED) {
        if(pei.jersey === this.playerEventInfoJersey.BLACK) { 
          await this.toggleSubteamJersey(pei);
        }
      }
    }

    //now assign some to black
    console.log("setting some to black jersey");
    for (let pei of this.event.playerEventInfos) {
      if (pei.status === this.playerEventInfoStatus.CONFIRMED) {
        if (bestTeams.teamB.find(player => player.id === pei.player.id)) {
          await this.toggleSubteamJersey(pei);
        }
      }
    }
  }
}
