import { Component, OnInit } from '@angular/core';
import { Event} from '../shared/models/event';
import { EventService } from '../shared/services/event.service';

@Component({
    templateUrl: './admin.component.html',
})
export class AdminComponent implements OnInit {

    customers: Event[];

    selectedCustomers: Event[];

    statuses: any[];

    loading: boolean = true;

    activityValues: number[] = [0, 100];

    constructor(private customerService: EventService) { }

    ngOnInit() {
        this.customerService.getAllActiveEvents().subscribe(customers => {
            this.customers = customers;
            this.loading = false;

            //this.customers.forEach(customer => e.date = new Date(customer.date));
        });

        this.statuses = [
            {label: 'Unqualified', value: 'unqualified'},
            {label: 'Qualified', value: 'qualified'},
            {label: 'New', value: 'new'},
            {label: 'Negotiation', value: 'negotiation'},
            {label: 'Renewal', value: 'renewal'},
            {label: 'Proposal', value: 'proposal'}
        ]
    }
}