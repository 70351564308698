import {NgModule, CUSTOM_ELEMENTS_SCHEMA} from '@angular/core';
import {CommonModule} from '@angular/common';
import {CoreModule} from "../core/core.module";
import {FormsModule} from '@angular/forms';
import {PlayerDetailFormComponent} from './player-detail/player-detail.component';
import {PlayerService} from '../shared/services/player.service';
import {PlaySpaceService} from "../shared/services/playspace.service";
import {IpInfoLocationService} from "../shared/services/ipinfo.location.service";
import {PlayerDetailsPopupComponent} from './player-details-popup/player-details-popup.component';
//import {NgxLoadingModule} from 'ngx-loading';
import {PlayerListComponent} from "./player-list/player-list.component";
import {PopoverModule} from 'ngx-bootstrap/popover';
import {TypeaheadModule} from 'ngx-bootstrap/typeahead';
import {BsDropdownModule} from 'ngx-bootstrap/dropdown';
import { NgxSpinnerModule } from "ngx-spinner";
import { RatingModule } from 'ngx-bootstrap/rating';


@NgModule({
  imports: [
    CommonModule, 
    FormsModule, 
    PopoverModule, 
    CoreModule, 
    TypeaheadModule, 
    BsDropdownModule, 
//    NgxLoadingModule.forRoot({}), 
    NgxSpinnerModule,
    RatingModule
  ],
  exports: [PlayerDetailFormComponent, PlayerDetailsPopupComponent, PlayerListComponent],
  declarations: [PlayerDetailFormComponent, PlayerDetailsPopupComponent, PlayerListComponent],
  providers: [PlayerService, PlaySpaceService, IpInfoLocationService],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class PlayerModule {
}
