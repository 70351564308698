<div [@dialog] *ngIf="visible" class="modal fade show d-block modal-component" tabindex="-1" role="dialog"  aria-modal="true">

  <div  class="modal-dialog modal-dialog-scrollable modal-dialog-centered" role="document">  
    <div class="modal-content">
      <ng-content></ng-content>
      <a href="javascript:" *ngIf="closable" (click)="close()" aria-label="Close" class="dialog__close-btn text-secondary h4">
        <i class="fas fa-times-circle "><span class="sr-only">Close Dialog</span></i>
      </a>
    </div>     
    
  </div>
  
 <div *ngIf="visible" class="overlay" (click)="close()"></div>
</div>
<div *ngIf="visible" class="modal-backdrop fade show" ></div>