import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { AuthService } from '@auth0/auth0-angular';
import { ApiVersion } from 'app/shared/models/apiVersion';
import { ApiVersionService } from 'app/shared/services/apiVersion.service';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html'
})
export class FooterComponent implements OnInit {

  public apiVersion: ApiVersion;

  constructor(public auth: AuthService, private authHttp: HttpClient, private apiVersionService: ApiVersionService) {
  }

  ngOnInit() {
    this.apiVersionService.getApiVersion().subscribe((apiVersion) => {
        console.log("apiVersion: " + apiVersion.apiCommitId);
        this.apiVersion = apiVersion;
      },
      (error) => {
        console.log(error);
      }
    );
  }

}
