import {PlaySpace} from './playSpace';
import {Player} from "./player";
import {PlayerEventInfo} from "./playerEventInfo";

export class Event {

  id: string;
  originalEventId: string;

  name: string;
  manager: Player;

  active: boolean;
  rulebook: string;

  locationId: string;
  playSpace: PlaySpace;

  nextOccurrenceTimestamp: Date;
  nextOccurrenceTimestampZone: string;
  recurringType: string;

  season1StartDate: Date;
  season2StartDate: Date;

  teamWhite: boolean;
  whiteScore: number;
  blackScore: number;
  wonLostDrewNothing: string;

  imageUrl: string;

  playerEventInfos: PlayerEventInfo[] = Array<PlayerEventInfo>();

  sosUsed: boolean;
  confirmedCount: number;
  maxPlayers: number;

  note: string;

  sendingNotificationsInProgress: boolean = false;
  currentPlayerInviteStatus: string;

  viewedByManager: boolean = false;
  viewedByPlayer: boolean = false;
  viewedByCandidate: boolean = false;

  newCandidatesFound: boolean = false;

  historyEvent = false;
}

export class EventRecurringType {
  DAILY: string = "DAILY";
  WEEKLY: string = "WEEKLY";
  MONTHLY: string = "MONTHLY";
  ONE_OFF: string = "ONE_OFF";
}
