<!-- <ngx-loading [show]="loading" [config]="{ backdropBorderRadius: '3px' }"></ngx-loading> -->
<ngx-spinner
bdColor="rgba(51,51,51,0.8)"
size="medium"
color="#fff"
type="ball-scale-multiple"
><p style="font-size: 20px; color: white">Loading...</p>
</ngx-spinner>

<div *ngIf="!loading" class="container mb-4" role="main">

    <header class="row my-3 my-md-4 mb-md-5 align-items-center">
        <!-- <a href="javascript:" class="btn-back col-auto border-right align-self-stretch" (click)="goBack()"><i class="fas fa-arrow-left"><span class="sr-only">Back</span></i></a> -->
        <div class="col">      
            <h2 class="m-0">Feedback</h2> 
        </div>
        <div class="header-divider"></div>
    </header>

    <div class="row my-3 my-md-4">
        <div class="col-12 col-md-10 col-lg-7 mx-auto">
            <p>Please give us feedback in the form of ideas, suggestions, dislikes, likes, whatever it is - we would
                like to hear it. We are here to listen and improve! Below is a list of existing feedback. </p>
        </div>
    </div>
    <div class="row">
        <div class="col-12 col-md-10 col-lg-7 mx-auto">
            <form (ngSubmit)="onSubmit()" autocomplete="off" #feedbackForm="ngForm">
                <div class="form-group">
                    <label for="subject" class="mb-0">Subject</label>
                    <input type="text" class="form-control" id="subject" placeholder="Enter the subject here" required
                        [(ngModel)]="feedback.subject" name="subject">
                </div>
                <div class="form-group">
                    <label for="feedback" class="mb-0">Feedback</label>
                    <textarea type="text" class="form-control" id="feedback" required [(ngModel)]="feedback.feedback"
                        name="feedback">Enter the details here...
                    </textarea>
                </div>
                <div class="form-group anonymous-checkbox">
                    <div class="custom-control custom-switch">
                        <input type="checkbox" class="custom-control-input  cursor-pointer" id="anonymous" [(ngModel)]="feedback.anonymous" name="anonymous">
                        <label class="custom-control-label  cursor-pointer" for="anonymous">Anonymous</label>
                    </div>

                    <!-- <label for="feedback">Anonymous</label>
                    <input type="checkbox" class="form-control" id="anonymous" [(ngModel)]="feedback.anonymous" name="anonymous"> -->
                </div>
                <div class="">
                    <div class="row justify-content-end">
                        <div class="col-auto pr-0">
                            <button type="button" class="btn btn-secondary text-white px-3" (click)="cancelFeedback()">Cancel</button>
                        </div>
                        <div class="col-auto ">
                            <button type="submit" class="btn btn-primary px-3" [ngClass]="!feedbackForm.form.valid ? 'disabled' : ''" [disabled]="!feedbackForm.form.valid">Save</button>
                        </div>                        
                    </div>

                </div>
            </form>
            <hr class="my-5">
        </div>
        
    </div>

    <div class="row" *ngFor="let feedback of feedbacks; let idx = index">
        <div class="col-12 col-md-10 col-lg-7 mx-auto mb-4">
            <div class="card bg-light">
                <div class="card-body">
                    <div class="row">
                        <div class="col-12">
                            <a href="#" (click)="viewFeedback(idx);$event.preventDefault()" class="no-underline">
                                <strong>{{feedback.subject}}</strong>
                            </a>
                        </div>
                    </div>
                    <div id="feedbackDetails" class="row">
                        <div class="col-12">
                            <span *ngIf="!feedback.anonymous">by {{feedback.email}}</span>
                            <span *ngIf="feedback.anonymous">by Anonymous</span>
                            <span> on {{feedback.createdDate | date:'short'}}.</span>
                            <br>
                            <p *ngIf="feedbackVisibility[idx]">{{feedback.feedback}}</p>
                        </div>
                    </div>
                </div>
            </div><!-- /.card   -->
        </div>
    </div>
</div>
