<!-- <ngx-loading [show]="loading" [config]="{ backdropBorderRadius: '3px' }"></ngx-loading> -->
<ngx-spinner
  bdColor="rgba(51,51,51,0.8)"
  size="medium"
  color="#fff"
  type="ball-scale-multiple"
><p style="font-size: 20px; color: white">Loading...</p>
</ngx-spinner>

<div *ngIf="!loading" class="container" role="main">

  <header class="row my-3 my-md-4 mb-md-5 align-items-center">
    <div class="col">      
      <h2 class="m-0">Chats</h2> 
    </div>
    <div class="col-auto">
      <button (click)="createNewChat()" class="btn btn-sm px-3 btn-primary">New chat</button>
    </div>

    <div class="header-divider"></div>
  </header>

  <div *ngFor="let chat of chats" class="border-bottom border-bottom-not-last mb-3 hide-empty">
    <div class="row align-items-center mb-3">
      <div class="col line-height-13 text-truncate"><strong class="text-break">{{chat.displayName}}</strong></div>

      <div class="col-auto ml-auto">
        <button (click)="enterChat(chat)" class="btn btn-sm px-3 btn-success">Enter</button>  
      </div>
    </div>
  </div>

</div>
