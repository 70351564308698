import {Observable} from 'rxjs';
import {Injectable} from "@angular/core";
import {environment} from "../../environments/environment";
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {PlayerEventInfo} from "../models/playerEventInfo";
import {Player} from '../models/player';

@Injectable()
export class PlayerEventInfoService {

  private apiUrl: string = environment.API_BASE_URL + '/playereventinfo';

  constructor(private authHttp: HttpClient) {
  }

  private static getRequestOptions() {
    return {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    };
  }

  public create(body: PlayerEventInfo, currentPlayer: Player): Observable<PlayerEventInfo> {
    body.statusModifiedByPlayerId = currentPlayer.id;
    body.statusModifiedByPlayerEmail = currentPlayer.email;

    let bodyString = JSON.stringify(body);
    return this.authHttp.post<PlayerEventInfo>(`${this.apiUrl}`, bodyString, PlayerEventInfoService.getRequestOptions());
  }

  public fetchForEvent(eventId: string): Observable<PlayerEventInfo[]> {
    return this.authHttp.get<PlayerEventInfo[]>(`${this.apiUrl}/event/${eventId}`, PlayerEventInfoService.getRequestOptions());
  }

  public update(body: PlayerEventInfo, currentPlayer: Player): Observable<PlayerEventInfo> {
    body.statusModifiedByPlayerId = currentPlayer.id;
    body.statusModifiedByPlayerEmail = currentPlayer.email;

    let bodyString = JSON.stringify(body);
    return this.authHttp.put<PlayerEventInfo>(`${this.apiUrl}/${body['id']}`, bodyString, PlayerEventInfoService.getRequestOptions());
  }

  public getPlayerEventInfo(id: string): Observable<PlayerEventInfo> {
    return this.authHttp.get<PlayerEventInfo>(`${this.apiUrl}/${id}`);
  }

  public delete(id: string): Observable<PlayerEventInfo> {
    return this.authHttp.delete<PlayerEventInfo>(`${this.apiUrl}/${id}`);
  }
}
