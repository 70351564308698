
import {throwError as observableThrowError, Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'app/environments/environment';

@Injectable()
export class FreeGeoIpLocationService {

  constructor(private http: HttpClient) { }

  public getCurrentLocation(): Observable<FreeGeoIpResponse> {
    return this.http.get<FreeGeoIpResponse>(environment.freeGeoIpApiUrl);
  }
}

export class FreeGeoIpResponse {
  public ip: string;
  public country_code: string;
  public country_name: string;
  public region_code: string;
  public region_name: string;
  public city: string;
  public zip_code: string;
  public time_zone: string;
  public latitude: string;
  public longitude: string;
  public metro_code: string;

}
