
import { throwError as observableThrowError, Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { Stats } from '../models/stats';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Event } from '../models/event';

@Injectable()
export class StatsService {

  private apiUrl: string = environment.API_BASE_URL + '/stats';

  constructor(private authHttp: HttpClient) { }

  private getRequestOptions() {
    return {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    };
  }

  public getEventStats(originalEventId: string): Observable<Stats[]> {
    return this.authHttp.get<Stats[]>(`${this.apiUrl}/${originalEventId}`, this.getRequestOptions());
  }

  public recalculateStatsForEvent(originalEventId: string): Observable<Event> {
    return this.authHttp.post<Event>(`${this.apiUrl}/recalculate/${originalEventId}`, 'somestupidstring', this.getRequestOptions());
  }
}
