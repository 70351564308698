import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { FeedbackComponent } from './feedback.component';
import { FeedbackService } from '../shared/services/feedback.service';
//import { NgxLoadingModule } from 'ngx-loading';
import { NgxSpinnerModule } from "ngx-spinner";

@NgModule({
  imports: [
    CommonModule, 
    FormsModule, 
//    NgxLoadingModule.forRoot({}), 
    NgxSpinnerModule
  ],
  exports: [FeedbackComponent],
  declarations: [FeedbackComponent],
  providers: [FeedbackService],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class FeedbackModule { }
