import { Component, OnInit } from "@angular/core";
import { Subscription } from "rxjs";
import { environment } from "../app/environments/environment";
import { Router, NavigationEnd } from "@angular/router";
import { AuthService } from "@auth0/auth0-angular";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Player } from "./shared/models/player";
import { switchMap } from "rxjs/operators";
import { ApiVersion } from "./shared/models/apiVersion";

declare const gtag: any;

@Component({
  selector: "app-root",
  providers: [],
  templateUrl: "app.component.html",
  styleUrls: ["./app.component.css"],
})
export class AppComponent implements OnInit {
  public loading: boolean = true;
  subscription: Subscription;

  constructor(public auth: AuthService, private router: Router, private authHttp: HttpClient) {
    if (environment.production) {
      this.router.events.subscribe((event) => {
        if (event instanceof NavigationEnd) {
          gtag("event", "page", event.urlAfterRedirects);
        }
      });
    }
  } 

  ngOnInit() {
    this.auth.user$
      .pipe(
        switchMap((profile) => {
          console.log("USER", JSON.stringify(profile, null, 2));
          if (profile == null) {
            return;
          }

          // if (navigator.geolocation) {
          //   navigator.geolocation.getCurrentPosition((position) => {
          //     profile["location"] = {
          //       latitude: position.coords.latitude,
          //       longitude: position.coords.longitude,
          //     };
          //   });
          // } else {
          //   console.log("NE PODRZAVA");
          // }

          // /*   profile["location"] = {
          //   latitude: "45.7471683",
          //   longitude: "15.7944213",
          // }; */
          profile.user_id = profile.sub;
          return this.authHttp.post(environment.API_BASE_URL + "/profile", profile);
        })
      )
      .subscribe(
        (response: any) => {
          this.setCurrentPlayer(response);
          this.loading = false;
          console.log("BACKEND", JSON.stringify(response, null, 2));
        },
        (error) => {
          console.log("error: " + JSON.stringify(error));
          console.log("Letting the user know that he needs to log in with a different email.");
          if(JSON.stringify(error).includes("existingAuth")){
            if(confirm("It looks like you are not using the same login method as before: Apple vs Facebook vs Google vs Email? We have you registered with: " + error.error.existingAuth  
            + ", but you just tried logging in with: " + error.error.attemptedWith + ". Please use the: " + error.error.existingAuth 
            + " to log in. Otherwise delete your existing account and reregister with a desired method (Apple vs Facebook vs Google vs email).")){
              document.location.href ="";
              console.log("user confirmed");
             }
           }
        }
      );

    this.auth.isAuthenticated$.subscribe((sub) => console.log("IS logged in", sub));

    this.auth.idTokenClaims$.subscribe((token) => {
      if (!token.__raw) {
        console.log("NO TOKEN");
      }
      let httpOptions = {
        headers: new HttpHeaders({
          "Content-Type": "application/json",
          Authorization: `Bearer ${token.__raw}`,
        }),
      };
    });
  }
  login(): void {
    this.auth.loginWithRedirect();
  }

  private setCurrentPlayer(player: Player): void {
    // problems with circular dependency if this function is used from playerService

    if (!player.avatar || !player.avatar.startsWith("http")) {
      player.avatar = environment.DEFAULT_AVATAR_URL;
    }

    localStorage.setItem("player", JSON.stringify(player));
  }

  private showFooter():boolean{
    return !this.router.url.includes("messages");
    //console.log("current url: " + this.router.url);
  }
}
