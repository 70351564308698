import {Player} from "./player";
import {Message} from "./message";

export class Chat {
  id?: string;
  name: string;
  displayName: string;
  contact: Player;
  entityId: string;
  entityName: string;
  entityType: string;

  mutedPlayers: string[];
  players: Player[]; // on backend players is a list of ids

  text: string;
  created: Date;
  lastMessageDate: string;
  priority: number;

  messages: Message[];

  viewedByManager: boolean;
}

export class ChatEntityType {
  EVENT: string = "EVENT";
  PLAYER: string = "PLAYER";
}
