
import {throwError as observableThrowError,  Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { Feedback } from '../models/feedback';
import { HttpClient, HttpHeaders } from '@angular/common/http';

@Injectable()
export class FeedbackService {

  private apiUrl: string = environment.API_BASE_URL + '/feedback';
  constructor(private authHttp: HttpClient) { }

  private getRequestOptions() {
    return {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    };
  }

  public getFeedback(): Observable<Feedback[]> {
    return this.authHttp.get<Feedback[]>(`${this.apiUrl}`, this.getRequestOptions());
  }

  public createFeedback(body: Object): Observable<Feedback> {
    let bodyString = JSON.stringify(body);
    return this.authHttp.post<Feedback>(`${this.apiUrl}`, bodyString, this.getRequestOptions());
  }
}
