import { Component, Input, OnInit, QueryList, ViewChildren } from "@angular/core";
import { Event, EventRecurringType } from "../../shared/models/event";
import { EventService } from "../../shared/services/event.service";
import { PlayerService } from "../../shared/services/player.service";
import { Observable } from "rxjs";
import { PlaySpaceService } from "../../shared/services/playspace.service";
import { PlaySpace } from "../../shared/models/playSpace";
import { Player } from "../../shared/models/player";
import { StatsService } from "../../shared/services/stats.service";
import { Stats } from "../../shared/models/stats";
import { ActivatedRoute, Router } from "@angular/router";
import { Location } from "@angular/common";
import * as moment from "moment";
import { LocalizationService } from "app/shared/services/localization.service";
import { PlayerEventInfo, PlayerEventInfoStatus, PlayerEventInfoJersey, TeamMemberType } from "app/shared/models/playerEventInfo";
import { PlayerEventInfoService } from "app/shared/services/playereventinfo.service";
import { NgxSpinnerService } from "ngx-spinner";
import { BsModalService, BsModalRef } from "ngx-bootstrap/modal";
import { ModalContentComponent } from "../../shared/modal/modal-content.component";
import { debounceTime, mergeMap } from "rxjs/operators";
import { MapInfoWindow, MapMarker } from "@angular/google-maps";
import { BsDatepickerConfig } from "ngx-bootstrap/datepicker";

@Component({
  selector: "event-detail",
  templateUrl: "./event-detail.component.html",
  styleUrls: ["../event.component.css"],
})
export class EventDetailComponent implements OnInit {
  @Input() public editMode: boolean = false;

  public event: Event;
  public eventOld: Event;
  public currentPlayer: Player;

  public currentDate: Date;
  public selectedDate: Date;
  public selectedHour: number;
  public selectedMinute: number;

  public autocompletePlayspace: string;
  public locationNameSelected: boolean;
  public typeaheadLocationLoading: boolean;
  public typeaheadLocationNoResults: boolean;
  public locationDataSource: Observable<any>;

  public toggleModified = false;
  public radioRecurringType: EventRecurringType = new EventRecurringType();

  public mapLatitude: number = 44.78;
  public mapLongitude: number = 20.44;
  public zoom: number = 11;
  public markers: PlaySpace[] = Array<PlaySpace>();
  mapOptions: google.maps.MapOptions;


  public stats: Stats[];
  public showDeleteDialog: boolean = false;

  public loading = false;

  public autocompleteSelectedPlayer: string;
  public typeaheadMngPlayerLoading: boolean;
  public typeaheadMngPlayerNoResults: boolean;
  public managerEmailSelected: boolean;
  public playerDataSource: Observable<any>;

  public playerEventInfoStatus: PlayerEventInfoStatus = new PlayerEventInfoStatus();
  public playerEventInfoJersey: PlayerEventInfoJersey = new PlayerEventInfoJersey();
  public teamMemberType: TeamMemberType = new TeamMemberType();

  public eventForDelete: Event;

  public navigateHref: string = "";
  bsModalRef: BsModalRef;

  bsConfig: Partial<BsDatepickerConfig>;

  @ViewChildren(MapInfoWindow) infoWindows: QueryList<MapInfoWindow>;

  constructor(
    public playerService: PlayerService,
    public eventService: EventService,
    public route: ActivatedRoute,
    public router: Router,
    public statsService: StatsService,
    public localizationService: LocalizationService,
    public location: Location,
    private playSpaceService: PlaySpaceService,
    private playereventinfoService: PlayerEventInfoService,
    private spinner: NgxSpinnerService,
    private modalService: BsModalService
  ) {
    this.loading = true;
    this.spinner.show();

    this.bsConfig = {
      containerClass: 'theme-dark-blue', // Add a custom class to the datepicker container
    };

    this.locationDataSource = new Observable((observer: any) => {
      observer.next(this.autocompletePlayspace);
    })
    .pipe(
      debounceTime(500),
      mergeMap((token: string) => this.getPlaySpacesAsObservable(token))
    );

    this.playerDataSource = new Observable((observer: any) => {
      observer.next(this.autocompleteSelectedPlayer);
      this.spinner.hide();
      this.loading = false;
    })
    .pipe(
      debounceTime(500),
      mergeMap((token: string) => this.getPlayersAsObservable(token))
    );
  }

  ngOnInit() {
    window.scroll(0, 0);

    let me = this;

    if (!localStorage.getItem("event")) {
      me.router.navigate(["/event-list"]);
      return;
    }

    me.loading = true;
    me.spinner.show();
    me.editMode = JSON.parse(me.route.snapshot.params["editSelectedEvent"]);

    me.event = JSON.parse(localStorage.getItem("event"));
    me.eventOld = JSON.parse(JSON.stringify(me.event));

    if (me.editMode && !me.event.rulebook) {
      me.localizationService.getJSON().subscribe(
        (resp) => {
          me.event.rulebook = resp.RULEBOOK_DEFAULT;
        },
        (error) => {
          console.log(error);
        }
      );
    }

    let returningFromPlayspaceCreation = JSON.parse(sessionStorage.getItem("returningFromNewPlayspaceForm"));
    if (returningFromPlayspaceCreation) {
      me.event = JSON.parse(localStorage.getItem("savedEventEdit"));

      let newLocation: PlaySpace = JSON.parse(sessionStorage.getItem("newPlayspace"));
      if (newLocation) {
        me.event.playSpace = newLocation;
      }
    }
    localStorage.removeItem("savedEventEdit");
    sessionStorage.removeItem("returningFromNewPlayspaceForm");

    me.loading = true;
    me.spinner.show();

    if (!me.event) {
      me.event = new Event();

      me.event.recurringType = me.radioRecurringType.WEEKLY;
      me.event.viewedByManager = true;
    } else {
      if (me.event.id) {
        me.statsService.getEventStats(me.event.originalEventId).subscribe(
          (stats) => {
            me.stats = stats;
          },
          (error) => {
            console.log(error);
          }
        );
      }
    }

    me.currentPlayer = me.playerService.getCurrentPlayer();
    if (me.currentPlayer.id === me.event.manager.id) {
      me.event.viewedByManager = true;
    }

    if (me.event.season1StartDate) {
      //me.event.season1StartDate = moment.tz(me.event.season1StartDate, me.event.nextOccurrenceTimestampZone).toDate();?
      me.event.season1StartDate = moment(me.event.season1StartDate).toDate();
      me.event.season2StartDate = moment(me.event.season2StartDate).toDate();
    } else {
      me.event.season1StartDate = moment().toDate();
      me.event.season2StartDate = moment().toDate();
    }

    me.event.originalEventId = me.event.originalEventId ? me.event.originalEventId : me.event.id;

    me.autocompletePlayspace = me.event.playSpace.name;
    me.autocompleteSelectedPlayer = me.event.manager.name;

    me.markers = JSON.parse(localStorage.getItem("nearByLocations"));

    me.markers.forEach(marker => {
      marker.googleLatLng = new google.maps.LatLng(marker.latitude, marker.longitude);
    });

    if (me.event.playSpace.latitude) {
      me.mapLatitude = me.event.playSpace.latitude;
      me.mapLongitude = me.event.playSpace.longitude;
      me.event.playSpace.googleLatLng = new google.maps.LatLng(me.event.playSpace.latitude, me.event.playSpace.longitude);

      me.markers.push(me.event.playSpace);
    } else {
      me.mapLatitude = me.currentPlayer.location.coordinates[0];
      me.mapLongitude = me.currentPlayer.location.coordinates[1];
    }


    this.mapOptions = {
      center: {lat: me.mapLatitude, lng: me.mapLongitude},
      zoom: this.zoom
    };

    me.currentDate = moment().toDate();
    me.selectedDate = moment(me.event.nextOccurrenceTimestamp).toDate();
    me.selectedHour = moment(me.event.nextOccurrenceTimestamp).toDate().getHours();
    me.selectedMinute = moment(me.event.nextOccurrenceTimestamp).toDate().getMinutes();

    if (me.event.confirmedCount == 0) {
      me.event.confirmedCount = me.eventService.getConfirmedCount(me.event);
    }
    console.log(me.event);
    me.loading = false;
    me.spinner.hide();
  }

  public openMap(): void {
    if (navigator.platform.indexOf("iPhone") != -1 || navigator.platform.indexOf("iPod") != -1 || navigator.platform.indexOf("iPad") != -1) {
      this.navigateHref = "http://maps.apple.com/?q=" + this.event.playSpace.latitude + "," + this.event.playSpace.longitude;
    } else {
      this.navigateHref = "https://maps.google.com/maps?daddr=" + this.event.playSpace.latitude + "," + this.event.playSpace.longitude + "&amp;ll=";
    }
  }

  public saveEventState(): void {
    localStorage.setItem("savedEventEdit", JSON.stringify(this.event));
  }

  public editEvent(): void {
    this.editMode = true;
  }

  public setEventForDelete(event: Event): void {
    this.eventForDelete = event;
  }

  public removeEvent(): void {
    this.loading = true;
    this.spinner.show();

    this.eventService.removeAllEventOccurrenceByOriginalId(this.eventForDelete.id).subscribe(
      () => {
        this.router.navigate(["/event-list"]);
        this.loading = false;
        this.spinner.hide();
      },
      (res) => {
        this.router.navigate(["/event-list"]);
        console.log(res.error);
        this.loading = false;
        this.spinner.hide();
      }
    );
  }

  openInfoWindow(marker: MapMarker, markerNo: number) {
    this.infoWindows.get(markerNo).open(marker);
  }

  public choosePlayLocation(playSpace: PlaySpace): void {
    this.locationNameSelected = true;
    this.event.playSpace = playSpace;
    this.autocompletePlayspace = playSpace.name;
  }

  public onSubmit(viewedByManager: boolean): void {
    let me = this;

    if (!viewedByManager) {
      for (let playerEventInfo of me.event.playerEventInfos) {
        if (me.currentPlayer.id === playerEventInfo.player.id) {
          me.loading = true;
          me.spinner.show();
          break;
        }
      }

      return;
    }

    me.toggleModified = false;

    me.event.nextOccurrenceTimestamp = moment(me.selectedDate).toDate();
    me.event.nextOccurrenceTimestamp.setHours(me.selectedHour);
    me.event.nextOccurrenceTimestamp.setMinutes(me.selectedMinute);
    me.event.nextOccurrenceTimestamp.setSeconds(0);
    me.event.nextOccurrenceTimestampZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

    me.event.locationId = me.event.playSpace.id;
    me.event.playSpace.location = null;

    me.loading = true;
    me.spinner.show();

    if (me.event.id == null) {
      me.event.manager = this.currentPlayer;
      me.createEvent();
    } else {
      me.eventService.updateEvent(me.event).subscribe(
        (event) => {
          me.event = event;

          me.event.nextOccurrenceTimestamp = moment(event.nextOccurrenceTimestamp).toDate();
          me.event.nextOccurrenceTimestampZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
          me.event.viewedByManager = true;

          me.router.navigate(["/event-list"]);
          me.spinner.hide();
          me.loading = false;
        },
        (error) => {
          console.log(error);

          me.router.navigate(["/event-list"]);
          me.spinner.hide();
          me.loading = false;
        }
      );
    }
  }

  private addPlayerToPlayerEventInfosList(player: Player): void {
    let currentPlayerEventInfosList = this.event.playerEventInfos;
    let playerEventInfoFound: boolean = false;
    if (currentPlayerEventInfosList !== undefined) {
      for (let playerEventInfo of currentPlayerEventInfosList) {
        if (player.id === playerEventInfo.player.id) {
          playerEventInfoFound = true;
          break;
        }
      }
    }
    if (!playerEventInfoFound) {
      let newPlayerEventInfo: PlayerEventInfo = new PlayerEventInfo();
      newPlayerEventInfo.eventId = this.event.id;
      newPlayerEventInfo.player = player;
      newPlayerEventInfo.jersey = this.playerEventInfoJersey.WHITE;
      newPlayerEventInfo.status = this.playerEventInfoStatus.NOT_NOTIFIED;
      newPlayerEventInfo.teamMemberType = this.teamMemberType.RESERVE;

      if (newPlayerEventInfo.eventId) {
        this.playereventinfoService.create(newPlayerEventInfo, this.currentPlayer).subscribe(
          (playerEventInfo) => {
            this.event.playerEventInfos.push(playerEventInfo);
          },
          (error) => {
            console.log(error);
          }
        );
      } else {
        this.event.playerEventInfos.push(newPlayerEventInfo);
      }
    }
  }

  public changeTypeaheadLoading(e: boolean, fieldName: string): void {
    switch (fieldName) {
      case "locationName":
        this.locationNameSelected = false;
        this.typeaheadLocationLoading = e;
        break;
      case "managerName":
        this.managerEmailSelected = false;
        this.typeaheadMngPlayerLoading = e;
        break;
    }
  }

  public changeTypeaheadNoResults(e: boolean, fieldName: string): void {
    switch (fieldName) {
      case "locationName":
        this.locationNameSelected = false;
        this.typeaheadLocationNoResults = e;
        break;
      case "managerName":
        this.managerEmailSelected = false;
        this.typeaheadMngPlayerNoResults = e;
        break;
    }
  }

  public typeaheadManagerEmailSelected(selectedObject: any): void {
    this.managerEmailSelected = true;
    this.event.manager = selectedObject.item;
  }

  public typeaheadLocationNameSelected(selectedObject: any): void {
    this.locationNameSelected = true;
    this.event.playSpace = selectedObject.item;

    this.mapLatitude = this.event.playSpace.latitude;
    this.mapLongitude = this.event.playSpace.longitude;
    this.event.playSpace.googleLatLng = new google.maps.LatLng(this.event.playSpace.latitude, this.event.playSpace.longitude);

    this.markers.push(this.event.playSpace);
  }

  public checkIfLocationNameIsSelected(): void {
    if (!this.locationNameSelected) {
      this.autocompletePlayspace = null;
    }
  }

  public cancelEventDetailsChange(): void {
    let me: any = this;
    me.event = JSON.parse(JSON.stringify(me.eventOld));

    me.editMode = false;
    if (!me.event.id) {
      me.router.navigate(["/event-list"]);
    }
  }

  public getPlayersAsObservable(partialPlayerNickName: string): Observable<any> {
    return this.playerService.getPlayers(partialPlayerNickName);
  }

  private getPlaySpacesAsObservable(partialLocationName: string): Observable<any> {
    return this.playSpaceService.getPlaySpaces(partialLocationName);
  }

  private createEvent(): void {
    this.eventService.createEvent(this.event).subscribe(
      (event) => {
        this.event = event;
        this.event.nextOccurrenceTimestamp = new Date(event.nextOccurrenceTimestamp);
        this.event.viewedByManager = true;
        this.addPlayerToPlayerEventInfosList(this.currentPlayer);

        localStorage.setItem("event", JSON.stringify(event));
        this.loading = false;
        this.spinner.hide();
        this.router.navigate(["/event-players", false]);
      },
      (error) => {
        console.log(error);
        this.spinner.hide();
        this.loading = false;
      }
    );
  }

  public goBack(): void {
    //console.log('uh');
    this.location.back();
  }

  public requestToJoin(event: Event): void {
    this.loading = true;
    this.spinner.show();
    this.eventService.requestToJoinEvent(event, this.currentPlayer).subscribe(
      () => {
        const initialState = { title: "Alert", text: "A request has been sent to the match manager and will be considered. Keep an eye on your inbox for new info!" };
        this.bsModalRef = this.modalService.show(ModalContentComponent, { initialState });
        this.bsModalRef.content.closeBtnName = "Close";
        this.event.viewedByCandidate = true;
        this.spinner.hide();
        this.loading = false;
      },
      (res) => {
        console.log(res.error);
        this.spinner.hide();
        this.loading = false;
      }
    );
  }
}
