import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment-timezone';

@Pipe({ name: 'timezone' })
export class TimezonePipe implements PipeTransform {
  transform(value: any, timezone: string, format: string): string {
    if (!value) {
      return '';
    }
    return moment.tz(value, timezone).format(format);
  }
}
