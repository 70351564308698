import { AfterViewChecked, ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { Event, EventRecurringType } from "../../shared/models/event";
import { EventService } from "../../shared/services/event.service";
import { PlayerService } from "../../shared/services/player.service";
import { PlaySpace } from "../../shared/models/playSpace";
import { PlayerEventInfoService } from "../../shared/services/playereventinfo.service";
import { PlayerEventInfo, PlayerEventInfoStatus, TeamMemberType } from "../../shared/models/playerEventInfo";
import { Player } from "app/shared/models/player";
import { Router } from "@angular/router";
import { PlaySpaceService } from "../../shared/services/playspace.service";
import { combineLatest } from "rxjs";
import { map } from "rxjs/operators";
import { NgxSpinnerService } from "ngx-spinner";
import { BsModalService, BsModalRef } from "ngx-bootstrap/modal";
import { ModalContentComponent } from "../../shared/modal/modal-content.component";

@Component({
  selector: "event-list",
  templateUrl: "./event-list.component.html",
  styleUrls: ["../event.component.css"],
  providers: [],
})
export class EventListComponent implements OnInit, AfterViewChecked {
  @Output() invokeHelpTab = new EventEmitter<any>();

  public events: Event[];

  public allEvents: Event[];
  public myEvents: Event[];
  public sosEvents: Event[];

  public creatingNewEvent: boolean = false;

  public isMyMatchesClicked: boolean = true;
  public isAllMatchesClicked: boolean = false;
  public isSosMatchesClicked: boolean = false;

  public selectedEvent: Event;
  public editSelectedEvent: boolean = false;

  public isCurrentPlayerManager: boolean;

  public currentPlayer: Player;
  public teamMemberType: TeamMemberType = new TeamMemberType();

  public playerEventInfoStatus: PlayerEventInfoStatus = new PlayerEventInfoStatus();

  public loading = false;

  public showInviteDialog: boolean = false;
  public showLeaveEventDialog: boolean = false;

  public sendInviteEmailEvent: Event;
  public eventForLeave: Event;

  public currentPlayerCity: string;

  public joinMessage: string;
  public addressMissing: boolean = false;
  bsModalRef: BsModalRef;

  public sosEventsCount: number;
  public myEventsCount: number;
  public rating: number = 4;
  //public sosEventsCount: number;


  constructor(
    public playerService: PlayerService,
    public eventService: EventService,
    private cdRef: ChangeDetectorRef,
    private playereventinfoService: PlayerEventInfoService,
    private playSpaceService: PlaySpaceService,
    public router: Router,
    private spinner: NgxSpinnerService,
    private modalService: BsModalService
  ) {}

  ngOnInit() {
    this.spinner.show();
    this.currentPlayer = this.playerService.getCurrentPlayer();
    this.playerService.checkCity();

    let filter = localStorage.getItem("eventsFilter");
    if (filter !== null) {
      if (filter === "allMatches") {
        this.isMyMatchesClicked = false;
        this.isAllMatchesClicked = true;
        this.isSosMatchesClicked = false;
      }
      if (filter === "myMatches") {
        this.isMyMatchesClicked = true;
        this.isAllMatchesClicked = false;
        this.isSosMatchesClicked = false;
      }
      if (filter === "sosMatches") {
        this.isMyMatchesClicked = false;
        this.isAllMatchesClicked = false;
        this.isSosMatchesClicked = true;
      }
    }

    //if(this.currentPlayer.city != null){
      this.getEventList();
      this.getCurrentCityAndLocations();  
    //}else{
      //this.spinner.hide();
      //this.loading = false;
      //this.addressMissing = true;
    //}
  }

  ngAfterViewChecked() {
    this.cdRef.detectChanges();
  }

  public requestToJoin(event: Event): void {
    this.loading = true;
    this.spinner.show();
    this.eventService.requestToJoinEvent(event, this.currentPlayer).subscribe(
      () => {
        const initialState = { title: "Alert", text: "A request has been sent to the match manager and will be considered. Keep an eye on your inbox for new info!" };
        this.bsModalRef = this.modalService.show(ModalContentComponent, { initialState });
        this.bsModalRef.content.closeBtnName = "Close";
        this.getEventList(true, true);

        this.isAllMatchesClicked = false;
        this.isSosMatchesClicked = false;
        this.isMyMatchesClicked = true;
        this.spinner.hide();
        this.loading = false;
      },
      (res) => {
        console.log(res.error);
        this.spinner.hide();
        this.loading = false;
      }
    );
  }

  public sendInviteEmail(inviteToNogoshEmailList: string): void {
    this.loading = true;
    this.spinner.show();
    this.eventService.invitePlayersToJoinNogosh(this.sendInviteEmailEvent, inviteToNogoshEmailList).subscribe(
      () => {
        const initialState = {
          title: "Alert",
          text: "An email with instructions has been sent to the list you provided! Once they create an account they can request to join your match.",
        };
        this.bsModalRef = this.modalService.show(ModalContentComponent, { initialState });
        this.bsModalRef.content.closeBtnName = "Close";

        this.spinner.hide();
        this.loading = false;
      },
      (res) => {
        console.log(res.error);
        this.spinner.hide();
        this.loading = false;
      }
    );

    this.showInviteDialog = false;
  }

  public dialogSendInviteEmail(event: Event): void {
    this.showInviteDialog = true;
    this.sendInviteEmailEvent = event;
  }

  public createNewEvent(): void {
    // TODO - move all this shit to event detail

    this.creatingNewEvent = true;

    this.selectedEvent = new Event();
    this.selectedEvent.viewedByManager = true;

    this.selectedEvent.nextOccurrenceTimestamp = new Date();
    this.selectedEvent.season1StartDate = new Date(this.selectedEvent.nextOccurrenceTimestamp.getFullYear(), 3, 1);
    this.selectedEvent.season2StartDate = new Date(this.selectedEvent.nextOccurrenceTimestamp.getFullYear(), 9, 1);

    this.selectedEvent.manager = this.currentPlayer;
    this.selectedEvent.active = true;
    this.selectedEvent.playSpace = new PlaySpace();
    this.selectedEvent.playerEventInfos = Array<PlayerEventInfo>();
    this.selectedEvent.confirmedCount = 0;
    this.selectedEvent.recurringType = new EventRecurringType().WEEKLY;

    localStorage.setItem("event", JSON.stringify(this.selectedEvent));

    this.editSelectedEvent = true;
    this.router.navigate(["/event-detail", this.editSelectedEvent]);
  }

  public viewEvent(event: Event): void {
    localStorage.setItem("event", JSON.stringify(event));

    this.selectedEvent = event;
    this.editSelectedEvent = false;

    this.router.navigate(["/event-detail", this.editSelectedEvent]);
  }

  public eventPlayers(event: Event): void {
    localStorage.setItem("event", JSON.stringify(event));

    this.selectedEvent = event;
    this.router.navigate(["/event-players", false]);
  }
  public eventDetail(event: Event): void {
    localStorage.setItem("event", JSON.stringify(event));

    this.selectedEvent = event;
    this.router.navigate(["/event-detail", false]);
  }

  private getCurrentCityAndLocations(): void {
    this.playSpaceService.getCurrentLocations().subscribe((playspaces) => {
      localStorage.setItem("nearByLocations", JSON.stringify(playspaces));
      this.currentPlayerCity = this.currentPlayer.city.slice(0, this.currentPlayer.city.indexOf(","));
    });
  }

  private getEventList(isLoading: boolean = true, isJoinOrLeave: boolean = false): void {
    let me = this;

    if (isLoading) {
      me.loading = true;
      me.spinner.show();
    }

    me.eventService.getAllActiveEventsNearBy(this.currentPlayer.location.coordinates[0], this.currentPlayer.location.coordinates[1], 50).subscribe(
      (events) => {
        if (events) {
          me.checkIfPlayerIsManager(events);
          //events = me.sortEvents(new Set<Event>(events));

          let tmpEventList = new Set<Event>();

          let allEvents = [];

          let currentEventList = Array.from(events);
          for (let event of currentEventList) {
            tmpEventList.add(event);

            event.originalEventId = event.originalEventId ? event.originalEventId : event.id;

            allEvents.push(
              me.playereventinfoService.fetchForEvent(event.id).pipe(
                map(
                  (playerEventInfoList) => {
                    event.playerEventInfos = playerEventInfoList;

                    if (event.playerEventInfos) {
                      for (let playerEventInfo of event.playerEventInfos) {
                        if (playerEventInfo.teamMemberType === this.teamMemberType.CANDIDATE) {
                          event.newCandidatesFound = true;

                          if (playerEventInfo.player.id === me.currentPlayer.id) {
                            event.viewedByCandidate = true;
                          }
                        }

                        if (playerEventInfo.player.id === me.currentPlayer.id) {
                          event.currentPlayerInviteStatus = playerEventInfo.status;
                          if (playerEventInfo.teamMemberType !== this.teamMemberType.CANDIDATE) {
                            event.viewedByPlayer = true;
                          }
                          if (!event.viewedByManager) {
                            tmpEventList.add(event);
                          }
                        }
                      }

                      //events = me.sortEvents(tmpEventList);
                    }

                    event.confirmedCount = me.eventService.getConfirmedCount(event);
                  },
                  (error: any) => {
                    console.log(error);
                  }
                )
              )
            );

            let allEventsLength = allEvents.length;
            combineLatest(allEvents).subscribe((event) => {
              if (event.length === allEventsLength) {
                if (events.length === 1) {
                  me.selectedEvent = events[0];
                  me.editSelectedEvent = true;

                  if (me.selectedEvent.manager.id === me.currentPlayer.id) {
                    me.selectedEvent.viewedByManager = true;
                    me.editSelectedEvent = false;
                  }
                }

                me.allEvents = JSON.parse(JSON.stringify(events));
                me.myEvents = events.filter((event) => (event.viewedByManager || event.viewedByPlayer) && event.active);
                me.sosEvents = events.filter((event) => event.sosUsed === true);

                if (this.isSosMatchesClicked && me.sosEvents && me.sosEvents.length > 0 && !isJoinOrLeave) {
                  me.events = JSON.parse(JSON.stringify(me.sosEvents));
                  me.isMyMatchesClicked = false;
                  me.isAllMatchesClicked = false;
                  me.isSosMatchesClicked = true;
                } else {
                  if (this.isAllMatchesClicked && me.allEvents) {
                    me.events = JSON.parse(JSON.stringify(me.allEvents));
                    me.isMyMatchesClicked = false;
                    me.isAllMatchesClicked = true;
                    me.isSosMatchesClicked = false;
                  } else {
                    me.events = JSON.parse(JSON.stringify(me.myEvents));
                    me.isMyMatchesClicked = true;
                    me.isAllMatchesClicked = false;
                    me.isSosMatchesClicked = false;
                  }
                }
                this.spinner.hide();
                me.loading = false;
              }
            });
          }
        }
        this.spinner.hide();
        me.loading = false;
        me.selectedEvent = null;
      },
      (error) => {
        if (this.currentPlayer.city === null || this.currentPlayer.address === null) {
          this.addressMissing = true;
        }
        this.spinner.hide();
        me.loading = false;
      }
    );
  }

  private sortEvents(events: Set<Event>): Event[] {
    let managerEventList: Event[] = Array<Event>();
    let playerEventList: Event[] = Array<Event>();
    let otherEventList: Event[] = Array<Event>();

    events.forEach(function (event) {
      if (event.viewedByManager) {
        managerEventList.push(event);
      } else if (event.viewedByPlayer) {
        playerEventList.push(event);
      } else {
        otherEventList.push(event);
      }
    });

    let eventList = managerEventList.concat(playerEventList);
    eventList = eventList.concat(otherEventList);

    return eventList;
  }

  private checkIfPlayerIsManager(events: Event[]): void {
    this.isCurrentPlayerManager = false;

    if (events) {
      for (let event of events) {
        event.viewedByManager = false;

        if (event.manager.id === this.currentPlayer.id) {
          this.isCurrentPlayerManager = true;
          event.viewedByManager = true;
        }
      }
    }
  }

  public filterEvents(value: string): void {
    let me: any = this;
    if (value === "allMatches") {
      me.isMyMatchesClicked = false;
      me.isSosMatchesClicked = false;
      me.isAllMatchesClicked = true;
      localStorage.setItem("eventsFilter", "allMatches");
      me.events = me.allEvents;
    } else if (value === "myMatches") {
      me.isAllMatchesClicked = false;
      me.isSosMatchesClicked = false;
      me.isMyMatchesClicked = true;
      localStorage.setItem("eventsFilter", "myMatches");
      me.events = me.myEvents;
    } else {
      me.isMyMatchesClicked = false;
      me.isAllMatchesClicked = false;
      me.isSosMatchesClicked = true;
      localStorage.setItem("eventsFilter", "sosMatches");
      me.events = me.sosEvents;
    }
  }
}
