<!-- <ngx-loading [show]="loading" [config]="{ backdropBorderRadius: '3px' }"></ngx-loading> -->
<ngx-spinner
              bdColor="rgba(51,51,51,0.8)"
              size="medium"
              color="#fff"
              type="ball-scale-multiple"
              ><p style="font-size: 20px; color: white">Loading...</p>
              </ngx-spinner>

<div *ngIf="!loading" class="container" role="main">

   

  <app-player-details-popup #playerDetailPopup></app-player-details-popup>

  <header class="row my-3 my-md-4 mb-md-5 align-items-center">
    <!-- <a href="javascript:" class="btn-back col-auto border-right align-self-stretch" (click)="goBack()"><i class="fas fa-arrow-left"><span class="sr-only">Back</span></i></a> -->
    <div class="col">      
      <h2 class="m-0">Players</h2> 
    </div>
    <div class="col-12 col-sm-auto mt-3 mt-md-0">
      <div class="player-list-filter-links">
          <ul class="nav flex-column flex-sm-row nav-pills nav-separated justify-content-center text-center">
              <li class="nav-item"><a href="javascript:" class="nav-link" [ngClass]="{'picked-color  active': isWithoutTeamsClicked }" (click)="filterPlayers('withoutTeams')">With no teams</a></li>
              <li class="nav-item"><a href="javascript:" class="nav-link" [ngClass]="{'picked-color  active': isReservesOnlyClicked }" (click)="filterPlayers('reservesOnly')">Reserves only</a></li>
              <li class="nav-item"><a href="javascript:" class="nav-link" [ngClass]="{'picked-color  active': isAllPlayersClicked }" (click)="filterPlayers('allPlayers')">All players</a></li>
          </ul>
      </div>
    </div><!--  /.col-auto  -->
    <div class="header-divider"></div>
  </header>

  <app-dialog [(visible)]="showInviteDialog">
    <div class="modal-header">
      <h5 class="mb-0">Invite {{playerForInviteDialog?.name}} to join:</h5>
    </div>
    
    <div class="modal-body">
      <div *ngFor="let event of myEvents; let i = index" class="row ">
        <div class="col-12" *ngIf="i>0"><div class="border-top my-1"></div></div>
        <div class="col">
          <strong>{{event.name}}</strong>
        </div>
        <div class="col-auto">
          <button type="button" class="btn btn-warning btn-sm px-3"
                (click)="sendInviteForEvent(playerForInviteDialog, event)">Invite</button>
        </div>
        
      </div>
    </div>
  </app-dialog>

  <div class="mb-5 ">
    <div *ngFor="let player of players; index as i; even as isEven; odd as isOdd" class="border-bottom border-bottom-not-last animated fadeIn">
      <div class="row align-items-center py-2 row-{{i}} flex-nowrap" [ngClass]="{'odd': isOdd, 'even': isEven}">
        <div class="col-auto pr-0">
            <div class="avatar-team rounded-circle border" title="{{player.nickName}}" [style.backgroundImage]="'url(' + (player.avatar ? player.avatar : '../../assets/images/user1.jpg') + ')'">
              <span class="sr-only">{{player.nickName}}'s profile image</span>
            </div>
        </div>
        
        <div class="col player-list minw-0 pr-0"  title="{{player.nickName}}">
          <div class="overflow-ellipsis line-height-10"><strong class="d-inline">{{player.nickName}}</strong></div>
          
        </div>

          <!-- TMP disabled - GDPR -->
          <div *ngIf="false"  class="col-auto pr-0" [popover]="playerDetailPopup.templateRef"
              (click)="playerDetailPopup.getPlayerInfo(player.email)" popoverTitle="User details" placement="bottom"
              [outsideClick]="true">
            <button class="btn btn-sm btn-success px-3">Details</button>
          </div>

          <div class="col-auto pr-0">
            <button (click)="showInviteDialog = true; playerForInviteDialog = player" class="btn btn-sm btn-warning px-3">Invite</button>
          </div>

          <div class="col-auto">
            <button (click)="enterOrCreateNewChat(player);" class="btn btn-sm btn-primary px-3 font-weight-700">Chat</button>
          </div>


      </div><!-- /.row  -->
    </div>
  </div>
</div>
