import {Observable} from 'rxjs';
import {Injectable} from "@angular/core";
import {environment} from "../../environments/environment";
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Message} from "../models/message";

@Injectable()
export class MessageService {

  public apiUrl: string = environment.API_BASE_URL + '/message';

  constructor(private authHttp: HttpClient) {
  }

  public static getRequestOptions() {
    return {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    };
  }

  public fetchAdditionalMessagesForChat(chatId: string, page: number, resultsPerPage: number): Observable<Message[]> {
    if (!resultsPerPage) {
      resultsPerPage = resultsPerPage;
    }
    if (!page) {
      page = 0;
    }


    return this.authHttp.get<Message[]>(
      `${this.apiUrl}/page/?chatId=${chatId}&pageNumber=${page}&resultsPerPage=${resultsPerPage}`,
      MessageService.getRequestOptions());
  }
}
