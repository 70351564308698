import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from "@angular/core";
import { CommonModule } from "@angular/common";
import { FormsModule } from "@angular/forms";
import { PlaySpaceFormComponent } from "./play-space.component";
import { PlaySpaceService } from "../shared/services/playspace.service";
import { FreeGeoIpLocationService } from "../shared/services/freegeoip.location.service";
import { IpInfoLocationService } from "../shared/services/ipinfo.location.service";
import { TypeaheadModule } from "ngx-bootstrap/typeahead";
import { NgxSpinnerModule } from "ngx-spinner";
import { GoogleMapsModule } from '@angular/google-maps';

@NgModule({
  imports: [CommonModule, FormsModule, TypeaheadModule.forRoot(), NgxSpinnerModule, GoogleMapsModule],
  exports: [PlaySpaceFormComponent],
  declarations: [PlaySpaceFormComponent],
  providers: [PlaySpaceService, FreeGeoIpLocationService, IpInfoLocationService],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class PlaySpaceModule {}
