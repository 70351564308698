<footer class="text-center mt-3 pt-2 text-white d-flex flex-column text-secondary"> 
  <p class="mx-auto mb-0 small cursor-default">Created by</p>
  <p class="mx-auto text-center">
    <a href="https://www.mangonel.io/" target="_blank" class="no-underline d-inline-flex align-items-center justify-content-center text-secondary">
      <img src="../../../assets/imgs/Profile_FeedbackV1/mangonel_favicon.png">
      <span class="ml-2">Mangonel</span>
    </a>
  </p>
  <p class="mx-auto text-center text-sm">
    <!-- this is set during the build using nogosh-deployment repo->build-and-deploy.sh and a linux "sed" command-->
    Web deploy: 738cc4f793c0d72c072bf11d874d6cc9f1fdaddc @ 2023-10-08-17-46-34
    <br/>
    Api deploy: {{apiVersion.apiCommitId}} @ {{apiVersion.apiDeploymentDate}}
  </p>
    
</footer>
