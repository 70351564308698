import { Component } from '@angular/core';
import { AuthService } from '@auth0/auth0-angular';

@Component({
  selector: 'app-loading-progress',
  templateUrl: './loading-progress.component.html'
})
export class LoadingProgressComponent {

  constructor(public auth: AuthService) { }

}
