import {NgModule, CUSTOM_ELEMENTS_SCHEMA} from '@angular/core';
import {CommonModule} from '@angular/common';
import {StatsDetailComponent} from './stats-detail.component';
import {StatsService} from '../shared/services/stats.service';
import {PopoverModule} from 'ngx-bootstrap/popover';
//import {NgxLoadingModule} from 'ngx-loading';
import { NgxSpinnerModule } from "ngx-spinner";


@NgModule({
  imports: [
    CommonModule, 
    PopoverModule, 
//    NgxLoadingModule.forRoot({}), 
    NgxSpinnerModule
  ],
  exports: [StatsDetailComponent],
  declarations: [StatsDetailComponent],
  providers: [StatsService],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class StatsModule {
}
