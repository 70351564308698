import { HttpClient, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { AuthService } from "@auth0/auth0-angular";
import { Observable } from "rxjs";

@Injectable()
class Auth0InterceptorApi implements HttpInterceptor {
  private token: String = null;

  constructor(public auth: AuthService) {
    auth.idTokenClaims$.subscribe((it) => (this.token = it.__raw));
  }
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (!this.token) {
      return;
    }
    req = req.clone({
      headers: req.headers.append("Authorization", `Bearer ${this.token}`),
    });

    return next.handle(req);
  }
}

export default Auth0InterceptorApi;
