<div class="container" role="main" *ngIf="(auth.isAuthenticated$ | async)===false">
    <div class="row my-4 my-md-5">
        <div class="col col-md-10 mx-md-auto text-center">
            <!-- <h2 class="text-center mb-4">Welcome to FOOTBALLER.ZONE</h2> -->
            <img width="100%" src="../assets/images/footballer-zone-logo-transparent.png"/>
            <br>
            <br>
            <button type="button" class="center btn btn-primary btn-sm px-3" (click)="login()">Log in</button>
            <br>
            <br>
            <a href="https://footballer.zone">Learn More</a>
            <br>
            <br>
            <a href='https://play.google.com/store/apps/details?id=com.mangonel.nogosh&hl=en&gl=US&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1'>
                <img width="50%" alt='Get it on Google Play' src='https://play.google.com/intl/en_us/badges/static/images/badges/en_badge_web_generic.png'/>
            </a>
            <br>
            <br>
            <a href="https://apps.apple.com/us/app/footballer-zone/id1312823941?itsct=apps_box_badge&amp;itscg=30200" style="display: inline-block; overflow: hidden; border-top-left-radius: 13px; border-top-right-radius: 13px; border-bottom-right-radius: 13px; border-bottom-left-radius: 13px; width: 80%;">
                <img src="https://tools.applemediaservices.com/api/badges/download-on-the-app-store/black/en-us?size=250x83&amp;releaseDate=1621296000&h=e9b867f5b7f458fd7c86e8a146ae7493" alt="Download on the App Store" style="border-top-left-radius: 13px; border-top-right-radius: 13px; border-bottom-right-radius: 13px; border-bottom-left-radius: 13px; width: 55%;">
            </a>            
        </div>
    </div>
</div>

<div *ngIf="!loading && auth.isAuthenticated$ | async">
    <app-nav-bar></app-nav-bar>
    <router-outlet></router-outlet>
    <app-footer *ngIf="showFooter()"></app-footer>
</div>