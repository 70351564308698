import { Observable } from "rxjs";
import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { environment } from "../../environments/environment";
//import { controlNameBinding } from '@angular/forms/src/directives/reactive_directives/form_control_name';

@Injectable()
export class LogService {
  level: number = LogLevel.Off;
  logWithDate: boolean = true;

  private apiUrl: string = environment.API_BASE_URL + "/player";

  constructor(private authHttp: HttpClient) {
    this.getLogLevel().subscribe(
      (level) => {
        this.level = level;
      },
      (error) => {
        console.log(error);
      }
    );
  }

  public logClientActivity(body: Object) {
    this.authHttp.post(`${this.apiUrl}/logClientActivity`, body, this.getRequestOptions()).subscribe();
  }

  public getLogLevel(): Observable<number> {
    return this.authHttp.get<number>(`${this.apiUrl}/getLogLevel`, this.getRequestOptions());
  }

  debug(msg: string, ...optionalParams: any[]) {
    this.writeToLog(msg, LogLevel.Debug, optionalParams);
  }

  info(msg: string, ...optionalParams: any[]) {
    this.writeToLog(msg, LogLevel.Info, optionalParams);
  }

  warn(msg: string, ...optionalParams: any[]) {
    this.writeToLog(msg, LogLevel.Warn, optionalParams);
  }

  error(msg: string, ...optionalParams: any[]) {
    this.writeToLog(msg, LogLevel.Error, optionalParams);
  }

  fatal(msg: string, ...optionalParams: any[]) {
    this.writeToLog(msg, LogLevel.Fatal, optionalParams);
  }

  log(msg: string, ...optionalParams: any[]) {
    this.writeToLog(msg, LogLevel.All, optionalParams);
  }

  private getRequestOptions() {
    return {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
      }),
    };
  }

  private writeToLog(msg: string, level: LogLevel, params: any[]) {
    if (this.shouldLog(level)) {
      let player: string = localStorage.getItem("player");

      let value = player ? JSON.parse(player).email : "";
      value += " => ";
      if (this.logWithDate) {
        value += new Date() + " - ";
      }

      value += "Type: " + LogLevel[level];
      value += " - Message: " + msg;
      if (params.length) {
        value += " - Extra Info: " + this.formatParams(params);
      }

      try {
        console.log(value);
        this.logClientActivity(value);
      } catch (error) {
        console.log(error);
        if (error.message !== "No JWT present or has expired") {
          alert("Please report this error to the vedran.stanic@mangonel.hr: " + error.stack);
        }
      }
    }
  }

  private shouldLog(level: LogLevel): boolean {
    let ret: boolean = false;

    if ((level >= this.level && level !== LogLevel.Off) || this.level === LogLevel.All) {
      ret = true;
    }

    return ret;
  }

  private formatParams(params: any[]): string {
    let ret: string = params.join(",");

    // Is there at least one object in the array?
    if (params.some((p) => typeof p == "object")) {
      ret = "";
      for (let item of params) {
        ret += JSON.stringify(item) + ",";
      }
    }

    return ret;
  }
}

export enum LogLevel {
  All = 0,
  Debug = 1,
  Info = 2,
  Warn = 3,
  Error = 4,
  Fatal = 5,
  Off = 6,
}
