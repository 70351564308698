import { Component, Input, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { PlayerService } from '../../shared/services/player.service';
import { Player } from '../../shared/models/player';
import { PopoverDirective } from 'ngx-bootstrap/popover';

@Component({
  selector: 'app-player-details-popup',
  templateUrl: './player-details-popup.component.html'
})
export class PlayerDetailsPopupComponent implements OnInit {

  @Input()
  public newEmail: string;

  @ViewChild('playerDetailsPopupTemplateRef')
  public templateRef: TemplateRef<PopoverDirective>;

  public playerDetails: Player = new Player();
  public currentUser: Player;

  constructor(private playerService: PlayerService) { }

  ngOnInit() {
    this.currentUser = this.playerService.getCurrentPlayer();
  }

  public getPlayerInfo(playerEmail: string): void {
    this.playerService.getPlayer(playerEmail).subscribe(player => {
      this.playerDetails = player;

      if (!this.playerDetails.avatar || !this.playerDetails.avatar.startsWith("http")) {
        this.playerDetails.avatar = PlayerService.DEFAULT_AVATAR_URL;
      }
    },
      error => {
        console.log(error);
      });
  }

  public updateUserEmail(): void {
    this.playerService.updatePlayerEmail(this.playerDetails.email, this.newEmail).subscribe(
      player => {
        console.log("New player email set to: " + player.email);
      },
      error => {
        console.log(error);
      });
  }
}
