import { Component, Input, OnInit, NgZone, ViewChild, ViewChildren, QueryList } from "@angular/core";
import { PlaySpace } from "../shared/models/playSpace";
import { PlaySpaceService } from "../shared/services/playspace.service";
import { PlayerService } from "../shared/services/player.service";
import { City } from "../shared/models/city";
import { Country } from "../shared/models/country";
import { Player } from "app/shared/models/player";
import { ActivatedRoute, Router } from "@angular/router";
import { Location } from "@angular/common";
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { mergeMap } from "rxjs/internal/operators/mergeMap";
import { NgxSpinnerService } from "ngx-spinner";
import { HttpClient } from '@angular/common/http';
import { environment } from "app/environments/environment";
import { MapInfoWindow, MapMarker } from '@angular/google-maps';

@Component({
  selector: "play-space",
  templateUrl: "./play-space.component.html",
  styleUrls: ["./play-space.component.css"],
})
export class PlaySpaceFormComponent implements OnInit {
  @Input()
  public playSpace: PlaySpace;

  public editMode: boolean = false;
  public currentLocationLatitude: number = 44.78;
  public currentLocationLongitude: number = 20.44;

  public zoom: number = 11;

  public markers: PlaySpace[] = Array<PlaySpace>();
  public loading = false;
  public currentPlayer: Player;
  public cityDataSource: Observable<any>;
  public addressDataSource: Observable<any>;

  private creatingNewPlayspaceFromEvent: boolean = false;
  public mapClickListener: any;
  public map: any;

  apiLoaded: Observable<boolean>;
  mapOptions: google.maps.MapOptions;
  markerOptions: google.maps.MarkerOptions = {draggable: true};

  @ViewChildren(MapInfoWindow) infoWindows: QueryList<MapInfoWindow>;

  constructor(
    public playSpaceService: PlaySpaceService,
    public route: ActivatedRoute,
    public playerService: PlayerService,
    public router: Router,
    private _location: Location,
    private spinner: NgxSpinnerService,
    private zone: NgZone,
    private httpClient: HttpClient
  ) {
    // this.apiLoaded = httpClient.jsonp('https://maps.googleapis.com/maps/api/js?key=' + environment.apiKey, 'callback')
    // .pipe(
    //   map(() => true),
    //   catchError(() => of(false)),
    // );
  }

  ngOnInit() {
    this.loading = true;
    this.spinner.show();
    this.currentPlayer = this.playerService.getCurrentPlayer();
    this.playerService.checkCity();

    this.creatingNewPlayspaceFromEvent = JSON.parse(this.route.snapshot.params["createNewPlayspace"]);

    this.cityDataSource = new Observable((observer: any) => {
      observer.next(this.playSpace.city.name);
      this.spinner.hide();
      this.loading = false;
    }).pipe(mergeMap((token: string) => this.getCitiesAsObservable(token)));

    this.addressDataSource = new Observable((observer: any) => {
      observer.next(this.playSpace.address);
      this.spinner.hide();
      this.loading = false;
    }).pipe(mergeMap((address: string) => this.getAddressAsObservable(address)));

    this.playSpaceService.getCurrentLocations().subscribe((playspaces) => {
      for (let playspace of playspaces) {
        if (playspace.manager.id === this.currentPlayer.id) {
          playspace.viewedByManager = true;
        }
        
        playspace.googleLatLng = new google.maps.LatLng(playspace.latitude, playspace.longitude);
        this.markers.push(playspace);
      }

      this.currentLocationLatitude = this.currentPlayer.location.coordinates[0];
      this.currentLocationLongitude = this.currentPlayer.location.coordinates[1];

      if (this.creatingNewPlayspaceFromEvent) {
        this.editCreatePlayspace(null);
      }

      this.mapOptions = {
        center: {lat: this.currentLocationLatitude, lng: this.currentLocationLongitude},
        zoom: this.zoom
      };

      this.spinner.hide();
      this.loading = false;
    });
  }

  openInfoWindow(marker: MapMarker, markerNo: number) {
    this.infoWindows.get(markerNo).open(marker);
  }

  public getAddressAsObservable(partialAddres: string) {
    return this.playerService.getAddress(partialAddres, this.playSpace.latitude, this.playSpace.longitude);
  }

  public getCitiesAsObservable(partialCityName: string) {
    return this.playerService.getCities(partialCityName);
  }

  public typeaheadCitySelected(selectedCity: any) {
    this.playSpace.city.name = selectedCity.value;
    this.playerService.getLatAndLng(this.playSpace.city.name).subscribe((latLng) => {
      this.playSpace.latitude = latLng.lat;
      this.playSpace.longitude = latLng.lng;
      this.playSpace.googleLatLng = new google.maps.LatLng(this.playSpace.latitude, this.playSpace.longitude);
    });
  }

  public typeaheadAddressSelected(selectedAddress: any) {
    this.playSpace.address = selectedAddress.value;
    this.playerService.getLatAndLng(this.playSpace.city.name + selectedAddress).subscribe((latLng) => {
      this.playSpace.latitude = latLng.lat;
      this.playSpace.longitude = latLng.lng;
      this.playSpace.googleLatLng = new google.maps.LatLng(this.playSpace.latitude, this.playSpace.longitude);
    });
  }

  public editCreatePlayspace(playSpace: PlaySpace): void {
    this.editMode = true;

    if (playSpace !== null && playSpace.manager.id === this.currentPlayer.id) {
      playSpace.viewedByManager = true;
    }

    if (playSpace) {
      this.playSpace = playSpace;
      this.playSpace.googleLatLng = new google.maps.LatLng(this.playSpace.latitude, this.playSpace.longitude);
    } else {
      //let playerLocationInfo = this.currentPlayer.locationInfo;

      this.playSpace = new PlaySpace();
      this.playSpace.latitude = this.currentPlayer.location.coordinates[0];
      this.playSpace.longitude = this.currentPlayer.location.coordinates[1];
      this.playSpace.googleLatLng = new google.maps.LatLng(this.playSpace.latitude, this.playSpace.longitude);
      this.playSpace.city = new City();
      this.playSpace.city.name = this.currentPlayer.city;
      this.playSpace.city.country = new Country();
      //this.playSpace.city.country.code = playerLocationInfo.countryCode;
      //this.playSpace.city.country.name = playerLocationInfo.countryName;
      this.playSpace.manager = this.currentPlayer;
      this.playSpace.viewedByManager = true;
    }
  }

  public mapUpdatePlayLocationInfo(m: PlaySpace, $event: any): void {
    console.log($event)
    if (this.editMode && this.playSpace) {
      this.playSpace.latitude = $event.latLng.lat().toFixed(5);
      this.playSpace.longitude = $event.latLng.lng().toFixed(5);
      this.playSpace.googleLatLng = new google.maps.LatLng(this.playSpace.latitude, this.playSpace.longitude);
    }
  }

  // public mapReadyHandler(map: google.maps.Map): void {
  //   this.map = map;
  //   this.mapClickListener = this.map.addListener("click", (e: google.maps.MouseEvent) => {
  //     this.zone.run(() => {
  //       if (this.editMode && this.playSpace) {
  //         this.playSpace.latitude = Number(e.latLng.lat().toFixed(5));
  //         this.playSpace.longitude = Number(e.latLng.lng().toFixed(5));
  //       }
  //     });
  //   });
  // }

  public onSubmit(): void {
    this.playSpace.location = null;
    this.spinner.show();
    this.loading = true;

    if (this.playSpace.id) {
      this.playSpaceService.updatePlaySpace(this.playSpace).subscribe(
        (playSpace) => {
          this.playSpace = playSpace;
          this.playSpace.viewedByManager = true;

          this.editMode = false;
          this.spinner.hide();
          this.loading = false;
        },
        (error) => {
          console.log(error);
          this.spinner.hide();
          this.loading = false;
        }
      );
    } else {
      this.playSpaceService.createPlaySpace(this.playSpace).subscribe(
        (playSpace) => {
          this.playSpace = playSpace;
          this.playSpace.viewedByManager = true;

          this.playSpace.googleLatLng = new google.maps.LatLng(this.playSpace.latitude, this.playSpace.longitude);
          this.markers.push(this.playSpace);
          this.spinner.hide();
          this.loading = false;
          this.editMode = false;

          if (this.creatingNewPlayspaceFromEvent) {
            sessionStorage.setItem("returningFromNewPlayspaceForm", "true");
            sessionStorage.setItem("newPlayspace", JSON.stringify(this.playSpace));
            this.router.navigate(["/event-detail", true]);
          }
        },
        (error) => {
          console.log(error);
          this.spinner.hide();
          this.loading = false;
        }
      );
    }
  }

  public deletePlayspace(): void {
    this.playSpaceService.removePlaySpace(this.playSpace.id).subscribe(
      (response) => {
        //this.goBack();
        this.editMode = false;
        this.markers = this.markers.filter(item => item.id !== this.playSpace.id);
      },
        (error) => {
          console.log(error);
          alert(error.error);
          
        }
      );
  }

  public cancelPlaySpaceEdit(): void {
    this.editMode = false;

    if (this.creatingNewPlayspaceFromEvent) {
      sessionStorage.setItem("returningFromNewPlayspaceForm", "true");
      sessionStorage.removeItem("newPlayspace");

      this.router.navigate(["/event-detail", true]);
    }
  }

  public goBack(): void {
    //console.log('uh');
    this._location.back();
  }
}
