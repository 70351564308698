import { Player } from './player';
import { SentStatus } from '../enum/sent-status';
import { SafeHtml } from '@angular/platform-browser';

export class Message {
    id?: string;
    chatId: string;
    text: string = '';
    sender: Player;
    readonly created: number;
    createdDateString: string;
    likesCount: number;
    dislikesCount: number;
    votedByPlayers: string[];
    inappropriate: boolean;
    deleted: boolean;
    marker: string;
    sent: SentStatus;
    html: SafeHtml;
    messages: Message[];

    constructor() {
        this.created = Date.now();
        this.createdDateString = "";
        this.likesCount = 0;
        this.dislikesCount = 0;
        this.votedByPlayers = Array<string>();
        this.inappropriate = false;
        this.deleted = false;
        this.marker = "";
        this.sent = SentStatus.NOT_SENT;
        this.messages = Array<Message>();
    }

    toString(): string {
        const { created, text } = this;
        return `Message created at: ${created} - Text: ${text}`;
    }
}
