import { Observable } from "rxjs";
import { Injectable } from "@angular/core";
import { environment } from "../../environments/environment";
import { PlaySpace } from "../models/playSpace";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { DbIpResponse } from "./js/db-ip-response";
import { IpInfoLocationService } from "./ipinfo.location.service";
import { PlayerLocationInfo } from "../models/player";
import { PlayerService } from "./player.service";
import { switchMap } from "rxjs/operators";

@Injectable()
export class PlaySpaceService {
  private apiUrl: string = environment.API_BASE_URL + "/play-space";

  constructor(private authHttp: HttpClient, private ipInfoLocationService: IpInfoLocationService, private playerService: PlayerService) {}

  public getPlaySpaces(partialName: string): Observable<PlaySpace[]> {
    return this.authHttp.get<PlaySpace[]>(`${this.apiUrl}/search/?partialName=${partialName}`, this.getRequestOptions());
  }

  public getDbIpLocationInfo(ipAddress: string): Observable<DbIpResponse> {
    return this.authHttp.get(`${this.apiUrl}/dbip/info/?ipAddress=${ipAddress}`, this.getRequestOptions());
  }

  public getPlaySpacesByCurrentLocation(latitude: number, longitude: number, distanceInKm: number): Observable<PlaySpace[]> {
    return this.authHttp.get<PlaySpace[]>(`${this.apiUrl}/search/geo?latitude=${latitude}&longitude=${longitude}&distanceInKm=${distanceInKm != null ? distanceInKm : 50}`);
  }

  public createPlaySpace(body: Object): Observable<PlaySpace> {
    let bodyString = JSON.stringify(body);
    return this.authHttp.post<PlaySpace>(`${this.apiUrl}`, bodyString, this.getRequestOptions());
  }

  public updatePlaySpace(body: Object): Observable<PlaySpace> {
    let bodyString = JSON.stringify(body);
    return this.authHttp.put<PlaySpace>(`${this.apiUrl}/${body["id"]}`, bodyString, this.getRequestOptions());
  }

  public removePlaySpace(id: string): Observable<PlaySpace> {
    return this.authHttp.delete<PlaySpace>(`${this.apiUrl}/${id}`);
  }

  public getCurrentLocations(): Observable<PlaySpace[]> {
    return this.getPlaySpacesByCurrentLocation(this.playerService.getCurrentPlayer().location.coordinates[0], this.playerService.getCurrentPlayer().location.coordinates[1], 50);
  }

  public getCurrentLocations_old(): Observable<PlaySpace[]> {
    return this.ipInfoLocationService.getCurrentLocation().pipe(
      switchMap((ipInfoLocationResponse) => {
        // first - get location (more accurate than freeGeoIp) and IP from ipInfo.io
        let playerLocationInfo: PlayerLocationInfo = new PlayerLocationInfo();
        playerLocationInfo.ip = ipInfoLocationResponse.ip;
        playerLocationInfo.latitude = parseFloat(ipInfoLocationResponse.loc.split(",")[0]);
        playerLocationInfo.longitude = parseFloat(ipInfoLocationResponse.loc.split(",")[1]);

        // second - get country and city info from db-ip.com
        // going through the backend because of the CORS problems
        return this.getDbIpLocationInfo(playerLocationInfo.ip).pipe(
          switchMap((dbIpLocationResponse) => {
            playerLocationInfo.continentCode = dbIpLocationResponse.continentCode;
            playerLocationInfo.continentName = dbIpLocationResponse.continentName;
            playerLocationInfo.countryName = dbIpLocationResponse.countryName;
            playerLocationInfo.countryCode = dbIpLocationResponse.countryCode;
            playerLocationInfo.region = dbIpLocationResponse.stateProv;
            playerLocationInfo.city = dbIpLocationResponse.city;

            // update current player info
            let player = this.playerService.getCurrentPlayer();
            playerLocationInfo.email = player.email;
            player.locationInfo = playerLocationInfo;
            this.playerService.setCurrentPlayer(player);

            return this.getPlaySpacesByCurrentLocation(playerLocationInfo.latitude, playerLocationInfo.longitude, 50);
          })
        );
      })
    );
  }

  private getRequestOptions() {
    return {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
      }),
    };
  }
}
