import {Pipe, PipeTransform} from '@angular/core';

@Pipe({name: 'sortGroupedBy'})
export class SortGroupedByPipe implements PipeTransform {

    Order : String[] = ["CONFIRMED", "DECLINED", "NOT_ANSWERED", "NOT_NOTIFIED"];

    transform(array : any[], field : string) : any[] {
        array.sort((a : any, b : any) => {
            let indexA = this.Order.indexOf(a[field]);
            let indexB =  this.Order.indexOf(b[field]);
            if (indexA < indexB) {
                return -1;
            } else if (indexA > indexB) {
                return 1;
            } else {
                return 0;
            }
        });
        return array;
    }
}