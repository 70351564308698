<ngx-spinner
              bdColor="rgba(51,51,51,0.8)"
              size="medium"
              color="#fff"
              type="ball-scale-multiple"
              ><p style="font-size: 20px; color: white">Loading...</p>
              </ngx-spinner>

<div *ngIf="!loading" class="container" role="main">

  <header class="row my-3 my-md-4 mb-md-5 align-items-center">
    <a href="javascript:void(0)" class="btn-back col-auto border-right align-self-stretch" (click)="goBack()"><i class="fas fa-arrow-left"><span class="sr-only">Back</span></i></a>
  </header>

  <div class="card-body bg-primary pb-0 px-0 shadow-lg">
    <nav *ngIf="!chatPlayerMode && event.viewedByManager && (playerEventInfo.teamMemberType != teamMemberType.CANDIDATE) && (event.manager.id != playerEventInfo.player.id)" class="navbar sticky sticky-top navbar-main px-sm-0 animated fadeIn navbar-expand">
      <div class="container px-0 px-sm-3">

        <div class="collapse navbar-collapse mx-n3 px-2 shadow-none" id="detailsExtraOptions">
          <ul class="navbar-nav flex-fill mx-n1 mx-sm-0">
            <li class="ml-auto nav-item dropdown d-inline-block" dropdown>
              <a href="javascript:" dropdownToggle class="badge badge-status text-white right mr-2" title="More">
                <i class="fas fa-ellipsis-h fa-2x"><span class="sr-only">More</span></i>
              </a>

              <div *dropdownMenu class="dropdown-menu dropdown-menu-right mt-2 border-top-0" role="menu">
                <a href="javascript:" class="dropdown-item" (click)="setAsManager()">Set as Manager</a>
                <a href="javascript:" class="dropdown-item" (click)="removePlayerFromMatch()">Remove from match</a>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </nav>

    <div class="row pb-3">
      <div class="col d-flex flex-column justify-content-center align-items-center pb-2">
        <img [src]="player.avatar ? player.avatar  : '../../assets/images/user1.jpg'"
             onerror="this.src='../../assets/images/user1.jpg'" class="avatar-team-lg rounded-circle border "
             style="width: 80px; height: 80px"
             alt="user profile image">
      </div>
    </div>
    <div class="col d-flex flex-column justify-content-center align-items-center pb-2 mb-3">
      <h2 *ngIf="event.viewedByManager" class="font-weight-700 text-light-blue text-center mw-100 text-truncate">{{player.name}}</h2>
      <h1 class="text-white text-center mw-100 text-truncate">{{player.nickName}}</h1>
    </div>

    <div *ngIf="player.id !== currentPlayer.id" class="row mx-0 align-items-center bg-light py-4">
      <div class="m-auto d-flex">
        <div class="col-6">
          <a class="btn btn-block mx-3 px-3 btn-primary" [href]="'tel: ' + player.phoneNumber">Call</a>
        </div>
        <div class="col-6">
          <button class="btn btn-block mx-2 px-3 btn-primary" (click)="enterOrCreateNewChat(player)">Chat</button>
        </div>
      </div>
    </div>
  </div>

    <!-- RATING -->
    <div>
      <div class="row team-all mt-4 my-0 px-3 align-items-center">
        <div class="col-auto my-0 mb-1">
          <b class="text-left fr-header">{{currentPlayer.id != player.id ? 'RATE THIS PLAYER' : 'YOUR RATING'}}</b>
        </div>
      </div>
      <div class="card">
        <div class="card-body py-3 pl-3">
          <div *ngIf="currentPlayer.id != player.id" class="players-list border-bottom border-bottom-not-last pb-2">
            <div class="d-flex">
              <div>
                <h6 class="mb-0">Defence</h6>
                <rating [max]="10" [(ngModel)]="ratingDefence" [customTemplate]="tDefence" [readonly]="false" (click)="setDefence()"></rating>
                <ng-template #tDefence let-i="index" let-v="value">
                  <button class="btn btn-sm btn-{{i < v ? 'primary' : 'default'}}">
                    {{i < v ? '&#9733;' : '&#9734;'}}
                  </button>
                </ng-template>
              </div>
            </div>
          </div>
          <div *ngIf="currentPlayer.id != player.id" class="players-list border-bottom border-bottom-not-last py-2">
            <div class="d-flex">
              <div>
                <h6 class="mb-0">Offence</h6>
                <rating [max]="10" [(ngModel)]="ratingOffence" [customTemplate]="tOffence" [readonly]="false" (click)="setOffence()"></rating>
                <ng-template #tOffence let-i="index" let-v="value">
                  <button class="btn btn-sm btn-{{i < v ? 'primary' : 'default'}}">
                    {{i < v ? '&#9733;' : '&#9734;'}}
                  </button>
                </ng-template>              
              </div>
            </div>
          </div>
          <div class="players-list border-bottom border-bottom-not-last py-2">
            <div class="d-flex">
              <div>
                <h6 class="mb-0">How others rated defence</h6>
                <rating [max]="10" [(ngModel)]="ratingAverageDefence" [customTemplate]="tAvgRatingDefence" [readonly]="true"></rating>
                <ng-template #tAvgRatingDefence let-i="index" let-v="value">
                  <button class="btn btn-sm btn-{{i < v ? 'primary' : 'default'}}">
                    {{i < v ? '&#9733;' : '&#9734;'}}
                  </button>
                </ng-template>              
              </div>
            </div>
          </div>
          <div class="players-list border-bottom border-bottom-not-last py-2">
            <div class="d-flex">
              <div>
                <h6 class="mb-0">How others rated offence</h6>
                <rating [max]="10" [(ngModel)]="ratingAverageOffence" [customTemplate]="tAvgRatingOffence" [readonly]="true"></rating>
                <ng-template #tAvgRatingOffence let-i="index" let-v="value">
                  <button class="btn btn-sm btn-{{i < v ? 'primary' : 'default'}}">
                    {{i < v ? '&#9733;' : '&#9734;'}}
                  </button>
                </ng-template>              
              </div>
            </div>
          </div>
        </div><!-- /.card-body -->
      </div>
    </div>
    <!-- END: RATING -->

    <!-- MANAGER EDITS section -->

  <div *ngIf="eventDetailMode && event.viewedByManager && !chatPlayerMode && (playerEventInfo.teamMemberType != teamMemberType.CANDIDATE)">
    <div class="row team-all mt-4 my-0 px-3 align-items-center">
      <div class="col-auto my-0 mb-1">
        <b class="text-left fr-header">PLAYER INFO</b>
      </div>
    </div>
    <div class="card">
      <div class="card-body py-3 pl-3">
        <div class="players-list border-bottom border-bottom-not-last pb-2">
          <div class="d-flex">
            <div>
              <h6 class="mb-0">Status</h6>
              <p class="text-capitalize text-nogosh-grey3 text-sm mb-0">{{playerEventInfo.status.toString().replace("_"," ").toLowerCase()}}</p>
            </div>
            <div class="ml-auto d-inline-flex">
              <a href="javascript:void(0)" data-toggle="collapse" data-target="#collapseStatus" role="button" aria-expanded="false" aria-controls="collapseStatus" class="pl-4 d-inline-flex align-items-center flex-grow-1 no-underline icon-rotatable icon-rotatable-90">
              <i class="fas fa-chevron-right text-muted"></i>
              <span class="sr-only">Collapse</span>
              </a>
            </div>

          </div>
          <div class="collapse" id="collapseStatus">            
            <div class="py-3 d-flex justify-content-sm-center justify-content-between">
              <button *ngIf="playerEventInfo.status === playerEventInfoStatus.NOT_NOTIFIED" (click)="notifyPlayer()" class="btn btn-block btn-sm-inline-block px-3 btn-primary">
                Invite
                </button>
            </div>
            <div class="py-3 d-flex justify-content-sm-center justify-content-between">
              <button (click)="playerEventInfo.status !== playerEventInfoStatus.CONFIRMED ? mngUpdateInviteStatus(event, playerEventInfoStatus.CONFIRMED) : false"
              class="btn btn-lg btn-outline-primary border-2 mr-1 mr-sm-3 my-2 px-3 py-1 align-middle cursor-pointer" title="Confirmed"
              [ngClass]="{'active': playerEventInfo.status === playerEventInfoStatus.CONFIRMED}">
                <i class="fa fa-check-circle text-success" aria-hidden="true"></i>
              </button>

              <span class="border-right mr-0 mr-sm-3 pr-0 pr-sm-3 align-self-center">&#8203;</span>

              <button (click)="playerEventInfo.status !== playerEventInfoStatus.NOT_ANSWERED ? mngUpdateInviteStatus(event, playerEventInfoStatus.NOT_ANSWERED) : false"
              class="btn btn-lg btn-outline-primary border-2 mx-1 mx-sm-3 my-2 px-3 py-1 align-middle cursor-pointer" title="Confirmed"
              [ngClass]="{'active': playerEventInfo.status === playerEventInfoStatus.NOT_ANSWERED}">
                <i class="fa fa-question-circle text-warning" aria-hidden="true"></i>
              </button>

              <span class="border-right mr-0 mr-sm-3 pr-0 pr-sm-3 align-self-center">&#8203;</span>

              <button (click)="playerEventInfo.status !== playerEventInfoStatus.DECLINED ? mngUpdateInviteStatus(event, playerEventInfoStatus.DECLINED): false"
                    class="btn btn-lg btn-outline-primary border-2 mx-1 mx-sm-3 my-2 px-3 py-1 cursor-pointer" title="Declined"
                    [ngClass]="{'active': playerEventInfo.status === playerEventInfoStatus.DECLINED}">
                <i class="fa fa-times-circle text-danger" aria-hidden="true"></i>
              </button>

              <span class="border-right mr-0 mr-sm-3 pr-0 pr-sm-3 align-self-center">&#8203;</span>

              <button (click)="playerEventInfo.status !== playerEventInfoStatus.NOT_NOTIFIED ? mngUpdateInviteStatus(event, playerEventInfoStatus.NOT_NOTIFIED) : false"
                    class="btn btn-lg btn-outline-primary border-2 ml-1 ml-sm-3 my-2 px-3 py-1 cursor-pointer" title="Not Notified"
                    [ngClass]="{'active': playerEventInfo.status === playerEventInfoStatus.NOT_NOTIFIED}">
                <i class="fa fa-minus-circle text-secondary" aria-hidden="true"></i>
              </button>
            </div>
          </div><!-- /.collapse -->
        </div>
        <div class="players-list border-bottom border-bottom-not-last py-2">
          <div class="d-flex">
            <div>
              <h6 class="mb-0">Team</h6>
              <p class="text-capitalize text-nogosh-grey3 text-sm mb-0" >
                <span *ngIf="playerEventInfo.teamMemberType === teamMemberType.FIRST_TEAM" >1st Team</span>
                <span *ngIf="playerEventInfo.teamMemberType === teamMemberType.RESERVE">Reserve</span>
              </p>
            </div>
            <div class="ml-auto d-inline-flex">
              <a href="javascript:void(0)" data-toggle="collapse" data-target="#collapseTeam" role="button" aria-expanded="false" aria-controls="collapseTeam" class="pl-4 d-inline-flex align-items-center flex-grow-1 no-underline icon-rotatable icon-rotatable-90">
              <i class="fas fa-chevron-right text-muted"></i>
              <span class="sr-only">Collapse</span>
              </a>
            </div>

          </div>
          <div class="collapse" id="collapseTeam">
            <div class="py-3 d-flex justify-content-sm-center justify-content-center">

              <button (click)="playerEventInfo.teamMemberType !== teamMemberType.FIRST_TEAM ? changePlayerTeam(playerEventInfo, teamMemberType.FIRST_TEAM) : false" [ngClass]="(playerEventInfo.teamMemberType === teamMemberType.FIRST_TEAM) ? 'btn-primary' : 'btn-outline-primary'"
                      class="btn btn-block btn-sm-inline-block mr-2 px-3 text-nowrap">1st Team
              </button>

              <span class="border-right mr-0 mr-sm-3 pr-0 pr-sm-3 align-middle align-self-center">&#8203;</span>

              <button (click)="playerEventInfo.teamMemberType !== teamMemberType.RESERVE ? changePlayerTeam(playerEventInfo, teamMemberType.RESERVE): false"
              [ngClass]="(playerEventInfo.teamMemberType === teamMemberType.RESERVE) ? 'btn-primary' : 'btn-outline-primary'"
                      class="btn btn-block btn-sm-inline-block ml-2 px-3 text-nowrap">Reserve
              </button>

            </div>
          </div><!-- /.collapse -->
        </div>
      </div><!-- /.card-body -->
    </div>
  </div>
  <!-- end of MANAGER EDITS section -->

  <!-- PLAYER CONTACT section -->

  <div *ngIf="eventDetailMode && event.viewedByManager && !chatPlayerMode" class="row team-all mt-4 my-0 px-3 align-items-center">
    <div class="col-auto my-0 mb-1">
      <b class="text-left fr-header">PLAYER CONTACT</b>
    </div>
  </div>
  <div *ngIf="event.viewedByManager && (eventDetailMode || player.id !== currentPlayer.id) && !chatPlayerMode" class="players-list-wrapper card">
    <div class="card-body px-3 py-1">

      <div class="players-list border-bottom border-bottom-not-last py-2">
        <a [href]="'tel: ' + player.phoneNumber">
          <p class="h6 pb-0 mb-0">Phone number</p>
        </a>
        <a [href]="'tel: ' + player.phoneNumber">
          <label for="email" class="mb-0">{{player.phoneNumber}}</label>
        </a>
      </div>

      <div class="players-list border-bottom border-bottom-not-last py-2">
        <a [href]="'mailto: ' + player.loginEmail">
          <p class="h6 pb-0 mb-0">Email</p>
        </a>
        <a [href]="'mailto: ' + player.loginEmail">
          <label for="email" class="mb-0">{{player.loginEmail}}</label>
        </a>
      </div>
    </div>
  </div>
  <!-- end of PLAYER CONTACT section -->


  <div *ngIf="errorMsg" class="col-md-6 col-xs-12 text-left text-danger">
    <p class="h6">Error: </p>
    <p class="h5">{{errorMsg}}</p>
  </div>

  <form *ngIf="!eventDetailMode && !chatPlayerMode" (ngSubmit)="onSubmit()" autocomplete="off" #playerForm="ngForm" class="col-12 col-md-10 col-lg-7 mx-auto px-0">
    <div class="form-group">
      <div class="text-left">
        <div class="form-group">
          <label for="name" class="mb-0">Name (visible to manager)</label>
          <input type="text" class="form-control" id="name" placeholder="Enter full name" required [(ngModel)]="player.name" name="name"></div>
        <div class="form-group">
          <label for="nickname" class="mb-0">Nickname (visible to teammates)</label>
          <input type="text" class="form-control" id="nickname" placeholder="Enter nickname" required [(ngModel)]="player.nickName" name="nickName" #name="ngModel">
        </div>
        <div class="form-group">
          <label for="email" class="mb-0">Notification email (visible to manager)</label>
          <input type="text" class="form-control" id="email" placeholder="Enter email" [(ngModel)]="player.email" name="email">
        </div>
        <div class="form-group">
          <label for="city" class="mb-0">City</label>
          <input [(ngModel)]="player.city"
              placeholder="Enter city"
              [typeahead]="cityDataSource"
              typeaheadWaitMs="500"
              (typeaheadOnSelect)="typeaheadCitySelected($event);"
              name="city"
              class="form-control"
              id="city"
              autocomplete="off"
              required />
        </div>
        <div class="form-group">
          <label for="address" class="mb-0">Address</label>
          <input [(ngModel)]="player.address"
          placeholder="Enter address (optional)"
          [typeahead]="addressDataSource"
          (typeaheadOnSelect)="typeaheadAddressSelected($event);"
          typeaheadWaitMs="500"
          name="address"
          class="form-control"
          id="address"
          [disabled] = "player.city === null"
          autocomplete="off"/>
          <!--required />-->

        </div>
        <div class="form-group">
          <label for="email" class="mb-0">Phone (visible to manager)</label>
          <input type="tel" class="form-control" id="phone" placeholder="Enter phone number" [(ngModel)]="player.phoneNumber" name="phoneNumber">
        </div>

        <div class="mb-3">
          <div class="custom-control custom-switch">
            <input type="checkbox" class="custom-control-input  cursor-pointer" [checked]="player.active" name="activeCheckbox" [(ngModel)]="player.active" id="p_active">
            <label class="custom-control-label cursor-pointer" for="p_active">Send me My Matches invites</label>
          </div>
        </div>


        <div class="mb-3">
          <div class="custom-control custom-switch">
            <input type="checkbox" class="custom-control-input  cursor-pointer" [checked]="player.allowTeammatesSOS" name="allowTeammatesSOSCheckbox" [(ngModel)]="player.allowTeammatesSOS"
                   id="p_allowTeammatesSOS">
            <label class="custom-control-label cursor-pointer" for="p_allowTeammatesSOS">Send me My Teammates SOS invites</label>
          </div>
        </div>

        <div class="mb-3">
          <div class="custom-control custom-switch">
            <input type="checkbox" class="custom-control-input  cursor-pointer" [checked]="player.openProfile" name="openProfileCheckbox" [(ngModel)]="player.openProfile"
                   id="p_open_invites">
            <label class="custom-control-label cursor-pointer" for="p_open_invites">Send me other SOS invites</label>
          </div>
        </div>

        <div class="form-group">
          <label for="email" class="mb-0">Login email</label>
          <input type="text" class="form-control disabled" id="loginEmail" [(ngModel)]="player.loginEmail" name="loginEmail" disabled>
        </div>
      </div>
    </div>

    <app-dialog [(visible)]="addressMissing">
      <div class="modal-header">
        <br>
      </div>
      <div class="text-center pt-3 modal-body">
        <h5>You must set your City so that we can show you nearby matches.</h5>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary btn-sm px-3" (click)="addressMissing = false">Close</button>
      </div>
    </app-dialog>

    <div class="">
      <div class="row">
        <div class="col-12 my-4">
          <button type="submit" class="btn btn-primary btn-block btn-lg" [disabled]="!playerForm.form.valid">Save</button>
          <br/><br/><br/><br/><br/>
          <button type="button" class="btn btn-danger btn-block btn-lg" (click)="showDeleteDialog = !showDeleteDialog">Delete Profile</button>
          <app-dialog [(visible)]="showDeleteDialog">
            <div class="modal-header">
              <h5 class="mb-0">Are you sure you want to delete YOUR PROFILE?</h5>              
            </div>
            <div class="modal-body">
              <p>Once you delete your profile there is no going back. All your user details will be erased and if you ever want to join Footballer.zone again you will have to create a new profile?</p>
            </div>
            <div class="modal-footer justify-content-start">
              <button type="button" class="btn btn-primary text-white btn-sm px-3" (click)="deleteProfile(); showDeleteDialog = false">Yes, delete my profile.</button>
              <button type="button" class="btn btn-secondary btn-sm px-3" (click)="showDeleteDialog = false">No, don't do it</button>
            </div>
          </app-dialog>
        </div>
        <div class="col my-4 text-center">
          <a href="https://www.footballer.zone/privacy.html">Privacy Policy</a>
        </div>
      </div>
    </div>
  </form>
</div>
