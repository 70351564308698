import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { FooterComponent } from './footer.component';
import { ApiVersionService } from 'app/shared/services/apiVersion.service';

@NgModule({
  imports: [
    CommonModule, FormsModule
  ],
  exports: [FooterComponent],
  declarations: [FooterComponent],
  providers: [ApiVersionService]
})
export class FooterModule { }
