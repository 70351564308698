import { Component, Input, OnInit } from "@angular/core";
import { Feedback } from "../shared/models/feedback";
import { FeedbackService } from "../shared/services/feedback.service";
import { PlayerService } from "../shared/services/player.service";
import { Player } from "app/shared/models/player";
import { NgxSpinnerService } from "ngx-spinner";
import { BsModalService, BsModalRef } from "ngx-bootstrap/modal";
import { ModalContentComponent } from "../shared/modal/modal-content.component";

@Component({
  selector: "feedback",
  templateUrl: "./feedback.component.html",
})
export class FeedbackComponent implements OnInit {
  @Input() public feedback: Feedback;

  public feedbacks: Feedback[];
  public feedbackVisibility: boolean[];
  public loading = false;
  public currentPlayer: Player;
  bsModalRef: BsModalRef;

  constructor(public feedbackService: FeedbackService, public playerService: PlayerService, private spinner: NgxSpinnerService, private modalService: BsModalService) {}

  ngOnInit() {
    this.getFeedback();

    this.currentPlayer = this.playerService.getCurrentPlayer();
    this.resetFeedbackInput();
  }

  public onSubmit(): void {
    let me = this;
    me.loading = true;
    me.spinner.show();

    me.feedbackService.createFeedback(me.feedback).subscribe(
      (feedback) => {
        me.resetFeedbackInput();
        me.getFeedback();

        const initialState = {
          title: "Alert",
          text: "Thank you for your feedback!",
        };
        this.bsModalRef = this.modalService.show(ModalContentComponent, { initialState });
        this.bsModalRef.content.closeBtnName = "Close";
        me.spinner.hide();
        me.loading = false;
      },
      (error) => {
        console.log(error);
        me.loading = false;
        me.spinner.show();
      }
    );
  }

  public cancelFeedback(): void {
    this.resetFeedbackInput();
  }

  private resetFeedbackInput(): void {
    this.feedback = new Feedback(null, this.currentPlayer.email, false, "", "", true, null, 0);
  }

  public viewFeedback(id: string): void {
    this.feedbackVisibility[id] = !this.feedbackVisibility[id];
  }

  public getFeedback(): void {
    let me = this;

    me.loading = true;
    me.spinner.show();
    me.feedbackService.getFeedback().subscribe(
      (feedbacks) => {
        me.feedbackVisibility = new Array<boolean>(feedbacks.length);

        me.feedbacks = feedbacks;
        me.spinner.hide();
        me.loading = false;
      },
      (error) => {
        console.log(error);
        me.spinner.hide();
        me.loading = false;
      }
    );
  }
}
