import { Observable } from "rxjs";
import { Injectable } from "@angular/core";
import { environment } from "../../environments/environment";
import { Player, PlayerPushTokenInfo, RatingUpdate } from "../models/player";
import { Event } from "../models/event";
import { HttpClient, HttpHeaders, HttpResponse } from "@angular/common/http";
import { LogService } from "./log.service";
import { map } from "rxjs/operators";
import { LatLng } from "app/shared/models/latlng";
import { AuthService } from "@auth0/auth0-angular";
import { Router } from "@angular/router";

@Injectable()
export class PlayerService {
  public static DEFAULT_AVATAR_URL = environment.DEFAULT_AVATAR_URL;

  private player: Player;
  private apiUrl: string = environment.API_BASE_URL + "/player";
  private apiUrlProfile: string = environment.API_BASE_URL + "/profile";

  constructor(private authHttp: HttpClient, private logger: LogService, private auth: AuthService, public router: Router) {
    if (localStorage.getItem(environment.EMAIL)) {
      this.refreshUserInfo().subscribe(
        (player) => {
          this.logger.debug(`PlayerComponent -> refreshUserInfo() returned with player: ${player.email}`);
          this.setCurrentPlayer(player);
        },
        (error) => {
          this.logger.debug(`PlayerComponent -> refreshUserInfo() error: ${error}`);
          this.player = this.getCurrentPlayer();
        }
      );
    }
  }

  public refreshUserInfo(): Observable<Player> {
    this.logger.debug(`PlayerService.refreshUserInfo()...`);
    return this.authHttp.get<Player>(
      `${this.apiUrl}/find?email=${localStorage.getItem(environment.EMAIL)}&data=${localStorage.getItem(environment.KEY)}`,
      this.getRequestOptions()
    );
  }

  public checkCity(): void{
    if(this.player.city == null){
      this.router.navigate(["/player-detail", false]);
    }
  }

  public getCurrentPlayer(): Player {
    if (typeof this.player !== "undefined" && this.player && this.player.email) {
      return this.player;
    }

    if (!localStorage.getItem("player")) {
      this.logger.debug(`Unable to proceed without player data. Redirecting to authentication...`);
      this.auth.loginWithRedirect();
    }

    this.player = JSON.parse(localStorage.getItem("player"));

    this.logger.debug("PlayerService.getCurrentPlayer() returned player from local storage: " + this.player.email);

    return this.player;
  }

  public setCurrentPlayer(player: Player): void {
    this.player = player;

    if (!this.player.avatar || !this.player.avatar.startsWith("http")) {
      this.logger.debug("Detected broken avatar link: " + this.player.avatar);
      this.player.avatar = PlayerService.DEFAULT_AVATAR_URL;
    }
    localStorage.setItem("player", JSON.stringify(this.player));
  }

  public getEventsByEmail(playerEmail: string): Observable<Event[]> {
    return this.authHttp.get<Event[]>(`${this.apiUrl}/events/email/${playerEmail}`, this.getRequestOptions());
  }

  public getPlayers(partialNickName: string): Observable<Player[]> {
    return this.authHttp.get<Player[]>(`${this.apiUrl}/search/?partialNickName=${partialNickName}`, this.getRequestOptions())
      .pipe(
        map((players: any) => {
          this.setPlayersAutocompleteName(players);
          return players;
        }));
  }

  public getPlayersOpenProfile(partialNickName: string): Observable<Player[]> {
    return this.authHttp.get<Player[]>(`${this.apiUrl}/search/?partialNickName=${partialNickName}`, this.getRequestOptions())
      .pipe(
        map((players: any) => {
          this.setPlayersAutocompleteName(players);
          return players.filter(player => player.openProfile === true);
        }));
  }

  public getCities(partialCityName: string): Observable<string[]> {
    return this.authHttp.get<string[]>(`${this.apiUrl}/getCityPrediction/?city=${encodeURI(encodeURI(partialCityName))}`, this.getRequestOptions());
  }

  public getAddress(partialAddres: string, lat: number, lng: number): Observable<string[]> {
    return this.authHttp.get<string[]>(`${this.apiUrl}/getAddressPrediction/?address=${encodeURI(encodeURI(partialAddres))}&lat=${lat}&lng=${lng}`, this.getRequestOptions());
  }

  public getLatAndLng(city: string) {
    return this.authHttp.get<LatLng>(`${this.apiUrl}/getGeoLocation/?address=${city}`, this.getRequestOptions());
  }

  public getReserveOnlyPlayers(): Observable<Player[]> {
    return this.authHttp.get<Player[]>(`${this.apiUrl}/reserves`, this.getRequestOptions()).pipe(
      map((players: any) => {
        this.setPlayersAutocompleteName(players);
        return players;
      })
    );
  }

  public getPlayersWithoutTeams(): Observable<Player[]> {
    return this.authHttp.get<Player[]>(`${this.apiUrl}/no-events`, this.getRequestOptions()).pipe(
      map((players: any) => {
        this.setPlayersAutocompleteName(players);
        return players;
      })
    );
  }

  public getPlayer(email: string): Observable<Player> {
    this.logger.debug(`PlayerService.getPlayer(${email})...`);
    this.logger.debug(`${this.apiUrl}/searchplayer/?playerEmail=${email}`);

    return this.authHttp.get<Player>(`${this.apiUrl}/find?email=${email}`, this.getRequestOptions());
  }

  public getPlayerByAuthId(authId: string): Observable<Player> {
    return this.authHttp.get<Player>(`${this.apiUrl}/find/auth/?authId=${authId}`, this.getRequestOptions());
  }

  public updatePlayer(body: Object, playerId: string): Observable<Player> {
    let bodyString = JSON.stringify(body);
    return this.authHttp.put<Player>(`${this.apiUrl}/${playerId}`, bodyString, this.getRequestOptions());
  }

  public updateChatMuteSettings(player: Player, currentEmail: string): Observable<Player> {
    let bodyString = JSON.stringify(player);
    return this.authHttp.put<Player>(`${this.apiUrl}/updateChatMuteSettings/${currentEmail}`, bodyString, this.getRequestOptions());
  }

  public updatePlayerEmail(currentEmail: string, newEmail: string): Observable<Player> {
    return this.authHttp.put<Player>(`${this.apiUrl}/updateEmail/${currentEmail}?newEmail=${newEmail}`, this.getRequestOptions());
  }

  public updatePlayerPushToken(pushTokenInfo: PlayerPushTokenInfo): Observable<any> {
    let bodyString = JSON.stringify(pushTokenInfo);
    return this.authHttp.put<Player>(`${environment.API_BASE_URL}/mobile/push-access-token`, bodyString, this.getRequestOptions());
  }

  public deletePlayerById(playerId: string): Observable<string> {
    const formData = new FormData();
    formData.append("playerId", playerId);
    return this.authHttp.post(`${this.apiUrlProfile}/deletePlayerById`, formData, {responseType: 'text'});
  }

  private setPlayersAutocompleteName(players: Player[]): void {
    for (let player of players) {
      player.autocompleteName = player.nickName;
    }
  }

  public updatePlayerDefenceRating(targetPlayerId: string, ratingDefenceUpdate: RatingUpdate): Observable<any> {
    const bodyString = JSON.stringify(ratingDefenceUpdate);
    return this.authHttp.put<Player>(`${this.apiUrl}/update-defence-rating/${targetPlayerId}`, bodyString, this.getRequestOptions());
  }

  public updatePlayerOffenceRating(targetPlayerId: string, ratingOffenceUpdate: RatingUpdate): Observable<any> {
    const bodyString = JSON.stringify(ratingOffenceUpdate);
    return this.authHttp.put<Player>(`${this.apiUrl}/update-offence-rating/${targetPlayerId}`, bodyString, this.getRequestOptions());
  }

  private getRequestOptions() {
    return {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
      }),
    };
  }
}
