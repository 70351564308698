import {Component, OnInit} from "@angular/core";
import {PlayerService} from "../../shared/services/player.service";
import {Player} from "app/shared/models/player";
import {Router} from "@angular/router";
import {ChatService} from "../../shared/services/chat.service";
import {Event} from "../../shared/models/event";
import {Chat, ChatEntityType} from "../../shared/models/chat";
import {EventService} from "../../shared/services/event.service";
import { NgxSpinnerService } from "ngx-spinner";

@Component({
  selector: 'player-list',
  templateUrl: './player-list.component.html',
  styleUrls: ['../player.component.css'],
  providers: []
})
export class PlayerListComponent implements OnInit {

  public players: Player[];

  public allPlayers: Player[];
  public withoutTeamsPlayers: Player[];
  public reservesOnlyPlayers: Player[];

  public playerForInviteDialog: Player;

  public myEvents: Event[];
  public loading = false;

  public isWithoutTeamsClicked: boolean = true;
  public isReservesOnlyClicked: boolean = false;
  public isAllPlayersClicked: boolean = false;

  public showInviteDialog: boolean = false;

  private currentPlayer: Player;
  private chatEntityType = new ChatEntityType();

  constructor(private chatService: ChatService,
              private playerService: PlayerService,
              private eventService: EventService,
              private router: Router,
              private spinner: NgxSpinnerService) {
  }

  ngOnInit() {
    this.spinner.show();
    this.loading = true;

    this.currentPlayer = this.playerService.getCurrentPlayer();

    this.getPlayersWithoutTeams();
    this.getReserveOnlyPlayers();
    this.getAllPlayers();

    this.getMyEvents();
  }

  public enterOrCreateNewChat(player: Player): void {
    this.loading = true;
    this.spinner.show();

    let directChatEntityId = this.calculateDirectChatEntityId(this.currentPlayer, player);

    this.chatService.getChatForEntityId(directChatEntityId).subscribe(chat => {
      if (chat) {
        this.router.navigate(['/messages', {chatId: chat.id}]);
      } else {
        let chat = new Chat();
        chat.name = this.currentPlayer.nickName + ', ' + player.nickName;
        chat.entityType = this.chatEntityType.PLAYER;

        chat.contact = this.currentPlayer;

        chat.players = Array<Player>();
        chat.players.push(this.currentPlayer);
        chat.players.push(player);

        chat.entityId = directChatEntityId;

        this.chatService.createChat(chat).subscribe(chat => {
          this.router.navigate(['/messages', {chatId: chat.id}]);
        }, response => {
          console.log(response.error);
          this.spinner.hide();
          this.loading = false;
        });
      }
    }, response => {
      console.log(response.error);
      this.spinner.hide();
      this.loading = false;
    });
  }

  public sendInviteForEvent(player: Player, event: Event): void {
    this.spinner.show();
    this.loading = true;

    this.eventService.invitePlayersToJoinNogosh(event, player.email).subscribe(() => {
      this.spinner.hide();
      this.loading = false;
    }, res => {
      console.log(res.error);
      this.spinner.hide();
      this.loading = false;
    });
  }

  public filterPlayers(value: string): void {
    let me: any = this;

    if (value === 'withoutTeams') {
      me.isWithoutTeamsClicked = true;
      me.isReservesOnlyClicked = false;
      me.isAllPlayersClicked = false;

      me.players = me.withoutTeamsPlayers;
    } else if (value === 'reservesOnly') {
      me.isWithoutTeamsClicked = false;
      me.isReservesOnlyClicked = true;
      me.isAllPlayersClicked = false;

      me.players = me.reservesOnlyPlayers;
    } else {
      me.isWithoutTeamsClicked = false;
      me.isReservesOnlyClicked = false;
      me.isAllPlayersClicked = true;

      me.players = me.allPlayers;
    }
  }

  private calculateDirectChatEntityId(currentPlayer: Player, otherPlayer: Player): string {
    let ids: string[] = Array();
    ids.push(currentPlayer.id);
    ids.push(otherPlayer.id);
    ids.sort();

    return ids[0] + '-' + ids[1];
  }

  private getAllPlayers(): void {
    this.playerService.getPlayers('*').subscribe(players => {
      this.allPlayers = players.sort((a, b) => a.nickName.localeCompare(b.nickName));
      this.spinner.hide();
      this.loading = false;
    }, response => {
      console.log(response.error);
      this.spinner.hide();
      this.loading = false;
    });
  }

  private getPlayersWithoutTeams(): void {
    this.playerService.getPlayersWithoutTeams().subscribe(players => {
      this.withoutTeamsPlayers = players.sort((a, b) => a.nickName.localeCompare(b.nickName));

      this.filterPlayers('withoutTeams');
      this.spinner.hide();
      this.loading = false;
    }, response => {
      console.log(response.error);
      this.spinner.hide();
      this.loading = false;
    });
  }

  private getReserveOnlyPlayers(): void {
    this.playerService.getReserveOnlyPlayers().subscribe(players => {
      this.reservesOnlyPlayers = players.sort((a, b) => a.nickName.localeCompare(b.nickName));
      this.spinner.hide();
      this.loading = false;
    }, response => {
      console.log(response.error);
      this.spinner.hide();
      this.loading = false;
    });
  }

  private getMyEvents(): void {
    this.playerService.getEventsByEmail(this.currentPlayer.email).subscribe(events => {
      this.myEvents = events;
    }, response => {
      console.log(response.error);
    });
  }
}
