import {Observable} from 'rxjs';
import {Injectable} from "@angular/core";
import {environment} from "../../environments/environment";
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Chat} from "../models/chat";
import {Player} from "../models/player";

@Injectable()
export class ChatService {

  public apiUrl: string = environment.API_BASE_URL + '/chat';

  constructor(private authHttp: HttpClient) {
  }

  public static getRequestOptions() {
    return {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    };
  }

  public getChatForEntityId(entityId: string): Observable<Chat> {
    return this.authHttp.get<Chat>(`${this.apiUrl}/entity/${entityId}`, ChatService.getRequestOptions());
  }

  public getChatById(chatId: string): Observable<Chat> {
    return this.authHttp.get<Chat>(`${this.apiUrl}/${chatId}`, ChatService.getRequestOptions());
  }

  public getChatsForPlayer(playerId: string): Observable<Chat[]> {
    return this.authHttp.get<Chat[]>(`${this.apiUrl}/player/${playerId}`, ChatService.getRequestOptions());
  }

  public leaveChat(chat: Chat, player: Player): Observable<string> {
    return this.authHttp.put<string>(`${this.apiUrl}/${chat.id}/leave/${player.id}`, ChatService.getRequestOptions());
  }

  public createChat(body: Object): Observable<Chat> {
    let bodyString = JSON.stringify(body);
    return this.authHttp.post<Chat>(`${this.apiUrl}`, bodyString, ChatService.getRequestOptions());
  }

  public updateChat(body: Object): Observable<Chat> {
    let bodyString = JSON.stringify(body);
    return this.authHttp.put<Chat>(`${this.apiUrl}/${body['id']}`, bodyString, ChatService.getRequestOptions());
  }

  public removeChat(id: string): Observable<Chat> {
    return this.authHttp.delete<Chat>(`${this.apiUrl}/${id}`);
  }

  public getPlayers(chatId: string): Observable<Player[]> {
    return this.authHttp.get<Player[]>(`${this.apiUrl}/${chatId}/players`, ChatService.getRequestOptions());
  }

  public calculateDisplayName(chat: Chat, currentPlayerId: string) : Chat {
    if(chat.entityType == "EVENT"){
      chat.displayName = chat.name;
    }else if(chat.entityType == "PLAYER"){
      if(chat.players.length == 2){
        chat.players.forEach(player => {
          if(currentPlayerId != player.id){
            chat.displayName = player.nickName;
          }              
        });
      }else if(chat.players.length == 1){
        chat.displayName = "Player left the chat";
      }else if(chat.players.length > 2){
        chat.displayName = chat.name;
      }
    }
    return chat;
  }
}
