import { Component, Input, OnInit } from "@angular/core";
import { Event } from "../../shared/models/event";
import { EventService } from "../../shared/services/event.service";
import { PlayerService } from "../../shared/services/player.service";
import { PlayerEventInfoService } from "../../shared/services/playereventinfo.service";
import { PlayerEventInfoJersey } from "../../shared/models/playerEventInfo";
import { PlayerEventInfoStatus } from "../../shared/models/playerEventInfo";
import { Player } from "app/shared/models/player";
import { Router } from "@angular/router";
import { Location } from "@angular/common";
import { NgxSpinnerService } from "ngx-spinner";
import { switchMap } from "rxjs/operators";

@Component({
  selector: "event-history",
  templateUrl: "./event-history.component.html",
  styleUrls: ["../event.component.css"],
  providers: [],
})
export class EventHistoryComponent implements OnInit {
  @Input()
  public navigateHref: string = "";

  public events: Event[] = Array<Event>();
  public event: Event;

  public currentPlayer: Player;
  public pageTitle: string;

  public playerEventInfoJersey: PlayerEventInfoJersey = new PlayerEventInfoJersey();
  public playerEventInfoStatus: PlayerEventInfoStatus = new PlayerEventInfoStatus();

  public loading = false;

  public page: number = 0;
  public pageSize: number = 20;

  constructor(
    public playerService: PlayerService,
    public eventService: EventService,
    private playereventinfoService: PlayerEventInfoService,
    public router: Router,
    public location: Location,
    private spinner: NgxSpinnerService
  ) {}

  ngOnInit() {
    this.currentPlayer = this.playerService.getCurrentPlayer();

    if (!localStorage.getItem("historyEvent")) {
      this.router.navigate(["/event-list"]);
      return;
    }
    let eventForHistory: Event = JSON.parse(localStorage.getItem("historyEvent"));
    this.pageTitle = eventForHistory.name;

    this.fetchHistory(eventForHistory);
  }

  public fetchHistory(event: Event): void {
    //this.loading = true;
    //this.spinner.show();

    this.eventService
      .getEvent(event.originalEventId)
      .pipe(
        switchMap((event) => {
          this.event = event;
          console.log("Original event: ", event);

          if(this.events.length == 0) this.events.push(this.event);
      
          return this.eventService.fetchHistory(event.originalEventId);
        })
      )
      .subscribe(
        (archivedEvents) => {
          archivedEvents = archivedEvents.slice(this.page*this.pageSize, this.page*this.pageSize+this.pageSize);
          console.log("Event", event);
          console.log("Archived Events", archivedEvents);
          if (this.event.manager.id === this.currentPlayer.id) {
            this.event.viewedByManager = true;
          }

          if (archivedEvents !== null && archivedEvents.length > 0) {
            for (let archiveEvent of archivedEvents) {
              archiveEvent.confirmedCount = this.eventService.getConfirmedCount(archiveEvent);

              if (archiveEvent.manager.id === this.currentPlayer.id) {
                archiveEvent.viewedByManager = true;
              }

              this.playereventinfoService.fetchForEvent(archiveEvent.id).subscribe(
                (playerEventInfoList) => {
                  archiveEvent.playerEventInfos = playerEventInfoList;

                  for (let playerEventInfo of playerEventInfoList) {
                    if (playerEventInfo.player.id === this.currentPlayer.id) {
                      archiveEvent.viewedByPlayer = true;
                    }
                  }

                  this.setWonLostDrewNothing(archiveEvent);
                },
                (error) => {
                  console.log(error);
                }
              );

              this.events.push(archiveEvent);
            }
          }
          //this.loading = false;
          //this.spinner.hide();
        },
        (error) => {
          console.log(error);
          //this.loading = false;
          //this.spinner.hide();
        }
      );
  }

  public viewEvent(event: Event): void {
    localStorage.setItem("event", JSON.stringify(event));
    this.router.navigate(["/event-detail", false]);
  }

  public eventPlayers(event: Event): void {
    event.historyEvent = true;
    localStorage.setItem("event", JSON.stringify(event));
    this.router.navigate(["/event-players", false]);
  }

  public openMap(event: Event): void {
    if (navigator.platform.indexOf("iPhone") != -1 || navigator.platform.indexOf("iPod") != -1 || navigator.platform.indexOf("iPad") != -1) {
      this.navigateHref = "http://maps.apple.com/?q=" + event.playSpace.latitude + "," + event.playSpace.longitude;
    } else {
      this.navigateHref = "https://maps.google.com/maps?daddr=" + event.playSpace.latitude + "," + event.playSpace.longitude + "&amp;ll=";
    }
  }

  private setWonLostDrewNothing(event: Event): void {
    let didPlay: boolean = false;
    for (let playerEventInfo of event.playerEventInfos) {
      if (playerEventInfo.player.id === this.currentPlayer.id && playerEventInfo.status === this.playerEventInfoStatus.CONFIRMED) {
        didPlay = true;
        break;
      }
    }

    let isWhite: boolean = true;
    for (let playerEventInfo of event.playerEventInfos) {
      if (playerEventInfo.player.id === this.currentPlayer.id && playerEventInfo.jersey === this.playerEventInfoJersey.BLACK) {
        isWhite = false;
        break;
      }
    }

    if (!didPlay) {
      event.wonLostDrewNothing = "nothing";
    } else {
      if (event.whiteScore === event.blackScore) {
        event.wonLostDrewNothing = "drew";
      } else {
        if (isWhite) {
          event.teamWhite = true;

          if (event.whiteScore > event.blackScore) {
            event.wonLostDrewNothing = "won";
          } else {
            event.wonLostDrewNothing = "lost";
          }
        } else {
          event.teamWhite = false;

          if (event.blackScore > event.whiteScore) {
            event.wonLostDrewNothing = "won";
          } else {
            event.wonLostDrewNothing = "lost";
          }
        }
      }
    }
  }

  public loadMore(): void {
    this.page++;
    this.fetchHistory(this.event);
  }

  public goBack(): void {
    //console.log('uh');
    this.location.back();
  }
}
