import {Observable} from 'rxjs';
import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../environments/environment';

@Injectable()
export class IpInfoLocationService {

  constructor(private http: HttpClient) {
  }

  public getCurrentLocation(): Observable<IpInfoResponse> {
    return this.http.get<IpInfoResponse>(environment.ipInfoApiUrl);
  }
}

export class IpInfoResponse {
  public ip: string;
  public city: string;
  public region: string;
  public country: string;
  public loc: string;
}
