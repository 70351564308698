<!-- <ngx-loading [show]="loading" [config]="{ backdropBorderRadius: '3px' }"></ngx-loading> -->
<ngx-spinner
              bdColor="rgba(51,51,51,0.8)"
              size="medium"
              color="#fff"
              type="ball-scale-multiple"
              ><p style="font-size: 20px; color: white">Loading...</p>
              </ngx-spinner>
<div *ngIf="!loading" class="container" role="main">

  <header class="row my-3 my-md-4 mb-md-5 align-items-center">
    <a href="javascript:" class="btn-back col-auto border-right align-self-stretch" (click)="goBack()"><i class="fas fa-arrow-left"><span class="sr-only">Back</span></i></a>
    <div class="col">      
      <h2 class="m-0">Stats</h2> 
    </div>
    <div class="header-divider"></div>
  </header>

  <div *ngIf="eventStats" class="mx-auto">  
    <div *ngIf="player.id == eventStats.event.manager.id" class="pb-4 border-bottom mb-4 d-flex">
      <button (click)="recalculateStats(eventStats.event.id)" class="btn btn-warning px-3 mx-auto"><i class="fas fa-sync-alt mr-2 text-nogosh-grey3"></i> Recalculate stats for this crew</button>
    </div>
    <p>
      These are the team stats over the seasons. There are two seasons in a year. Seasons begin on
      {{eventStats.event.season1StartDate | date:'MMMM d'}} and {{eventStats.event.season2StartDate | date:'MMMM d'}}. We
      are only showing players who played more than 20% of all games in a season. If a season is empty it means that all of
      the player scores are 0 (not enough games played).
    </p>
    <div class="table-responsive">
      <table class="table table-striped table-sm table-hover" *ngFor="let seasonStats of eventStats.seasonStats; let i = index">
        <tbody>
        <tr>
          <th class="align-bottom">{{eventStats.event.name}} <br /> {{seasonStats.seasonStart | date:'MMM d yyyy'}} - {{seasonStats.seasonEnd |
            date:'MMM d yyyy'}}</th>
          <th class="align-bottom">
            <button type="button" class="btn btn-secondary btn-sm text-white px-3" [popover]="statsFormula" placement="bottom">
              Player Score
            </button>
          </th>
          <th class="rotate"><span>Played</span></th> 
          <th class="rotate"><span>Wins</span></th>
          <th class="rotate"><span>Loses</span></th>
          <th class="rotate"><span>Draws</span></th>
          <th class="rotate"><span>Scored</span></th>
          <th class="rotate"><span>Conceded</span></th>
          <th class="rotate"><span>Whites</span></th>
          <th class="rotate"><span>Blacks</span></th>
          <th class="rotate"><span>No response</span></th>
          <th class="rotate"><span>Not invited</span></th>
          <th class="rotate"><span>Declined</span></th>
        </tr>
        <tr *ngFor="let stats of seasonStats.stats; let i = index">
          <ng-container *ngIf="stats.playerScore > 0">
            <td class="text-truncate player-name" title="{{stats.playerName}}">
              {{i+1 + '. ' + stats.playerName}}
               
              <span>{{(i===0) ? '🏆' :''}} {{(stats.playerName==='Josip Maslać') ? '💩' :''}}</span>
            </td>
            <td>{{stats.playerScore}}</td>
            <td>{{stats.playedGames}}</td>
            <td>{{stats.playerWins}}</td>
            <td>{{stats.playerLoses}}</td>
            <td>{{stats.playerDraws}}</td>
            <td>{{stats.playerScored}}</td>
            <td>{{stats.playerConceded}}</td>
            <td>{{stats.playedAsWhites}}</td>
            <td>{{stats.playedAsBlacks}}</td>
            <td>{{stats.didNotRespond}}</td>
            <td>{{stats.wasNotInvited}}</td>
            <td>{{stats.declinedInvite}}</td>
          </ng-container>
        </tr>
      </tbody>
      </table>
    </div>
  </div>
</div>

<ng-template #statsFormula>
  <div class="col-xs-12 statsFormula">
    <b>Player score by Josip Kauf</b>
    <br />

    10 * <i>Win ratio</i> * (<i>Played</i> / 10) ^ 0,25
    <br />
    <br />

    <b>Win ratio</b>
    <br />

    (Wins + 0,5 * Draws) / Played
    <br />
    <br />

    <b>Played</b>
    <br />

    Wins + Draws + Losses
    <br />
    <br />
  </div>
</ng-template>
