<div class="text-center bg-primary w-100 h-100 d-flex flex-column overflow-hidden">
  <!-- <div class="mb-3">
    <div class="col-12 ">
      <h2>Authentication in progress</h2>
    </div>
  </div> 
  <div class="mb-3">
    <div class="col-12">
      <img src="../../../assets/images/soccer_ball_spinning.gif" alt='[]' />
    </div>
  </div>
  <div class="small">
      <p>...if you are experiencing issues please try Logout -> Refresh -> Login...</p>
    </div>
  </div>-->
  <main class="my-auto">
    <div class="container">
      <div class="row flex-column">
        <div class="col-auto mx-auto">        
         <img src="../../../assets/imgs/sb-logo.png" alt='[]' class="rounded-circle img-fluid animated bounceInDown" /> 
         <!-- <img src="../../../assets/images/soccer_ball_spinning.gif" alt='[]' class="rounded-circle img-fluid" /> --> 
         <h1 class="h2 my-2 mt-4 text-white text-center font-weight-400 animated bounceInUp">Love The Game</h1>
        </div>
      </div>
      
    </div>
  </main>

  <footer class="text-center mt-auto mb-2 text-white d-flex flex-column animated fadeIn"> 
    <p class="col-auto mx-auto mb-0 small">Created by</p>
    <p class="col-auto mx-auto d-inline-flex align-items-center justify-content-center">
      <img src="../../../assets/imgs/Profile_FeedbackV1/Mangonel_square.png">
      <span class="ml-2">Mangonel</span></p>
  </footer>  
</div>


