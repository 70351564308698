<div class="container" role="main">
    <div class="row my-4 my-md-5">
        <div class="col col-md-10 mx-md-auto">
            <h2 class="text-center mb-4">About Footballer</h2>
            <p>
              <strong>Create match, add players and then schedule the match!</strong>
              Footballer is a community of recreational
              footballers. This app is a tool that helps us organize and play football. Playing football with friends
              is one of those activities that makes this life better. It binds us in a positive way. The team spirit,
              the fair play battle, the friendships and the athletics all combined into something that is free. We
              leave our day to day worries outside of the pitch and enjoy the present moment with friends and future
              friends. Everybody is welcome. Just remember to play fair and have fun!
            </p>

            <p>
              Footballer is in alpha so there are lots of bugs and the looks could be better. Supported browsers are:
              Chrome-Firefox-Safari on desktop and mobile (latest versions). Known issues with older versions of Samsung Internet and IE.
              For any issues or questions please reach out to: <a href="mailto:vedran.stanic@mangonel.hr">vedran.stanic@mangonel.hr</a>
            </p>

            <h3 class="text-center my-4 border-top pt-4">The menu system</h3>
            <ul>
              <li>⚽ - matches - this is your list of upcoming matches followed by list of active matches that you could join.
                      Once invited you can choose whether you are coming or not.</li>
              <li>💬 - chats - every match series has it's own chat</li>
              <li>❓ - how to - it is the "how to" screen - you are reading it and that's good!</li>
              <li>🔢 - stats - every match has seasons and seasons have player stats</li>

              <li>The little "down arrow" is a list of more menus</li>
              <li><b>Locations</b> - a list of football fields in 100km radius from your location.
                                    You can always add your own favorite place and use it as a location for your matches!</li>
              <li><b>Player</b> - details about you! You stud!</li>
              <li><b>Feedback</b> - we would love to make this app better. Please give us feedback!</li>
            </ul>

            <h3 class="text-center my-4 border-top pt-4">As a Player</h3>
            <ul>
              <li>A player belongs to one or many matches</li>
              <li>A player, when part of a match, can be in the
                  starting lineup (<img src="../../assets/images/sensible_soccer_first_team.png" width="27px" height="27px" title="First team">)
                  or a reserve (<img src="../../assets/images/sensible_soccer_reserves.png" width="27px" height="27px" title="Reserves">)</li>
              <li>Players are then invited by the manager to each particular ⚽ Match</li>
              <li>💬 Chats are available for each match series that the Player belongs to</li>
              <li>Players can view the details of the match (who is coming, location, etc.)</li>
              <li>Players can view the history of the match series by clicking on History (who played, who won, etc.)</li>
              <li>Players can keep track of their 🔢 Stats per match and season</li>
              <li>Players are ranked in the 🔢 Stats by Josip Kauf's formula (you can see it by clicking on "Player Score")</li>
              <li>Players can leave Match</li>
              <li>Players can update their data (phone number, name, nickname, email)</li>
            </ul>

            <h3 class="text-center my-4 border-top pt-4">As a Manager</h3>
            <ul>
              <li>You are either a manager or a player or both</li>
              <li>You become a manager by creating a new match or by being made a Manager</li>
              <li>You should ask friends to create an account on Footballer and then add them to the match</li>
              <li>The little soccer player image next to player names indicate First Team or Reserves.
                  You can cycle through the types by clicking the little image.</li>
              <li>Match can have 2 seasons which define the 🔢 Stats</li>
              <li>Once you pick/create a location you can schedule a ⚽ Match on the new location</li>
              <li>A ⚽ Match can be a single match or a recurring match and it has a location</li>
              <li>The next recurring ⚽ Match will be automatically extended/created when the time comes</li>
              <li>Creating a Match automatically creates a 💬 Chat</li>
              <li>The manager invites players to every match by clicking Notify->First team for example</li>
              <li>The manager can cycle through the invite, confirm, decline status of each player invitation</li>
              <li>The manager can chose who he invites (first team, reserves, individual players)</li>
              <li>Invitations are sent out via email and players select whether they will come or not through the app</li>
              <li>The Manager can resend the notifications to the people who have not answered yet</li>
              <li>Before the kickoff the Manager may divide the players into blacks and whites subteams by clicking Modify on the match</li>
              <li>The Manager may then prepopulate a final email to be sent out to everyone invited by clicking Notify->Send Final Email on a match</li>
              <li>The manager may enter the score of each match occurrence which will automatically calculate the 🔢 Stats</li>
              <li>The manager may create a Rulebook to be followed (fair play, throw ins, etc.)</li>
              <li>The manager may transfer his managerial role to another player using Modify</li>
            </ul>

            <h3 class="text-center my-4 border-top pt-4">Footballer roadmap</h3>
            <ul>
              <li>We are working on a cross platorm mobile app for Players</li>
              <li>The design/look and feel is being worked on</li>
              <li>Your comments, suggestions and complaints are highly appreciated and can be placed in the Feedback section</li>
              <li>If you run into any problems please reach out to: <a href="mailto:vedran.stanic@mangonel.hr">vedran.stanic@mangonel.hr</a></li>
              <li>If you would like to be deleted from Footballer please reach out to: <a href="mailto:vedran.stanic@mangonel.hr">vedran.stanic@mangonel.hr</a></li>
            </ul>
        </div>
    </div>
</div>
