import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {EventListComponent} from './event/event-list/event-list.component';
import {PlaySpaceFormComponent} from './playspace/play-space.component';
import {FeedbackComponent} from './feedback/feedback.component';
import {HelpComponent} from './help/help.component';
import {StatsDetailComponent} from './stats/stats-detail.component';
import {PlayerDetailFormComponent} from './player/player-detail/player-detail.component';
import {ChatComponent} from './chat/chat.component';
import {EventDetailComponent} from './event/event-detail/event-detail.component';
import {EventPlayersComponent} from "./event/event-players/event-players.component";
import {EventHistoryComponent} from "./event/event-history/event-history.component";
import {ChatListComponent} from "./chat/chat-list/chat-list.component";
import {ChatDetailComponent} from "./chat/chat-detail/chat-detail.component";
import {PlayerListComponent} from "./player/player-list/player-list.component";

const routes: Routes = [
  {path: '', component: EventListComponent},

  {path: 'event-list', component: EventListComponent},
  {path: 'event-history', component: EventHistoryComponent},
  {path: 'event-detail/:editSelectedEvent', component: EventDetailComponent},
  {path: 'event-players/:editSelectedEvent', component: EventPlayersComponent},

  {path: 'messages', component: ChatComponent},
  {path: 'chat-list', component: ChatListComponent},
  {path: 'chat-detail', component: ChatDetailComponent},

  {path: 'player-list', component: PlayerListComponent},

  {path: 'help', component: HelpComponent},
  {path: 'player-detail/:eventDetailMode', component: PlayerDetailFormComponent},
  {path: 'feedback', component: FeedbackComponent},
  {path: 'playspace/:createNewPlayspace', component: PlaySpaceFormComponent},
  {path: 'stats-detail/:id', component: StatsDetailComponent},

  {path: '**', redirectTo: ''}
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    scrollPositionRestoration: 'enabled'
} /*, { enableTracing: true } */ /*, { enableTracing: true } */ /*, { enableTracing: true } *//*, { enableTracing: true } */)],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
