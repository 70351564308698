import {ErrorHandler, Injectable} from '@angular/core';
import {LogService} from '../services/log.service';

@Injectable()
export class CustomErrorHandler implements ErrorHandler {
  constructor(private logger: LogService) {
  }

  handleError(error) {
    console.log("Footballer.zone caught the following error:");
    console.log(error);
    this.logger.log("\nError: " + error + "\n, Stacktrace: " + error.stack + " page: " + window.location.href);
  }
}
