import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { HelpComponent } from './help.component';

@NgModule({
  imports: [
    CommonModule, FormsModule
  ],
  exports: [HelpComponent],
  declarations: [HelpComponent],
  providers: []
})
export class HelpModule { }
