import {NgModule, CUSTOM_ELEMENTS_SCHEMA} from "@angular/core";
import {CommonModule} from "@angular/common";
import {FormsModule} from "@angular/forms";
import {CoreModule} from "../core/core.module";
import {BsDatepickerModule} from "ngx-bootstrap/datepicker";
import {TimepickerModule} from "ngx-bootstrap/timepicker";
import {TypeaheadModule} from 'ngx-bootstrap/typeahead';
import {BsDropdownModule} from 'ngx-bootstrap/dropdown';
import {PlaySpaceService} from "../shared/services/playspace.service";
import {ChatModule} from "../chat/chat.module";
import {EventDetailComponent} from "./event-detail/event-detail.component";
import {EventListComponent} from "./event-list/event-list.component";
import {EventService} from "../shared/services/event.service";
import {PlayerEventInfoService} from "../shared/services/playereventinfo.service";
import {PopoverModule} from 'ngx-bootstrap/popover';
import {PlayerModule} from "../player/player.module";
import {RouterModule} from "@angular/router";
//import {NgxLoadingModule} from 'ngx-loading';
import {EventPlayersComponent} from "./event-players/event-players.component";
import {EventHistoryComponent} from "./event-history/event-history.component";
import {GroupByPipe} from "../shared/pipes/group.pipe";
import {SortByPipe} from "../shared/pipes/sort.pipe";
import {SortGroupedByPipe} from "../shared/pipes/sortGrouped.pipe";
import { NgxSpinnerModule } from "ngx-spinner";
import { GoogleMapsModule } from "@angular/google-maps";
import { MomentModule } from "ngx-moment";
import { AppModule } from "app/app.module";
import { TimezonePipe } from "app/shared/pipes/timezone.pipe";

@NgModule({
  declarations: [EventDetailComponent, GroupByPipe, SortByPipe, SortGroupedByPipe, EventListComponent, EventPlayersComponent, EventHistoryComponent, TimezonePipe],
  imports: [CommonModule, 
    FormsModule, 
    CoreModule,
    BsDatepickerModule, 
    TimepickerModule, 
    TypeaheadModule, 
    BsDropdownModule, 
    ChatModule,
    PopoverModule, 
    PlayerModule, 
    RouterModule, 
 //   NgxLoadingModule.forRoot({}), 
    NgxSpinnerModule,
    GoogleMapsModule,
    MomentModule
  ],
  exports: [EventListComponent],
  providers: [EventService, PlaySpaceService, PlayerEventInfoService],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class EventModule {
}
