export class Feedback {
  id: string;
  email: string;
  anonymous: boolean;
  subject: string;
  feedback: string;
  active: boolean;
  createdDate: Date;
  votes: number;
  constructor(id: string, email: string, anonymous: boolean, subject: string, feedback: string, active: boolean, createdDate: Date, votes: number) {
    this.id = id;
    this.email = email;
    this.anonymous= anonymous;
    this.subject = subject;
    this.feedback = feedback;
    this.active = active;
    this.createdDate = createdDate;
    this.votes = votes;
  }
}
