<!-- <ngx-loading [show]="loading" [config]="{ backdropBorderRadius: '3px' }"></ngx-loading> -->
<ngx-spinner
  bdColor="rgba(51,51,51,0.8)"
  size="medium"
  color="#fff"
  type="ball-scale-multiple"
><p style="font-size: 20px; color: white">Loading...</p>
</ngx-spinner>

<div *ngIf="!loading" class="container" role="main">
  <header class="row my-3 my-md-4 mb-md-5 align-items-center">
      <a href="javascript:" class="btn-back col-auto border-right align-self-stretch" (click)="goBack()"><i class="fas fa-arrow-left"><span class="sr-only">Back</span></i></a>
      <div class="col">      
        <h2 class="m-0">Chat details</h2> 
      </div>
      <div class="header-divider"></div>
  </header>

  <form class="row" autocomplete="off" (ngSubmit)="saveChat()" #chatForm="ngForm">

    <div class="form-group col-12 col-md-6 mx-auto">
      

      <div class="mb-4">
        <label for="chatName" class="mb-0 text-sm">Chat name</label>
        <input type="text" class="form-control" id="chatName" placeholder="Change chat name"
              [(ngModel)]="chat.displayName" name="chatName">
      </div>

      <div class="mb-4">
        <input [(ngModel)]="autocompletePlayer" class="form-control"
              placeholder="Search for a player to add to this chat..."
              (typeaheadOnSelect)="typeaheadPlayerSelected($event)"
              [typeahead]="playerDataSource" (typeaheadLoading)="changeTypeaheadLoading($event, 'playerEmail')"
              (typeaheadNoResults)="changeTypeaheadNoResults($event, 'playerEmail')"
              typeaheadOptionField="autocompleteName" typeaheadOptionsLimit="10"
              id="chatPlayer" #newPlayer autocomplete="off" name="autocompletePlayerPicker" required>
        <div *ngIf="typeaheadPlayerLoading === true">
          <i class="fas fa-sync ng-hide"></i>
        </div>
        <div *ngIf="typeaheadPlayerNoResults === true">
          <i class="fas fa-trash-alt col-sm-offset-1"></i> No results found
        </div>

      </div>

      <div class="mb-4 mb-md-5">
        <div *ngFor="let player of chat.players" id="playersEditMode">
          <div class="border-bottom border-bottom-not-last" >
            <div *ngIf="chat.viewedByManager" class="row text-sm pt-2" >

              <!-- nickname -->
              <div class="col-auto" title="{{player.nickName}}">
                <label for="playersEditMode" cl>{{player.name}}, {{player.nickName}}</label>
              </div>
              <!-- remove -->
              <div class="col-auto ml-auto">
                <a href="javascript:void(0)" class="h5 text-danger d-inline-block" (click)="removePlayerFromChat(player)" [ngClass]="player.id === chat.contact.id ? 'disabled' : ''"><i class="fas fa-times-circle"><span class="sr-only">Remove Player</span></i></a>
              </div>
            </div>
          </div>
        </div><!-- #playersEditMode -->
      </div>

      <div class="row">
        <div class="col-12"><div class="pt-4 border-top"></div></div>
          <div *ngIf="chat.viewedByManager" [ngClass]="chat.id && chat.entityType === chatEntityType.PLAYER && chat.priority >= 10 ? 'col': ''">
  
            <button *ngIf="chat.id && chat.entityType === chatEntityType.PLAYER && chat.priority >= 10"
                    class="btn btn-danger px-3" type="button"
                    (click)="showDeleteDialog = !showDeleteDialog">Delete
            </button>
  
            <app-dialog [(visible)]="showDeleteDialog">
              <div class="modal-header">
                <h5 class="mb-0">Are you sure you want to delete this chat!</h5>
              </div>
              <div class="modal-footer justify-content-center">
                <button type="button" class="ml-auto btn btn-secondary text-white btn-sm px-3" (click)="showDeleteDialog = false">No</button>
                <button type="button" class="btn btn-primary btn-sm px-3" (click)="removeChat(); showDeleteDialog = false">Yes</button>
                
              </div>
            </app-dialog> 
          </div>
          
          
          <div class="col-auto ml-auto pr-0">
            <button (click)="goBack()" class="btn btn-secondary text-white px-3" type="button">Cancel</button>
            <!-- <button (click)="cancelChatDetailsChange()" class="btn btn-secondary text-white px-3" type="button">Cancel</button> -->
          </div>
          <div class="col-auto">
            <button class="btn btn-primary px-3" type="submit">Save</button>
          </div>
        </div><!--  /.row -->

    </div><!-- .form-group -->
  </form>
</div>
