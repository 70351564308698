import { Component, OnInit, Inject } from "@angular/core";
import { PlayerService } from "../services/player.service";
import { Player } from "../models/player";
import { AuthService } from "@auth0/auth0-angular";
import { DOCUMENT } from "@angular/common";

@Component({
  selector: "app-nav-bar",
  templateUrl: "./nav-bar.component.html",
  styleUrls: ["./nav-bar.component.css"],
})
export class NavBarComponent implements OnInit {
  public player: Player;

  constructor(public auth: AuthService, private playerService: PlayerService, @Inject(DOCUMENT) private doc: Document) {}

  ngOnInit(): void {
    this.player = this.playerService.getCurrentPlayer();
  }

  logout(): void {
    localStorage.removeItem("nearByLocations");
    localStorage.removeItem("player");
    this.auth.logout({ localOnly: true });
  }
}
