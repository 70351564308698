<ng-template #playerDetailsPopupTemplateRef>
  <div>
    <div class="img-profile">
      <img title="Profile picture" alt="Profile picture" class="rounded-circle img-fluid center-block animated fadeIn"
          style="width: 100px; height:100px;"
          [src]="playerDetails.avatar" onError="this.src='./assets/images/player-icon-002.png';" />
    </div>

    <div class="col-xs-12 player-details" style="">
      <ul class="list-unstyled mb-1">
        <li title="{{playerDetails.name}}" title="{{playerDetails.name}}" class="d-block text-truncate"><b>Name: </b> {{playerDetails.name}}</li>
        <li title="{{playerDetails.nickName}}" title="{{playerDetails.nickName}}" class="d-block text-truncate"><b>Nickname: </b>{{playerDetails.nickName}}</li>
        <li class="d-block text-truncate"><b>Email: </b><a href="mailto:{{playerDetails.email}}" title="{{playerDetails.email}}">{{playerDetails.email}}</a></li>
        <li *ngIf="playerDetails.phoneNumber" class="d-block text-truncate">
          <b>Phone: </b><a href="sms:{{playerDetails.phoneNumber}}" title="{{playerDetails.phoneNumber}}">{{playerDetails.phoneNumber}}</a>
        </li>
      </ul>      

      <div *ngIf="this.currentUser.superAdmin">
        <div class="form-group-xs mb-2">
          <label for="newEmail" class="mb-0"><b>Enter new email: </b></label>
          <input type="text" id="newEmail" [(ngModel)]="newEmail" name="newEmail" size="30" class="table-bordered">
        </div>
        <button (click)="updateUserEmail()" aria-label="Close" class="btn btn-sm btn-primary">Update user email</button>
      </div>
    </div>
  </div>
</ng-template>
