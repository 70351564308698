import { SafeHtml, DomSanitizer } from "@angular/platform-browser";
import { Message } from "../models/message";
import { Injectable } from "@angular/core";

@Injectable()
export class ChatUtils {

  constructor(private sanitizer: DomSanitizer) { }

  //TODO: this is a security voulnerability: https://angular.io/guide/security#xss
  public visualizeMessage(message: Message): void {
    message.html = message.text;
    var imgRgx = /(https?:\/\/.*?\.(?:png|jpg|jpeg|gif|png|svg))/ig;
    if (message.text.search(imgRgx) != -1) {
      message.html = message.text.replace(imgRgx, function (x) {
        return "<br/><img src='" + x + "' /><br/>";
      });
    }
    var vidRgx = /(https?:\/\/.*?\.(?:mp4|ogg))/ig;
    if (message.text.search(vidRgx) != -1) {
      message.html = message.text.replace(vidRgx, function (x) {
        return '<br/><video width="400" controls>' +
          '<source src="' + x + '" type="video/mp4">' +
          '<source src="' + x + '" type="video/ogg">' +
          'Your browser does not support HTML5 video.' +
          '</video><br/>'
      });
    }
    //add the youtu.be, etc. - TODO: refresh works, websocket addition does not... :(
    var ytbRgx = /(https?:\/\/(.*youtube)[^\s]+)/ig;
    if (message.text.search(ytbRgx) != -1) {
      message.text = message.text.replace(ytbRgx, function (x) {
        var ytbVideoIdRgx = /^.*(?:(?:youtu\.be\/|v\/|vi\/|u\/\w\/|embed\/)|(?:(?:watch)?\?v(?:i)?=|\&v(?:i)?=))([^#\&\?]*).*/;
        var videoId = x.match(ytbVideoIdRgx);
        return '<br/><iframe width="560"' +
          ' height="315"' +
          ' src="https://www.youtube.com/embed/' + videoId[1] + '"' +
          ' frameborder="0"' +
          ' allow="autoplay; encrypted-media"' +
          ' allowfullscreen>' +
          '</iframe><br/>';
      });
      message.html = this.sanitize(message);
    }
  }

  public sanitize(message: Message): SafeHtml {
    return this.sanitizer.bypassSecurityTrustHtml(message.text);
  }
}
