import { AfterViewChecked, AfterViewInit, ChangeDetectorRef, Component, ElementRef, EventEmitter, NgZone, OnDestroy, OnInit, Output, ViewChild } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { Message } from "../shared/models/message";
import { PlayerService } from "../shared/services/player.service";
import { Player } from "../shared/models/player";
import { ChatService } from "../shared/services/chat.service";
import { MessageService } from "../shared/services/message.service";
import { Chat } from "../shared/models/chat";
import { ChatUtils } from "../shared/utils/chat.utils";
import { environment } from "../environments/environment";
import { Location } from "@angular/common";
import { NgxSpinnerService } from "ngx-spinner";

@Component({
  selector: "messages",       
  templateUrl: "./chat.component.html",
  styleUrls: ["./chat.component.css"],
})
export class ChatComponent implements OnInit, AfterViewChecked, OnDestroy {
  @ViewChild("scrollMe")
  public myScrollContainer: ElementRef;

  @ViewChild("chatWrapper") public chatWrapper: ElementRef;
  @ViewChild("chatTextBox") public chatTextBox: ElementRef;
  @ViewChild("heightDebug") public heightDebug: ElementRef;   

  @Output()
  public noMessagesEmitter: EventEmitter<boolean> = new EventEmitter<boolean>();

  public messages: Message[];
  public messagesBySenderGrouped: Message[] = [];
  public transformedMessages: any[];
  public selectedChatName: string;

  public newMessageText: string;

  public throttle = 200;
  public scrollDownDistance = 1;
  public scrollUpDistance = 3;

  public chat: Chat;
  public viewedByManager: boolean = false;
  public showLeaveDialog: boolean = false;

  public loading = false;

  private selectedChatId: string;
  public currentPlayer: Player;
  private unreadMessagesCount: number;
  private newChatLoadInProgress: boolean = false;

  private loadedPageIndex: number = 0;
  private resultsPerPage: number = 100;
  private chatInitialLoad: boolean = false;

  private socket: WebSocket;
  private wsUrl: string = environment.WEB_SOCKET_URL;
  private chatWrapperLoaded: number = 0;

  public originalInnerHeight:number = 0;

  constructor(
    public chatUtils: ChatUtils,
    public playerService: PlayerService,
    public chatService: ChatService,
    public messageService: MessageService,
    public router: Router,
    public cdRef: ChangeDetectorRef,
    public zone: NgZone,
    public route: ActivatedRoute,
    private _location: Location,
    private spinner: NgxSpinnerService
  ) {}

  ngOnInit() {
    this.cdRef.reattach();

    this.loading = true;
    this.spinner.show();
    this.currentPlayer = this.playerService.getCurrentPlayer();

    let chatId = this.route.snapshot.params["chatId"];
    let entityId = this.route.snapshot.params["entityId"];

    if (chatId) {
      this.loadMessagesByChatId(chatId);
    } else {
      this.loadMessagesByEventId(entityId);
    }

    this.newMessageText = "";
    this.loadedPageIndex = 1;

    this.setUnreadMessagesCount(0);
    this.chatInitialLoad = true;

    this.originalInnerHeight = window.innerHeight;
  }

  public chatPlayerInfo(player: Player): void {
    localStorage.setItem("chatPlayer", JSON.stringify(player));
    this.router.navigate(["/player-detail/" + true]);
  }

  ngAfterViewChecked() {
    this.resizeChatOnLoadResize(null, false);
  }

  scrollToBottom(): void {
    try {
      this.myScrollContainer.nativeElement.scrollTop = this.myScrollContainer.nativeElement.scrollHeight;
      this.setUnreadMessagesCount(0);
    } catch (err) {
      console.log("scrollToBottom() error: " + err);
    }
  }

  public onScrollUp(): void {

    if (this.newChatLoadInProgress) {
      setTimeout(() => {
        this.scrollToBottom();
        this.newChatLoadInProgress = false;
      });
      console.log("onScrollUp loading");
      return;
    }
    this.fetchAdditionalMessages(this.selectedChatId);

    console.log("end onScrollUp");
  }

  public onScrollDown(): void {
    this.setUnreadMessagesCount(0);
  }

  ngOnDestroy() {
    if (this.cdRef) {
      this.cdRef.detach();
    }

    if (this.socket) {
      this.socket.close();
    }
  }

  public modifyChat(): void {
    localStorage.setItem("chat", JSON.stringify(this.chat));
    this.router.navigate(["/chat-detail"]);
  }

  public leaveChat(): void {
    this.loading = true;
    this.spinner.show();
    this.chatService.leaveChat(this.chat, this.currentPlayer).subscribe(
      (res) => {
        this.router.navigate(["/chat-list"]);
        this.spinner.hide();
        this.loading = false;
      },
      (res) => {
        console.log(res.error);
        this.spinner.hide();
        this.loading = false;
      }
    );
  }

  public submitNewMessage(newMessage: string): void {
    let me = this;

    if (newMessage == null || newMessage.length < 2) {
      me.newMessageText = "";
      return;
    }

    let message: Message = new Message();
    message.chatId = me.selectedChatId;
    message.sender = me.currentPlayer;
    message.createdDateString = new Date().toLocaleString();
    message.likesCount = 0;
    message.dislikesCount = 0;
    message.text = newMessage;

    if (this.socket.readyState == 1) {
      me.socket.send(JSON.stringify(message));
    } else {
      let retry = () => {
        if (this.socket.readyState == 1) {
          me.socket.send(JSON.stringify(message));
        } else {
          setTimeout(retry, 2000);
        }
      };

      setTimeout(retry, 2000);
    }

    me.setUnreadMessagesCount(0);
    me.newMessageText = "";

    me.scrollToBottom();

  }

  public captureBlurEventOnMessageSubmit(newMessage: string): void {
    let _iOSDevice = !!navigator.userAgent.match(/iPhone|iPod|iPad/);
    if (_iOSDevice) {
      this.submitNewMessage(newMessage);
      this.resizeChatOnLoadResize(null, true);
    }
  }

  private loadMessagesByEventId(eventId: string) {
    this.chatService.getChatForEntityId(eventId).subscribe(
      (chat) => {
        if (chat && this.currentPlayer.id === chat.contact.id) {
          this.viewedByManager = true;
        }

        this.chat = chat;
        this.changeDisplayName();
        this.chatTab(chat);
        this.spinner.hide();
        this.loading = false;
      },
      (error) => {
        console.log(error);
        this.spinner.hide();
        this.loading = false;
      }
    );
  }

  private loadMessagesByChatId(chatId: string) {
    this.chatService.getChatById(chatId).subscribe(
      (chat) => {
        if (chat && this.currentPlayer.id === chat.contact.id) {
          this.viewedByManager = true;
        }

        this.chat = chat;
        this.changeDisplayName();
        this.chatTab(chat);
        this.spinner.hide();
        this.loading = false;
      },
      (error) => {
        console.log(error);
        this.spinner.hide();
        this.loading = false;
      }
    );
  }

  private fetchAdditionalMessages(eventId: string): void {
    let me = this;
    me.spinner.show();
    me.loading = true;

    const startingScrollTop = this.myScrollContainer.nativeElement.scrollTop;
    const startingScrollHeigh = this.myScrollContainer.nativeElement.scrollHeight;
    console.log("1. height: " + this.myScrollContainer.nativeElement.scrollHeight + ", top: " + this.myScrollContainer.nativeElement.scrollTop);

    me.messageService.fetchAdditionalMessagesForChat(eventId, me.loadedPageIndex, me.resultsPerPage).subscribe(
      (messagesList) => {



        if (messagesList === null || messagesList.length === 0) {
          me.spinner.hide();
          me.loading = false;
          me.noMessagesEmitter.emit(true);

          return;
        }

        messagesList = messagesList.reverse();
        for (let message of messagesList) {
          if (message.sender.id === me.currentPlayer.id && !message.sender.avatar && me.currentPlayer.avatar) {
            message.sender.avatar = me.currentPlayer.avatar;
          }

          me.chatUtils.visualizeMessage(message);
          me.spinner.hide();
          me.loading = false;
        }

        me.messages = messagesList.concat(this.messages);

        this.transformMessages(me.messages);

        me.loadedPageIndex += 1;

        setTimeout(() => {
          const newScrollHeight = this.myScrollContainer.nativeElement.scrollHeight;
          const diffScrollHeight = newScrollHeight-startingScrollHeigh;
          const newTop = diffScrollHeight+startingScrollTop;
          // set the scroll height from the difference of the new and starting scroll height
          console.log("2. height: " + newScrollHeight + ", diffScrollHeight: " + diffScrollHeight, ", newTop: " + newTop);
          this.myScrollContainer.nativeElement.scrollTo(0, newTop);
          console.log("3. height: " + this.myScrollContainer.nativeElement.scrollHeight + ", top: " + this.myScrollContainer.nativeElement.scrollTop);
        }, 1);    
      },
      (error) => {
        console.log(error);
        this.spinner.hide();
        me.loading = false;
      }
    );
  }

  private iOS(): boolean {
    let iDevices = ["iPad Simulator", "iPhone Simulator", "iPod Simulator", "iPad", "iPhone", "iPod"];

    if (!!navigator.platform) {
      while (iDevices.length) {
        if (navigator.platform === iDevices.pop()) {
          return true;
        }
      }
    }

    return false;
  }

  private initializeWebSocket(): void {
    let me = this;

    me.socket = new WebSocket(`${this.wsUrl}/name?${this.selectedChatId}`, []);

    me.socket.addEventListener("open", (event) => {
      me.zone.run(() => {
        me.scrollToBottom();
      });
    });

    me.socket.addEventListener("message", (event) => {
      me.zone.run(() => {
        let receivedComment: Message = JSON.parse(event.data);
        me.chatUtils.visualizeMessage(receivedComment);

        me.messages.push(receivedComment);

        this.pushMessageInTransform(receivedComment);

        setTimeout(() => {
          me.scrollToBottom();
        }, 100);
      });
    });

    me.socket.addEventListener("close", (event) => {
      console.log("Close event raised. Event details: " + JSON.stringify(event));

      me.zone.run(() => {
        setTimeout(() => {
          me.initializeWebSocket();
        }, 2000);
      });
    });

    me.socket.addEventListener("error", (event) => {
      console.log("ChatDetailComponent -> wsError... The socket had an error", event);
    });
  }

  private chatTab(chat: Chat): void {
    let me = this;

    me.loading = true;
    me.spinner.show();
    me.messages = Array<Message>();
    me.messageService.fetchAdditionalMessagesForChat(chat.id, null, me.resultsPerPage).subscribe((messagesList) => {
      if (messagesList !== null && messagesList.length > 0) {
        me.messages = messagesList.reverse();

        for (let message of messagesList) {
          me.chatUtils.visualizeMessage(message);
        }
        this.transformMessages(me.messages);
      }

      me.selectedChatId = chat.id;
      me.selectedChatName = chat.name;
      me.unreadMessagesCount = 0;

      me.initializeWebSocket();

      setTimeout(function () {
        me.scrollToBottom();
      }, 0);
      me.spinner.hide();
      me.loading = false;
    });
  }

  private setUnreadMessagesCount(messagesCount: number): void {
    this.unreadMessagesCount = messagesCount;
    this.cdRef.detectChanges();
  }

  public calculateHeightOfNonScrollParent(): number{
    var substractHeight = 0,
    htmlElementsAboveValue = this.chatWrapper.nativeElement.getAttribute("data-height-on"),
    htmlElementsAbove = document.querySelectorAll(htmlElementsAboveValue);
    for (var i = 0; i < htmlElementsAbove.length; i++) {
      substractHeight += htmlElementsAbove[i].offsetHeight;
    }
    return substractHeight;
  }

  public resizeChatOnLoadResize(event, isBlurEvent:boolean) {
      if(this.chatWrapper){
        this.chatWrapper.nativeElement.querySelector(".scroll-parent").style.maxHeight = this.originalInnerHeight - this.calculateHeightOfNonScrollParent() + "px";
        this.chatWrapper.nativeElement.style.maxHeight = this.originalInnerHeight - this.calculateHeightOfNonScrollParent() + "px";
      }

      // var heightDebug = this.heightDebug;      
      // var chatTextBox = this.chatTextBox;
      //setTimeout(function(){ 
      //}, 500);
      //if ((chatWrapper && event) || (chatWrapper && this.chatWrapperLoaded === 0)) {
      // this.chatWrapperLoaded++;
      // var alertText = "window.innerHeight: " + window.innerHeight;
      // alertText += "window.visualViewport.height: " + window.visualViewport.height;
      //alert(alertText);
      // if(!isKeyboardEvent){
      // }else{
      //   chatWrapper.nativeElement.querySelector(".scroll-parent").style.maxHeight = window.innerHeight - substractHeight + "px";
      //   this.scrollToBottom();
      // }
      //window.visualViewport
      //var keyboardHeight = window.innerHeight - window.visualViewport.height;
      //chatWrapper.nativeElement.querySelector(".scroll-parent").style.maxHeight =window.innerHeight - keyboardHeight + "px";
      //console.log('idi', substract, htsElms, this.chatWrapper,  event.target.innerHeight);
      // var keyboardHeight = window.innerHeight - window.visualViewport.height;
      // console.log("isBlurEvent: " + isBlurEvent);
      // console.log("keyboardHeight: " + keyboardHeight);
      //chatWrapper.nativeElement.querySelector(".scroll-parent").style.maxHeight = window.innerHeight - substractHeight + "px";
      // heightDebug.nativeElement.innerHTML = window.visualViewport.height  + " - " + window.innerHeight  + " - originalInnerHeight: " + this.originalInnerHeight; 
      //chatTextBox.nativeElement.style.height = "50px";
  }

  public goBack(): void {
    this._location.back();
  }

  public splitByAndRemoveOther(string, needle, tagname, className, returnNeedle) {
    var array = string.split(needle),
      toReturn = "";
    for (var i = 0; i < array.length; i++) {
      if (array[i].trim() != this.currentPlayer.nickName) {
        toReturn += "<" + tagname + ' class="' + className + '">' + array[i] + "</" + tagname + ">";
      }
    }
    return toReturn;
  }

  public getMessageDateMode(createdDate: number): string {
    let mode = "";
    let current = Date.now();
    let todayMS = current % 86400000;

    if (current - todayMS > createdDate + 518400000) {
      return (mode = "6Days");
    }

    if (current - todayMS > createdDate + 86400000) {
      return (mode = "Days");
    }
    if (current - todayMS > createdDate) {
      return (mode = "Yesterday");
    }
    if (current - todayMS < createdDate) {
      return (mode = "Today");
    }
  }

  public transformMessages(messages: Message[]): any {
    var previousMessageSenderId = null;
    this.messagesBySenderGrouped = Array<Message>();

    messages.forEach((message, index) => {
      if(message.sender.id !== previousMessageSenderId){
        message.messages = Array<Message>();
        message.messages.push(message);
        this.messagesBySenderGrouped.push(message);
      }else{      
        this.messagesBySenderGrouped[this.messagesBySenderGrouped.length-1].messages.push(message);
      }

      previousMessageSenderId = message.sender.id;
    });
  }

  private changeDisplayName() : void{
    this.chatService.calculateDisplayName(this.chat, this.currentPlayer.id);
  }

  public pushMessageInTransform(message: Message) {
    this.transformMessages(this.messages);
  }

  public getDateString(created: number): string {
    let d = new Date(created);
    let ye = new Intl.DateTimeFormat("en", { year: "numeric" }).format(d);
    let mo = new Intl.DateTimeFormat("en", { month: "short" }).format(d);
    let da = new Intl.DateTimeFormat("en", { day: "2-digit" }).format(d);
    let weda = new Intl.DateTimeFormat("en", { weekday: "long" }).format(d);
    let ho = new Intl.DateTimeFormat("en-GB", { hour: "2-digit" }).format(d);
    let min = new Intl.DateTimeFormat("en-GB", { minute: "2-digit" }).format(d);
    if (min.length === 1) min = "0".concat(min);
    const mode = this.getMessageDateMode(created);
    switch (mode) {
      case "6Days":
        return "".concat(weda, ", ", mo, " ", da, ", ", ho, ":", min);
      case "Days":
        return "".concat(weda, ", ", ho, ":", min);
      case "Yesterday":
        return "Yesterday".concat(" ", ho, ":", min);
      case "Today":
        return "".concat(ho, ":", min);
    }
  }
}
