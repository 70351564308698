import { Component, OnInit } from "@angular/core";
import { Player, RatingUpdate } from "../../shared/models/player";
import { PlayerService } from "../../shared/services/player.service";
import { ActivatedRoute, Router } from "@angular/router";
import { PlayerEventInfo, PlayerEventInfoStatus, TeamMemberType } from "../../shared/models/playerEventInfo";
import { Event } from "../../shared/models/event";
import { Chat, ChatEntityType } from "../../shared/models/chat";
import { ChatService } from "../../shared/services/chat.service";
import { PlayerEventInfoService } from "../../shared/services/playereventinfo.service";
import { Location } from "@angular/common";
import { Observable } from "rxjs/internal/Observable";
import { mergeMap } from "rxjs/operators";
import { LatLng } from "app/shared/models/latlng";
import { EventService } from "app/shared/services/event.service";
import { NgxSpinnerService } from "ngx-spinner";
import { BsModalService, BsModalRef } from "ngx-bootstrap/modal";
import { ModalContentComponent } from "../../shared/modal/modal-content.component";
import { AuthService } from "@auth0/auth0-angular";

@Component({
  selector: "player-detail",
  templateUrl: "./player-detail.component.html",
  styleUrls: ["../player.component.css"],
})
export class PlayerDetailFormComponent implements OnInit {
  public player: Player;
  public playerEventInfo: PlayerEventInfo;

  public errorMsg: string;
  public loading = false;

  public eventDetailMode: boolean = false;
  public playerEventInfoStatus: PlayerEventInfoStatus = new PlayerEventInfoStatus();
  public event: Event = new Event();

  public teamMemberType: TeamMemberType = new TeamMemberType();
  private chatEntityType = new ChatEntityType();

  private currentPlayer: Player;

  public cityDataSource: Observable<any>;
  public addressDataSource: Observable<any>;
  public locationLatLng: LatLng;
  public chatPlayerMode: boolean = false;
  bsModalRef: BsModalRef;

  public showDeleteDialog: boolean = false;

  public addressMissing: boolean = false;

  public ratingDefence: number;
  public ratingOffence: number;
  public ratingAverageDefence: number;
  public ratingAverageOffence: number;

  public isReadonly: boolean = false; // Default rating value
  
  constructor(
    public auth: AuthService,
    private chatService: ChatService,
    public playerService: PlayerService,
    public route: ActivatedRoute,
    private playerEventinfoService: PlayerEventInfoService,
    private router: Router,
    public eventService: EventService,
    private _location: Location,
    private spinner: NgxSpinnerService,
    private modalService: BsModalService
  ) {
    this.cityDataSource = new Observable((observer: any) => {
      observer.next(this.player.city);
      this.spinner.hide();
      this.loading = false;
    }).pipe(mergeMap((token: string) => this.getCitiesAsObservable(token)));

    this.addressDataSource = new Observable((observer: any) => {
      observer.next(this.player.address);
      this.spinner.hide();
      this.loading = false;
    }).pipe(mergeMap((address: string) => this.getAddressAsObservable(address)));

    route.params.subscribe((val) => {
      this.currentPlayer = this.playerService.getCurrentPlayer();
      this.eventDetailMode = JSON.parse(this.route.snapshot.params["eventDetailMode"]);
      this.player = this.playerService.getCurrentPlayer();

      let chatPlayerDetails = JSON.parse(localStorage.getItem("chatPlayer"));

      if (chatPlayerDetails !== null) {
        this.chatPlayerMode = true;
        this.player = chatPlayerDetails;
      } else {
        if (this.eventDetailMode) {
          this.playerEventInfo = JSON.parse(localStorage.getItem("peiForPlayerDetail"));
          this.player = this.playerEventInfo.player;
          this.event = JSON.parse(localStorage.getItem("eventForPlayerDetail"));
        }
      }
    });
  }

  public typeaheadCitySelected(selectedCity: any) {
    this.player.city = selectedCity.value;
    this.playerService.getLatAndLng(this.player.city).subscribe((latLng) => {
      this.locationLatLng = latLng;
    });
  }

  public typeaheadAddressSelected(selectedAddress: any) {
    this.player.address = selectedAddress.value;
    this.playerService.getLatAndLng(this.player.city + selectedAddress).subscribe((latLng) => {
      this.locationLatLng = latLng;
    });
  }

  public getCitiesAsObservable(partialCityName: string) {
    return this.playerService.getCities(partialCityName);
  }

  public getAddressAsObservable(partialAddres: string) {
    if (this.locationLatLng === undefined) {
      this.locationLatLng = new LatLng();
      this.locationLatLng.lat = this.player.location.coordinates[0];
      this.locationLatLng.lng = this.player.location.coordinates[1];
    }
    return this.playerService.getAddress(partialAddres, this.locationLatLng.lat, this.locationLatLng.lng);
  }

  public changePlayerTeam(playerEventInfo: PlayerEventInfo, teamMemberType: string) {
    this.playerEventInfo.teamMemberType = teamMemberType;

    this.playerEventinfoService.update(playerEventInfo, this.currentPlayer).subscribe(
      (updatedPlayerEventInfo) => {
        this.playerEventInfo = updatedPlayerEventInfo;
      },
      (error) => {
        console.log(error);
      }
    );
  }

  public mngUpdateInviteStatus(event: Event, status: string): void {
    this.playerEventInfo.status = status;

    this.playerEventinfoService.update(this.playerEventInfo, this.currentPlayer).subscribe(
      (updatedPlayerEventInfo) => {
        this.playerEventInfo = updatedPlayerEventInfo;
      },
      (error) => {
        console.log(error);
        if (error.status == 406) {
          const initialState = {
            title: "Alert",
            text: "Max number of players already signed up!!! Please refresh the page to see the latest status of the match.",
          };
          this.bsModalRef = this.modalService.show(ModalContentComponent, { initialState });
          this.bsModalRef.content.closeBtnName = "Close";
        }
      }
    );
  }

  private calculateDirectChatEntityId(currentPlayer: Player, otherPlayer: Player): string {
    let ids: string[] = Array();
    ids.push(currentPlayer.id);
    ids.push(otherPlayer.id);
    ids.sort();

    return ids[0] + "-" + ids[1];
  }

  public enterOrCreateNewChat(playerForChat: Player) {
    this.spinner.show();
    this.loading = true;

    let directChatEntityId = this.calculateDirectChatEntityId(this.currentPlayer, playerForChat);

    this.chatService.getChatForEntityId(directChatEntityId).subscribe(
      (chat) => {
        if (chat) {
          this.router.navigate(["/messages", { chatId: chat.id }]);
        } else {
          let chat = new Chat();
          chat.name = this.currentPlayer.nickName + ", " + playerForChat.nickName;
          chat.entityType = this.chatEntityType.PLAYER;

          chat.contact = this.currentPlayer;

          chat.players = Array<Player>();
          chat.players.push(this.currentPlayer);
          chat.players.push(playerForChat);

          chat.entityId = directChatEntityId;

          this.chatService.createChat(chat).subscribe(
            (chat) => {
              this.router.navigate(["/messages", { chatId: chat.id }]);
            },
            (response) => {
              console.log(response.error);
              this.spinner.hide();
              this.loading = false;
            }
          );
        }
      },
      (response) => {
        console.log(response.error);
        this.spinner.hide();
        this.loading = false;
      }
    );
  }

  public notifyPlayer(): void {
    this.eventService.notifyPlayer(this.playerEventInfo).subscribe(
      (updatedPc) => {
        this.playerEventInfo = updatedPc;
        this.spinner.hide();
        this.loading = false;
      },
      (error) => {
        console.log(error);
        this.spinner.hide();
        this.loading = false;
      }
    );
  }

  public invitePlayer(): void {
    this.eventService.invitePlayer(this.playerEventInfo).subscribe(
      (updatedPc) => {
        this.playerEventInfo = updatedPc;
        this.spinner.hide();
        this.loading = false;
      },
      (error) => {
        console.log(error);
        this.spinner.hide();
        this.loading = false;
      }
    );
  }

  public setAsManager() {
      this.eventService.changeManager(this.event.id, this.playerEventInfo.player.id).subscribe(
      (managerChanged) => {
        this.spinner.hide();
        this.loading = false;
        this.router.navigate(["/", false]);
      },
      (error) => {
        console.log(error);
        this.spinner.hide();
        this.loading = false;
      }
    );
    // this.event.manager = this.playerEventInfo.player;
    // this.event.locationId = this.event.playSpace.id;
    // this.event.playSpace.location = null;
    // this.eventService.updateEvent(this.event).subscribe(
    //   (updatedEvent) => {
    //     this.event = updatedEvent;
    //     localStorage.setItem("event", JSON.stringify(event));
    //     this.spinner.hide();
    //     this.loading = false;
    //   },
    //   (error) => {
    //     console.log(error);
    //     this.spinner.hide();
    //     this.loading = false;
    //   }
    // );
  }

  public removePlayerFromMatch() {
    this.playerEventinfoService.delete(this.playerEventInfo.id).subscribe(
      () => {
        this.event.playerEventInfos = this.event.playerEventInfos.filter((item) => item.id !== this.playerEventInfo.id);
        localStorage.setItem("event", JSON.stringify(this.event));

        this.router.navigate(["/event-players", false]);
      },
      (res) => {
        console.log(res.error);
      }
    );
  }

  ngOnInit() {
    this.errorMsg = null;
    if(this.player.city == null){
      this.addressMissing=true;
    }

    this.preloadRatings();
  }

  public onSubmit(): void {
    this.loading = true;
    this.spinner.show();

    this.playerService.updatePlayer(this.player, this.player.id).subscribe(
      (player) => {
        this.player = player;
        this.playerService.setCurrentPlayer(player);

        this.errorMsg = null;
        this.loading = false;
        this.spinner.hide();

        this.router.navigate(["/", false]);
      },
      (response) => {
        this.cancelPlayerDetailsChange();

        this.errorMsg = response.error.errorMsg;

        console.log(response.error);
        this.spinner.hide();
        this.loading = false;
      }
    );
  }

  public cancelPlayerDetailsChange(): void {
    this.player = JSON.parse(localStorage.getItem("player"));
    this.playerService.setCurrentPlayer(this.player);
  }

  public deleteProfile(): void {

    this.playerService.deletePlayerById(this.currentPlayer.id).subscribe(
      () => {
        localStorage.removeItem("nearByLocations");
        localStorage.removeItem("player");
        this.auth.logout({ localOnly: true });
      },
      (res) => {
        console.log(res);
        alert(res.error);
      }
    );
  }

  public goBack(): void {
    this._location.back();
  }

  public setDefence(): void {
    const ratingDefenceUpdate: RatingUpdate = {
      ratedBy: this.currentPlayer.id,
      rating: this.ratingDefence,
    };
  
    this.playerService.updatePlayerDefenceRating(this.player.id, ratingDefenceUpdate).subscribe(
      (response) => {
        console.log('Rating successfully updated:', response);
      },
      (error) => {
        console.error('Failed to update rating:', error);
      }
    );

  }

  public setOffence(): void {
    const ratingOffenceUpdate: RatingUpdate = {
      ratedBy: this.currentPlayer.id,
      rating: this.ratingOffence,
    };
  
    this.playerService.updatePlayerOffenceRating(this.player.id, ratingOffenceUpdate).subscribe(
      (response) => {
        console.log('Rating successfully updated:', response);
      },
      (error) => {
        console.error('Failed to update rating:', error);
      }
    );

  }

  ngOnDestroy(): void {
    localStorage.setItem("chatPlayer", null);
  }

  preloadRatings(): void {
    const currentPlayerId = this.currentPlayer.id;
    
    const defenceRating = this.player.ratingDefence.find(rating => rating.ratedBy === currentPlayerId);
    if (defenceRating) {
      this.ratingDefence = defenceRating.rating;
    } else {
      this.ratingDefence = null;
    }
    
    const offenceRating = this.player.ratingOffence.find(rating => rating.ratedBy === currentPlayerId);
    if (offenceRating) {
      this.ratingOffence = offenceRating.rating;
    } else {
      this.ratingOffence = null;
    }

    //average each players offence and defence rating and store them into the ratingAverageDefence and ratingAverageOffence variables
    this.ratingAverageDefence = Math.round(this.player.ratingDefence.reduce((acc, rating) => acc + rating.rating, 0) / this.player.ratingDefence.length);
    this.ratingAverageOffence = Math.round(this.player.ratingOffence.reduce((acc, rating) => acc + rating.rating, 0) / this.player.ratingOffence.length);
  }
}
