import {Component, OnInit} from '@angular/core';
import {StatsService} from '../shared/services/stats.service';
import {Player} from '../shared/models/player';
import {PlayerService} from '../shared/services/player.service';
import {SeasonStats} from '../shared/models/seasonStats';
import {EventStats} from '../shared/models/eventStats';
import {Event} from "../shared/models/event";
import {ActivatedRoute, Router} from '@angular/router';
import {Location} from '@angular/common';
import { NgxSpinnerService } from "ngx-spinner";

@Component({
  selector: 'stats-detail',
  templateUrl: './stats-detail.component.html',
  styleUrls: ['./stats.component.css']
})

export class StatsDetailComponent implements OnInit {

  public event: Event; 
  public eventStats: EventStats;

  public player: Player;
  public loading = false;

  constructor(public statsService: StatsService, public playerService: PlayerService,
              private activatedRoute: ActivatedRoute, public router: Router,
              private _location: Location,
              private spinner: NgxSpinnerService) {
    this.loading = true;
    this.spinner.show();

    this.activatedRoute.url.subscribe(url => {
      this.getData();
      this.loading = false;
      this.spinner.hide();
    });
  }

  ngOnInit() {
    let me = this;

    me.getData();
    me.player = me.playerService.getCurrentPlayer();
  }

  public getData(): void {
    let me = this;
    me.loading = true;
    me.spinner.show();

    if (!localStorage.getItem('event')) {
      this.router.navigate(['/event-list']);
      return;
    }

    me.eventStats = new EventStats();
    me.eventStats.event = JSON.parse(localStorage.getItem('event'));

    let originalEventId = me.activatedRoute.snapshot.paramMap.get('id');

    me.statsService.getEventStats(originalEventId).subscribe(stats => {
      let noOfSeasons = Math.max.apply(Math, stats.map(function (o) {
        return o.season;
      }));

      me.eventStats.seasonStats = new Array();

      for (let currentSeason = noOfSeasons; currentSeason >= 0; currentSeason--) {
        let seasonStats = new SeasonStats();
        seasonStats.stats = stats.filter(playerStats => playerStats.season === currentSeason);
        seasonStats.seasonStart = seasonStats.stats[0].seasonStart;
        seasonStats.seasonEnd = seasonStats.stats[0].seasonEnd;
        me.eventStats.seasonStats.push(seasonStats);
      }

      me.loading = false;
      me.spinner.hide();
    }, error => {
      console.log(error);
      me.loading = false;
      me.spinner.hide();
    });
  }

  public recalculateStats(originalEventId: string): void {
    this.loading = true;
    this.spinner.show();

    this.statsService.recalculateStatsForEvent(originalEventId).subscribe(() => {
      this.ngOnInit();
      this.loading = false;
      this.spinner.hide();
    }, error => {
      console.log(error);
      this.loading = false;
      this.spinner.hide();
    });
  }


  public goBack():void{
    //console.log('uh');
    this._location.back();
  }

}
