<!-- <ngx-loading [show]="loading" [config]="{ backdropBorderRadius: '3px' }"></ngx-loading> -->
<ngx-spinner
              bdColor="rgba(51,51,51,0.8)"
              size="medium"
              color="#fff"
              type="ball-scale-multiple"
              ><p style="font-size: 20px; color: white">Loading...</p>
              </ngx-spinner>

<div *ngIf="!loading" class="container" role="main">

    <header class="row my-3 my-md-4 mb-md-5 align-items-center">
        <a href="javascript:" class="btn-back col-auto border-right align-self-stretch" (click)="goBack()"><i class="fas fa-arrow-left"><span class="sr-only">Back</span></i></a>
        <div class="col">      
          <h2 class="m-0">Locations</h2>  
        </div>             
        <div class="header-divider"></div>
    </header>

  <form (ngSubmit)="onSubmit()" autocomplete="off" #playSpaceForm="ngForm" class="col-lg-10 mx-auto">

    <div *ngIf="!editMode" class="row flex-column">
      <div class="">
        <button class="btn btn-success px-3" (click)="editCreatePlayspace(null)"><i _ngcontent-c8="" class="fas fa-map-marker-alt mr-2 text-nogosh-grey1"></i> Add new play location</button>
      </div>
      <div class="form-group-xs mt-2">
          
          <!-- <div *ngIf="apiLoaded | async"> -->
            <google-map [options]="mapOptions" height="500px" width="100%">
              <map-marker *ngFor="let m of markers; let i = index" #marker="mapMarker" (mapClick)="openInfoWindow(marker, i)"  [position]="m.googleLatLng">
                <map-info-window>
                  <strong>{{m.name}}</strong>
                  <br>
                  <span>{{m.address}}, {{m.city.name}}, {{m.city.country.name}}</span>
                  <br>
                  <br>
                  <span>Working hours: </span><strong>{{m.workingHours}}</strong>
                  <br>
                  <span>Phone: </span><strong>{{m.phone}}</strong>
                  <br>
                  <br>
                  <span>Web: </span><a target="_blank" href="{{m.webAddress}}"><strong>{{m.webAddress}}</strong></a>
                  <br>
                  <br>
                  <strong *ngIf="m.viewedByManager"><a (click)="editCreatePlayspace(m)">Edit</a></strong>
                  <br>
                </map-info-window>
              </map-marker>
            </google-map>
      </div>
    </div>

    <div *ngIf="editMode" class="row">
      <div class="col-12 d-empty-none"><div class="alert alert-danger alert-dismissible fade show col-12" role="alert">
          <p class="playspace-note mb-0">
              Make sure you <b>address is correct</b> and that map pointer is pointing the <b>correct location on the map</b>.
              Coordinates taken from the pointers location will be used to navigate players to this location.
          </p>
          <button type="button" class="close" data-dismiss="alert" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
        </div><!--/.alrt  --></div>
      

      <div class="col-12 col-md-6">
        <div class="form-group">
          <label for="psName" class="mb-0">Name</label>
          <input type="text" class="form-control" [(ngModel)]="playSpace.name" placeholder="Enter location name" name="psName" id="psName" required>
        </div>
        <div class="form-group">
          <label for="city" class="mb-0">City</label>
          <input [(ngModel)]="playSpace.city.name" 
              placeholder="Enter city"
              [typeahead]="cityDataSource" 
              (typeaheadOnSelect)="typeaheadCitySelected($event);"
              name="city"
              class="form-control" 
              id="city"
              autocomplete="off"
              required
              type="search"
               />
        </div>
        <div class="form-group">
          <label for="address" class="mb-0">Address</label>
          <input [(ngModel)]="playSpace.address" 
              placeholder="Enter address or select from map"
              [typeahead]="addressDataSource" 
              (typeaheadOnSelect)="typeaheadAddressSelected($event);"
              name="address"
              class="form-control" 
              id="address"
              autocomplete="off"
              required
              type="search" />
        </div>
        <div class="form-group">
          <label for="phone" class="mb-0">Phone</label>
          <input type="text" class="form-control" id="phone" placeholder="Enter contact phone"
                [(ngModel)]="playSpace.phone" name="phone">
        </div>
        <div class="form-group">
          <label for="web" class="mb-0">Web address</label>
          <input type="text" class="form-control" id="web" placeholder="Enter web site address"
                [(ngModel)]="playSpace.webAddress" name="web">
        </div>
        <div class="form-group">
          <label for="hours" class="mb-0">Working hours</label>
          <input type="text" class="form-control" id="hours" placeholder="Enter working hours (i.e 09am - 20pm)"
                [(ngModel)]="playSpace.workingHours" name="hours">
        </div>
      </div>

      <!-- right side - editMode -->
      <div class="col-12 col-md-6 text-left">
        
        <div class="mt-4 mb-3 bg-secondary loading-holder">
          <google-map [options]="mapOptions" height="275px" width="100%" (mapClick)="mapUpdatePlayLocationInfo(null,  $event)">
            <map-marker *ngIf="playSpace.longitude && playSpace.latitude" [position]="playSpace.googleLatLng" [options]="markerOptions" (mapDragend)="mapUpdatePlayLocationInfo(null,  $event)">
            </map-marker>
          </google-map>
        </div>

        <div class="form-group">
          <label for="coordinates" class="mb-0">Coordinates</label>
          <input type="text" class="form-control" id="coordinates" disabled value="({{playSpace.latitude}}, {{playSpace.longitude}})">
        </div>
        <div class="form-group">
          <label for="city" class="mb-0">Manager</label>
          <input type="text" class="form-control" id="manager" disabled required [(ngModel)]="playSpace.manager.name" name="managerName">
        </div>
      </div>

      <div class="col-12 mb-4">
        <div class="row justify-content-center justify-content-md-end?">
          <div class="col-12"><div class="pt-4 mt-2 border-top"></div></div>
          <div *ngIf="playSpace.manager.id == currentPlayer.id" class="col-auto"><button type="button" class="btn btn-danger px-3" (click)="deletePlayspace()">Delete</button></div>
          <div class="col-auto"><button type="button" class="btn btn-secondary px-3 text-white" (click)="cancelPlaySpaceEdit()">Cancel</button></div>
          <div class="col-auto"><button type="submit" class="btn btn-primary px-3" [disabled]="!playSpaceForm.form.valid">Save</button></div>
        </div> 
      </div>


    </div>
  </form>
</div>
