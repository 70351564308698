import { Observable } from "rxjs";
import { Injectable } from "@angular/core";
import { environment } from "../../environments/environment";
import { PlaySpace } from "../models/playSpace";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { DbIpResponse } from "./js/db-ip-response";
import { IpInfoLocationService } from "./ipinfo.location.service";
import { PlayerLocationInfo } from "../models/player";
import { PlayerService } from "./player.service";
import { switchMap } from "rxjs/operators";
import { ApiVersion } from "../models/apiVersion";

@Injectable()
export class ApiVersionService {
  private apiUrl: string = environment.API_BASE_URL + "/apiVersion";

  constructor(private authHttp: HttpClient) {}

  public getApiVersion(): Observable<ApiVersion> {
    return this.authHttp.get<ApiVersion>(`${this.apiUrl}`, this.getRequestOptions());
  }

  private getRequestOptions() {
    return {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
      }),
    };
  }
}
