<!-- <ngx-loading [show]="loading" [config]="{ backdropBorderRadius: '3px' }"></ngx-loading> -->
<ngx-spinner
  bdColor="rgba(51,51,51,0.8)"
  size="medium"
  color="#fff"
  type="ball-scale-multiple"
>
  <p style="font-size: 20px; color: white">Loading...</p>
</ngx-spinner>

<div *ngIf="!loading" class="container my-3 my-md-4" role="main">

  <header class="row my-3 my-md-4 mb-md-5 align-items-center">
    <div class="col">
            <h2 class="m-0">Matches in <a href="javascript:" routerLink="/player-detail/false" [routerLinkActive]="['active']">{{currentPlayerCity}}</a></h2>
    </div>

    <div *ngIf="isMyMatchesClicked" class="col-auto">
      <a href="javascript:" class="text-success d-inline-block d-sm-none h4 m-0" (click)="createNewEvent()"><i class="fas fa-plus-circle"><span class="sr-only">Create new match</span></i></a>
    </div>

    <div class="col-12 col-sm-auto mt-3 mt-md-0" *ngIf="!creatingNewEvent">
      <div class="match-filter">
        <ul class="nav flex-column flex-sm-row nav-pills nav-separated justify-content-center text-align-center text-align-md text-center">
          <li class="nav-item"><a href="javascript:" class="nav-link sos-matches" [ngClass]="{'matches-color active': isSosMatchesClicked }" (click)="filterEvents('sosMatches')">SOS Matches </a></li>
          <li class="nav-item"><a href="javascript:" class="nav-link my-matches" [ngClass]="{'matches-color active': isMyMatchesClicked }" (click)="filterEvents('myMatches')">My Matches</a></li>
          <li class="nav-item"><a href="javascript:" class="nav-link all-matches" [ngClass]="{'matches-color active': isAllMatchesClicked }" (click)="filterEvents('allMatches')">All Matches</a></li>
        </ul>
      </div>
    </div>
    <div class="header-divider"></div>
  </header>
  
  <div *ngIf="!creatingNewEvent">

    <p *ngIf="joinMessage" class="h6 text-center">{{joinMessage}}</p>

    <app-dialog [(visible)]="addressMissing">
      <div class="modal-header">
        <br>
      </div>
      <div class="text-center pt-3">
        <h5>Set your profile city and address to see nearby matches.</h5>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary btn-sm px-3" routerLink="/player-detail/false">Close</button>
      </div>
    </app-dialog>

    <div *ngIf="!events && isAllMatchesClicked" class="matches animated fadeIn">
      <h2 class="m-0">No matches near you</h2>
    </div>

    <div *ngIf="(!sosEvents || sosEvents.length == 0) && isSosMatchesClicked" class="matches animated fadeIn">
      <h2 class="m-0">No SOS matches near you</h2>
    </div>

    <div *ngIf="(!myEvents || myEvents.length == 0) && isMyMatchesClicked" class="matches animated fadeIn">
      <h2 class="m-0">Join or Start a match</h2>
    </div>

    <div *ngIf="events" class="matches animated fadeIn">
      <div *ngFor="let event of events; index as i; even as isEven; odd as isOdd">
        <div class="card card-event card-event-{{i}}" [ngClass]="{'odd': isOdd, 'even': isEven}">

          <div class="card-header text-white bg-primary py-2">
            <div class="row mb-2 align-items-center">
              <div class="text-light-blue text-uppercase small font-weight-700 col">{{event.active ? (event.nextOccurrenceTimestamp | date:'EEEE') : '⌛'}} </div>

              <div *ngIf="event.active && (event.viewedByPlayer || event.viewedByManager)" class="col-auto">
                <div *ngIf="event.confirmedCount >= event.maxPlayers && event.currentPlayerInviteStatus === playerEventInfoStatus.NOT_ANSWERED; else yourStatus">
                  <span class="badge badge-status badge-light badge-inverted">MATCH IS FULL</span>
                </div>
                <ng-template #yourStatus>
                  <span class="badge badge-status badge-light badge-inverted"
                    *ngIf="event.currentPlayerInviteStatus === playerEventInfoStatus.NOT_NOTIFIED && !event.viewedByManager">NOT
                    INVITED</span>
                  <span class="badge badge-status badge-danger text-white"
                    *ngIf="event.currentPlayerInviteStatus === playerEventInfoStatus.DECLINED">NOT COMING</span>
                
                  <span class="badge badge-status badge-success text-white"
                    *ngIf="event.currentPlayerInviteStatus === playerEventInfoStatus.CONFIRMED">COMING</span>
                
                  <span class="badge badge-status badge-warning text-white"
                    *ngIf="event.currentPlayerInviteStatus === playerEventInfoStatus.NOT_ANSWERED">INVITED</span>
                </ng-template>
              </div>
            </div>

            <div class="row">
              <div class="col">
                <div class="row">
                  <div class="col-auto">
                    <div class="cal rounded-lg bg-white text-primary text-center" title="{{event.nextOccurrenceTimestamp | date:'EEE, MMM d yyyy'}}">
                      <div class="month">{{event.nextOccurrenceTimestamp | date:'MMM'}}</div>
                      <div class="date">{{event.nextOccurrenceTimestamp | date:'d'}}</div>
                    </div>
                  </div>
                  <div class="col pl-0 mt-n1 line-height-14">
                    <strong (click)="(event.viewedByManager || event.viewedByPlayer) ? eventPlayers(event): eventDetail(event)" class="cursor-pointer match-name">
                      {{event.name}}
                    </strong>
                    <div class="text-nogosh-grey3 text-sm">
                      {{event.nextOccurrenceTimestamp | timezone:event.nextOccurrenceTimestampZone:'HH:mm'}} @ {{event.playSpace.name}}
                    </div>                    
                  </div>
                </div>
              </div>
              <div class="col-auto">
                <div *ngIf="event.active && (event.viewedByPlayer || event.viewedByManager)" class="">
                  <div class="confirmed">
                    <!-- Confirmed by -->
                    <span class="ratio">{{event.confirmedCount ? event.confirmedCount : 0 }}/{{event.maxPlayers}}</span>
                    <span class="addon">Players</span>
                  </div>
                </div>
              </div>
            </div>

            <div class="row darkBlueText">
              <div class="col-3" *ngIf="!event.active && event.whiteScore && event.blackScore" [ngSwitch]="event.wonLostDrew">
                <img *ngSwitchCase="'won'" width="27px" height="27px" src="../../assets/images/sensible_soccer_won.png" title="You won this one!! :)">
                <img *ngSwitchCase="'lost'" width="27px" height="27px" src="../../assets/images/sensible_soccer_lost.png" title="You lost this one!! :(">
                <img *ngSwitchCase="'drew'" width="27px" height="27px" src="../../assets/images/sensible_soccer_drew.png" title="You drew this one!! :|">
                <img *ngSwitchDefault>

                {{event.teamWhite ? event.whiteScore + ':' + event.blackScore : event.blackScore + ':' + event.whiteScore}}
              </div>
            </div>
          </div>

          <div class="card-body py-1">
            <div class="row align-items-center flex-nowrap">
              <div class="col-auto">
                <div class="avatar-team rounded-circle border" title="Manager: {{event.manager.nickName}}" [style.backgroundImage]="'url(' + (event.manager.avatar ? event.manager.avatar  : '../../assets/images/user1.jpg') + ')'">
                  <span class="sr-only">{{event.manager.nickName}}'s profile image</span>
                </div>
              </div>

              <div class="col px-0 d-flex align-items-center overflow-hidden">
                <span class="font-weight-800 text-primary d-inline-block line-height-11 overflow-hidden">{{event.manager.nickName}}</span>
              </div>
              <!-- Chat -->
              <div *ngIf="event.active && (event.viewedByPlayer || event.viewedByManager)" class="event-list-button">
                <a class="event-list-chat-btn" [routerLink]="['/messages', {entityId: event.originalEventId}]">
                  <img class="img-fluid" style="width: 40px; height: 40px" src="../assets/images/chat_match.png" alt="Chat">
                </a>
              </div>

              <!-- join - random visitor view -->
              <div class="col-auto ml-auto" *ngIf="event.active && !event.viewedByCandidate && !event.viewedByPlayer && !event.viewedByManager">
                <button type="button" (click)="requestToJoin(event);" class="btn btn-xs btn-warning">Join</button>
              </div>
              <div class="col-auto ml-auto" *ngIf="event.active && event.viewedByCandidate">
                <div *ngIf="event.viewedByCandidate" class="">
                  <button type="button" class="btn btn-xs btn-warning" disabled>Join pending</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div *ngIf="isMyMatchesClicked" class="mt-auto pt-5 text-center">
      <a class="mt-3" (click)="createNewEvent()" href="javascript:">
        <img class="ml-auto mr-auto pt-5" src="../../../assets/images/plus-white-background.png" alt="Create new match">
      </a>
    </div>
  </div>
</div>