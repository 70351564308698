<nav class="navbar navbar-dark bg-primary sticky sticky-top navbar-main px-sm-0 animated fadeIn navbar-expand-sm">
  <div class="container px-0 px-sm-3">
    <h1 class="m-0"><a href="/" class="navbar-brand d-block">FOOTBALLER</a></h1>

 <div class="nav-item mr-auto d-flex d-sm-inline-flex flex-sm-row">
            <a href="javascript:" routerLink="/event-list" class="btn btn-primary text-left" title="Matches" [routerLinkActive]="['active']">
              <span class="sr-only">Matches</span>
              <span class="d-inline-block">⚽</span>
            </a>
            <a href="javascript:"  routerLink="/chat-list" class="btn btn-primary text-left" title="Chats" [routerLinkActive]="['active']">
              <i class="fas fa-comments d-inline-block"></i>
              <span class="sr-only">Chats</span><!-- 💬 -->
            </a>
            <a *ngIf="false" href="javascript:"  routerLink="/player-list" class="btn btn-primary text-left" title="Players" [routerLinkActive]="['active']">
              <i class="fas fa-user-friends d-none d-sm-inline-block"></i><!-- 👥 -->
              <span class="d-sm-none">Players</span>
            </a>
            <a *ngIf="false" href="javascript:"  routerLink="/help" class="btn btn-primary text-left" title="About" [routerLinkActive]="['active']">
              <i class="fas fa-question d-none d-sm-inline-block"></i><!-- ❓ -->
              <span class="d-sm-none">About</span>
            </a>
        </div>
    <a href="javascript:" class="btn dropdown-toggle no-caret float-right d-sm-none text-white font-weight-300 mr-n2" title="More" data-toggle="collapse" data-target="#navbarNavMain" aria-controls="navbarNavMain" aria-expanded="false" aria-label="Toggle navigation" *ngIf="auth.isAuthenticated$ | async">
      <i class="fas fa-bars d-expanded-true-none"><span class="sr-only">More</span></i>
      <span class="h2 font-weight-300 line-height-3 align-middle d-expanded-false-none">&times;<span class="sr-only">More</span></span>
    </a>

    <div class="collapse navbar-collapse mx-n3 px-2" id="navbarNavMain" *ngIf="auth.isAuthenticated$ | async">
      <ul class="navbar-nav flex-fill mx-n1 mx-sm-0">

       

        <!--  DROPDOWN MOBILE VERSION  -->
        <li class="nav-item d-flex flex-column d-sm-none">
            <div class="nav-divider opacity-10"></div>
            <a href="javascript:" class="btn btn-primary text-left" routerLink="/playspace/false" [routerLinkActive]="['active']">Locations</a>
            <a href="javascript:" class="btn btn-primary text-left" routerLink="/player-detail/false" [routerLinkActive]="['active']">Profile</a>
            <a *ngIf="false" href="javascript:" class="btn btn-primary text-left" routerLink="/feedback" [routerLinkActive]="['active']">Feedback</a>
            <div class="nav-divider opacity-10"></div>
            <a target="_blank" href="https://play.google.com/store/apps/details?id=com.mangonel.nogosh&hl=en&gl=US" class="btn btn-primary text-left">Android App</a>
            <a target="_blank" href="https://apps.apple.com/us/app/footballer-zone/id1312823941" class="btn btn-primary text-left">iOS App</a>
            <div class="nav-divider opacity-10"></div>
            <a href="javascript:" class="btn btn-primary text-left" (click)="logout()" *ngIf="auth.isAuthenticated$ | async"><i class="fa fa-sign-out"></i> Logout</a>
        </li>

        <!--  DROPDOWN >= TABLET VERSION  -->
        <li class="ml-sm-auto nav-item dropdown d-none d-sm-inline-block" dropdown>
          <a href="javascript:" dropdownToggle class="btn text-white dropdown-toggle no-caret" title="More"><i class="fas fa-bars"><span class="sr-only">More</span></i><!-- <span class="caret"></span> --></a>
          <div *dropdownMenu class="dropdown-menu dropdown-menu-right mt-2 border-top-0" role="menu">
            <a href="javascript:" class="dropdown-item" routerLink="/playspace/false" [routerLinkActive]="['active']">Locations</a>
            <a href="javascript:" class="dropdown-item" routerLink="/player-detail/false" [routerLinkActive]="['active']">Profile</a>
            <a *ngIf="false" href="javascript:" class="dropdown-item" routerLink="/feedback" [routerLinkActive]="['active']">Feedback</a>
            <div class="dropdown-divider"></div>
            <a target="_blank" href="https://play.google.com/store/apps/details?id=com.mangonel.nogosh&hl=en&gl=US" class="dropdown-item">Android App</a>
            <a target="_blank" href="https://apps.apple.com/us/app/footballer-zone/id1312823941" class="dropdown-item">iOS App</a>
            <div class="dropdown-divider"></div>
            <a href="javascript:" class="dropdown-item logout-link" (click)="logout()" *ngIf="auth.isAuthenticated$ | async"><i class="fa fa-sign-out"></i> Logout</a>
          </div>
        </li>
      </ul>
    </div>



  </div>
</nav>
