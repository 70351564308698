import { Player } from "./player";

export class PlayerEventInfo {
  id: string;

  eventId: string;
  player: Player;
  status: string;
  jersey: string;
  teamMemberType: string;

  statusModifiedByPlayerId: string;
	statusModifiedByPlayerEmail: string;
	modificationDate: Date;

  version : number = 0;

  notificationInProgress : boolean = false;
}

export class PlayerEventInfoStatus {
  CONFIRMED : string = "CONFIRMED";
  DECLINED : string = "DECLINED";
  NOT_ANSWERED : string = "NOT_ANSWERED";
  NOT_NOTIFIED : string = "NOT_NOTIFIED";
}

export class PlayerEventInfoJersey {
  WHITE : string = "WHITE";
  BLACK : string = "BLACK";
  YELLOW : string = "YELLOW";
}

export class TeamMemberType {
  FIRST_TEAM: string = "FIRST_TEAM";
  RESERVE: string = "RESERVE";
  CANDIDATE : string = "CANDIDATE";
}
