import { City } from "./city";
import {} from "geojson";
import { Player } from "./player";

export class PlaySpace {
  id: string;
  name: string;
  manager: Player;
  city: City;
  address: string;
  phone: string;
  webAddress: string;
  workingHours: string;
  companyLogo: string;
  location: GeoJSON.Point;
  latitude: number;
  longitude: number;
  googleLatLng: google.maps.LatLng;
  viewedByManager: boolean = false;
}
