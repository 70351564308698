<!-- <ngx-loading [show]="loading" [config]="{ backdropBorderRadius: '3px' }"></ngx-loading> -->
<ngx-spinner
  bdColor="rgba(51,51,51,0.8)"
  size="medium"
  color="#fff"
  type="ball-scale-multiple"
><p style="font-size: 20px; color: white">Loading...</p>
</ngx-spinner>

<div *ngIf="!loading" class="container d-flex flex-column" id="commentsList" role="main">

  <header  class="row pt-3 pb-0 pt-md-4 align-items-center header-main">
    <a href="javascript:void(0)" class="btn-back col-auto border-right align-self-stretch" (click)="goBack()"><i class="fas fa-arrow-left"><span class="sr-only">Back</span></i></a>
    <div class="col text-truncate pr-0">
      <h2 class="m-0 chat-name text-truncate {{ chat.name.indexOf(',') != -1  ? 'text-h6 text-sm-h4 text-md-h2': 'h3'}} ">
          <span *ngIf="chat.entityType !== 'EVENT'" class="text-breakEX">{{chat.displayName}}</span>
          <a *ngIf="chat.entityType === 'EVENT'" href="javascript:" [routerLink]="['/event-players/false', {eventId: chat.entityId}]" [routerLinkActive]="['active']">{{chat.displayName}}</a>
      </h2>
    </div>
    <div *ngIf="(viewedByManager && chat.priority >= 10) || (currentPlayer.superAdmin && chat.priority < 10)"
        class="col-auto ml-auto align-self-start align-self-sm-center">
      <button (click)="modifyChat()" class="btn btn-sm btn-primary px-3"><i class="fas fa-edit d-sm-none"></i><span class="d-none d-sm-inline-block">Manage</span></button>
    </div>
    <div *ngIf="!viewedByManager && chat.priority >= 10" class="col-auto ml-auto align-self-start align-self-sm-center">
      <button (click)="showLeaveDialog = !showLeaveDialog" class="btn btn-sm btn-danger px-3"><i class="fas fa-times d-sm-none"></i><span class="d-none d-sm-inline-block">Leave</span></button>

      <app-dialog [(visible)]="showLeaveDialog">
        <div class="modal-header">
          <h5 class="mb-0">Are you sure you want to leave this chat!</h5>
        </div>
        <div class="modal-footer justify-content-center">
          <button type="button" class="btn btn-secondary btn-sm px-3 text-white" (click)="showLeaveDialog = false">No</button>
          <button type="button" class="btn btn-primary btn-sm px-3" (click)="leaveChat(); showLeaveDialog = false">Yes</button>

        </div>
      </app-dialog>
    </div>

    <div class="header-divider"></div>
  </header>

  <div class="row">
    <div class="scroll-wrapper w-100 mx-3" #chatWrapper (window:resize)="resizeChatOnLoadResize($event, false)"
         data-height-on="nav.navbar-main, header.header-main, #newMessageSection" data-controls=".scroll-parent" >
         <!-- (window:resize)="resizeChatOnLoadResize($event)" -->
         <!-- style="margin-bottom: -55px;" -->
         <!-- https://www.npmjs.com/package/on-screen-keyboard-detector -->
      <div class="scroll-parent col " id="scrollParent" #scrollMe infiniteScroll debounce
      [infiniteScrollDistance]="scrollDownDistance"
      [infiniteScrollUpDistance]="scrollUpDistance"
      [infiniteScrollThrottle]="throttle"
      (scrolled)="onScrollDown()"
      (scrolledUp)="onScrollUp()"
      [scrollWindow]="false"
      [alwaysCallback]="false"
      [fromRoot]="true">
        <div class="search-results">
          <div *ngFor="let message of messagesBySenderGrouped; let i = index;">
            <div class="msg">
              
              <p *ngIf="false && message?.marker" class="text-center"><strong
                style="color: #3333cc">{{message?.marker}}</strong></p>

              <div *ngIf="!message?.marker" class="row align-items-start mb-3">
                <div class="col-auto pr-0">
                  <div class="avatar-team avatar-team-md rounded-circle border" (click)="chatPlayerInfo(message.sender)" title="{{message.sender.nickName}}" [style.backgroundImage]="'url(' + (message.sender.avatar ? message.sender.avatar : '../../assets/images/user1.jpg') + ')'">
                    <span class="sr-only">{{message.sender.nickName}}'s profile image</span>
                  </div>
                </div>

                <div class="col">
                  <div class="row flex-column flex-md-row">
                    <div class="col line-height-13">
                      <div class="msgNick font-weight-700">{{message.sender.nickName}}</div>
                      <div *ngFor="let senderMessage of message.messages; let j = index;" class="row flex-column flex-md-row">
                        <div [innerHTML]="senderMessage.html" class="col line-height-13 text-sm text-nogosh-grey1"></div>
                        <div class="col-auto ml-md-auto text-sm text-nogosh-grey2" [ngSwitch]="getMessageDateMode(senderMessage.created)"> 
                        <!--div *ngIf="j == 0 || (j>0 && senderMessage.created-message.messages[j-1].created > (60000*5))" class="col-auto ml-md-auto text-sm text-nogosh-grey2" [ngSwitch]="getMessageDateMode(senderMessage.created)"-->
                          <span class="msgTime" *ngSwitchCase="'6Days'">
                            {{senderMessage.created | date: "EEEE, MMM d, H:mm" }}
                          </span>
                          <span class="msgTime" *ngSwitchCase="'Days'">
                            {{senderMessage.created | date: "EEEE, H:mm" }}
                          </span>
                          <span class="msgTime" *ngSwitchCase="'Yesterday'">
                            Yesterday {{senderMessage.created | date: "H:mm" }}
                          </span>
                          <span class="msgTime" *ngSwitchCase="'Today'">
                            {{senderMessage.created | date: "H:mm" }}
                          </span>
                        </div>
                      </div>                                 
                      <div class="mt-1 pt-1 border-bottom"></div>
                    </div>
                  </div>
                </div><!--  /.col -->
              </div>
            </div>
          </div>
        </div>
        <div id="searchEnd"></div>
      </div>
    </div><!-- /.scroll-parent-->
  </div>  <!-- .scroll-wrapper -->

  <!-- <div class="search-results" #heightDebug></div> -->
  <div class="row" id="newMessageSection" #chatTextBox>
    <div class="col ">
      <textarea class="form-control panel-shadow" rows="1" name="eventComment" id="newCommentText"
                [(ngModel)]="newMessageText" placeholder="Type a message here..."
                (keyup.enter)="submitNewMessage(newMessageText)"
                (blur)="captureBlurEventOnMessageSubmit(newMessageText);"
                (click)="resizeChatOnLoadResize(null, false)">
      </textarea>
    </div>
  </div>
</div>
