import {Component, OnInit} from "@angular/core";
import {PlayerService} from "../../shared/services/player.service";
import {Chat} from "../../shared/models/chat";
import {Player} from "app/shared/models/player";
import {Router} from "@angular/router";
import {ChatService} from "../../shared/services/chat.service";
import { NgxSpinnerService } from "ngx-spinner";



@Component({
  selector: 'chat-list',
  templateUrl: './chat-list.component.html',
  styleUrls: ['../chat.component.css']
})
export class ChatListComponent implements OnInit {

  public chats: Chat[];
  public loading = false;

  private currentPlayer: Player;

  constructor(private chatService: ChatService,
              private playerService: PlayerService,
              private router: Router,
              private spinner: NgxSpinnerService) {
  }

  ngOnInit() {
    this.loading = true;
    this.spinner.show();

    this.currentPlayer = this.playerService.getCurrentPlayer();
    this.playerService.checkCity();
    this.getChats(this.currentPlayer);
  }

  public createNewChat(): void {
    this.router.navigate([
      '/chat-detail',
      {
        newChat: true
      }
    ]);
  }

  public enterChat(chat: Chat): void {
    this.router.navigate(['/messages', {chatId: chat.id}]);
  }

  private getChats(player: Player): void {
    this.chats = Array<Chat>();

    this.chatService.getChatsForPlayer(player.id).subscribe(chats => {
      this.chats = this.calculateDisplayNames(chats);
      this.loading = false;
      this.spinner.hide();
    }, response => {

      console.log(response.error);
      this.loading = false;
      this.spinner.hide();
    });
  }

  private calculateDisplayNames(chats: Chat[]) : Chat[]{
    
    chats.forEach(chat => {
      this.chatService.calculateDisplayName(chat, this.currentPlayer.id);
    });

    return chats;
  }

  
}
