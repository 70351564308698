import {Observable} from 'rxjs';
import {Injectable} from "@angular/core";
import {environment} from "../../environments/environment";
import {Event} from "../models/event";
import {HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import {PlayerEventInfo, PlayerEventInfoStatus} from "../models/playerEventInfo";
import {Player} from "../models/player";

@Injectable()
export class EventService {
  private apiUrl: string = environment.API_BASE_URL + '/event';
  private playerEventInfoStatus: PlayerEventInfoStatus = new PlayerEventInfoStatus();

  constructor(private authHttp: HttpClient) {
  }

  private static getRequestOptions() {
    return {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    };
  }

  public createEvent(body: Object): Observable<Event> {
    let bodyString = JSON.stringify(body);
    return this.authHttp.post<Event>(`${this.apiUrl}`, bodyString, EventService.getRequestOptions());
  }

  public changeManager(eventId : string, playerId: string): Observable<string> {
    return this.authHttp.post<string>(`${this.apiUrl}/${eventId}/changeManager/${playerId}`, EventService.getRequestOptions());
  }

  public notifyPlayers(event: Event, teamMemberType: string): Observable<string> {
    let bodyString = JSON.stringify(event);

    const requestOptions = {
      headers: new HttpHeaders({'Content-Type': 'application/json'}),
      params: teamMemberType ? new HttpParams().set('teamMemberType', teamMemberType) : new HttpParams()
    };

    return this.authHttp.put<string>(`${this.apiUrl}/notifications`, bodyString, requestOptions);
  }

  public invitePlayer(playerEventInfo: PlayerEventInfo): Observable<PlayerEventInfo> {
    let bodyString = JSON.stringify(playerEventInfo);
    return this.authHttp.put<PlayerEventInfo>(`${this.apiUrl}/notifications/player/notification/${playerEventInfo['id']}`, bodyString, EventService.getRequestOptions());
  }

  public notifyPlayer(playerEventInfo: PlayerEventInfo): Observable<PlayerEventInfo> {
    let bodyString = JSON.stringify(playerEventInfo);
    return this.authHttp.put<PlayerEventInfo>(`${this.apiUrl}/notifications/player/confirmation/${playerEventInfo['id']}`, bodyString, EventService.getRequestOptions());
  }

  public notifyNotAnsweredEventPlayers(eventId : string ): Observable<string> {
    return this.authHttp.post<string>(`${this.apiUrl}/notifications/event/notAnswered/${eventId}`, EventService.getRequestOptions());
  }

  public notifyAllOpenProfiles(eventId: string): Observable<string> {
    return this.authHttp.post<string>(`${this.apiUrl}/notifyAllOpenProfiles/${eventId}`, EventService.getRequestOptions());
  }

  public notifyTeammateOpenProfiles(eventId: string): Observable<string> {
    return this.authHttp.post<string>(`${this.apiUrl}/notifyEventmateOpenProfiles/${eventId}`, EventService.getRequestOptions());
  }

  public fetchHistory(originalEventId: string): Observable<Event[]> {
    return this.authHttp.get<Event[]>(`${this.apiUrl}/archived/${originalEventId}`, EventService.getRequestOptions());
  }

  public updateEvent(body: Object): Observable<Event> {
    let bodyString = JSON.stringify(body);
    return this.authHttp.put<Event>(`${this.apiUrl}/${body['id']}`, bodyString, EventService.getRequestOptions());
  }

  public removeSingleEventOccurrenceById(id: string): Observable<Event> {
    return this.authHttp.delete<Event>(`${this.apiUrl}/${id}`);
  }

  public removeAllEventOccurrenceByOriginalId(originalId: string): Observable<Event> {
    return this.authHttp.delete<Event>(`${this.apiUrl}/deleteAllEventsByOriginalId/${originalId}`);
  }

  public getConfirmedCount(event: Event): number {
    if (event.playerEventInfos == null || event.playerEventInfos.length === 0) {
      return 0;
    }

    let count: number = 0;
    for (let playerEventInfo of event.playerEventInfos) {
      if (playerEventInfo.status === this.playerEventInfoStatus.CONFIRMED) {
        count++;
      }
    }
    return count;
  }

  public getAllActiveEvents(): Observable<Event[]> {
    return this.authHttp.get<Event[]>(`${this.apiUrl}/active`, EventService.getRequestOptions());
  }

  public getEvent(eventId: string) : Observable<Event>{
    return this.authHttp.get<Event>(`${this.apiUrl}/${eventId}`, EventService.getRequestOptions());
  }


  public getAllActiveEventsNearBy(latitude: number, longitude: number, distanceInKm: number): Observable<Event[]> {
    return this.authHttp.get<Event[]>(`${this.apiUrl}/active/geo?latitude=${latitude}&longitude=${longitude}&distanceInKm=${distanceInKm != null ? distanceInKm : 50}`, EventService.getRequestOptions());
  }

  public requestToLeaveEvent(event: Event, player: Player): Observable<Response> {
    return this.authHttp.post<Response>(`${this.apiUrl}/${event.id}/leave/player/${player.id}`, EventService.getRequestOptions());
  }

  public requestToJoinEvent(event: Event, player: Player): Observable<Response> {
    return this.authHttp.post<Response>(`${this.apiUrl}/${event.id}/join/player/${player.id}`, EventService.getRequestOptions());
  }

  public invitePlayersToJoinNogosh(event: Event, emails: string): Observable<Response> {
    return this.authHttp.post<Response>(`${this.apiUrl}/invitePlayersToJoinNogosh/?eventId=${event.id}&emails=${emails}`, EventService.getRequestOptions());
  }
}
