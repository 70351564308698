import {NgModule, CUSTOM_ELEMENTS_SCHEMA} from "@angular/core";
import {CommonModule} from "@angular/common";
import {FormsModule} from "@angular/forms";
import {CoreModule} from "../core/core.module";
import {ChatComponent} from "./chat.component";
import {ChatService} from "../shared/services/chat.service";
import {MessageService} from "../shared/services/message.service";
import {InfiniteScrollModule} from "ngx-infinite-scroll";
import {PopoverModule} from 'ngx-bootstrap/popover';
import {PlayerModule} from "../player/player.module";
import {ChatUtils} from "../shared/utils/chat.utils";
//import {NgxLoadingModule} from 'ngx-loading';
import {ChatListComponent} from "./chat-list/chat-list.component";
import {ChatDetailComponent} from "./chat-detail/chat-detail.component";
import {PlaySpaceService} from "../shared/services/playspace.service";
import {TypeaheadModule} from 'ngx-bootstrap/typeahead';
import {RouterModule} from "@angular/router";
import {BsDropdownModule} from 'ngx-bootstrap/dropdown';
import { NgxSpinnerModule } from "ngx-spinner";

@NgModule({
  declarations: [ChatComponent, ChatListComponent, ChatDetailComponent],
  imports: [
    CommonModule, 
    FormsModule, 
    InfiniteScrollModule, 
    PopoverModule, 
    PlayerModule, 
    CoreModule,
    TypeaheadModule, 
    BsDropdownModule, 
    RouterModule, 
//    NgxLoadingModule.forRoot({}), 
    NgxSpinnerModule],
  exports: [ChatComponent, ChatListComponent, ChatDetailComponent],
  providers: [ChatService, MessageService, ChatUtils, PlaySpaceService],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class ChatModule {
}
