export class Player {
  id: string;
  name: string;
  email: string;
  nickName: string;
  phoneNumber: string;
  authId: string;
  loginEmail: string;
  userNames: string[];
  active: boolean;
  openProfile: boolean;
  allowTeammatesSOS: boolean;
  avatar: string;
  comments: string[];
  locationInfo: PlayerLocationInfo;
  location: GeoJSON.Point;
  version: number;
  mutedChatIds: string[];
  city: string;
  address: string;
  addressLat: number;
  addressLong: number; 

  autocompleteName: string;
  superAdmin: boolean;

  ratingDefence: Array<{
    ratedBy: string;
    rating: number;
  }>;

  ratingOffence: Array<{
    ratedBy: string;
    rating: number;
  }>;
}

export interface RatingUpdate {
  ratedBy: string;
  rating: number;
}

export class PlayerDeviceData {
  client: string;
  userPhoneNumber: string;
  userEmail: string;
  userAppPrivateKey: string;
  otherBulkInfo: string;
  pushAccessToken: string;
}

export class PlayerLocationInfo {
  public ip: string;
  public email: string;
  public continentCode: string;
  public continentName: string;
  public countryCode: string;
  public countryName: string;
  public region: string;
  public city: string;
  public latitude: number;
  public longitude: number;
  public zipCode: string;
  public timeZone: string;
  public address: string;
}

export class PlayerPushTokenInfo {
  public email: string;
  public pushAccessToken: string;
}
